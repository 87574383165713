@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@charset "UTF-8";
:root {
  --cielo01: #4b1196;
  --cielo02: #3b0675;
  --cielo03: #9eb0d6;
  --btndangerbackgroundcolor: var(--cielo01);
  --btndangerbordercolor: var(--cielo01);
  --bgcMenu: var(--cielo02);
  --clienteDesativado: #c3c3c3;
  --clienteOverText: #a269df;
  --clienteClaro: #5a19af;
  --cliente: var(--cielo01);
  --clienteOver: #222222;
  --corPeriodoFiltroRelatorios: var(--cielo01);
  --btn-insigths: var(--cielo01);
  --bordas-links-insights-active: var(--cielo01);
  --parlamentar-titulo-grafico: var(--cielo01);
  --parlamentar-botao-seguir: var(--cielo01);
  --menuProposicaoActive: var(--cielo01);
  --slick-next: var(--cielo01);
  --linkText: var(--cielo01);
  --colorToolTip: #3e4049;
  --botaoPesquisar: var(--cielo01);
  --corTextoBotao: white;
  --botaoFiltro: var(--cielo01);
  --botaoLimpar: white;
  --corTextoBotaoLimpar: #3e4049;
  --corBackgroundTags: #d9c0ec;
  --telaloginsm: url(/static/media/logo_purpleClient.61cf1546.svg);
  --telaloginmd: url("");
  --telaloginxl: url("");
  --logoMenu: url(/static/media/logo_purpleClient.61cf1546.svg);
  --imgBrasilia: url(/static/media/brasilia-img.fb5931fd.png);
  --logoLogin: url(/static/media/logo-login-purpleClient.dcb804de.svg);
  --gradientLoginImg: linear-gradient(
        180deg,
        #1e0736 10.42%,
        #3b0675 27.6%,
        #3d0779 53.65%,
        #4b1196 80.21%,
        #6b09eb 98.44%
      ),
      #000000;
  --logoRelatorios: url(/static/media/logo-relatorios-purpleClient.1928d775.svg);
  --logoAgendaJornal: url(/static/media/logo-relatorios-purpleClient.1928d775.svg);
  --clipsAnexos: url(/static/media/clipsPreto.dc68b378.svg);
  --linkExterno: url(/static/media/link-externo-roxo.04251b72.svg);
  --setaBiografia: url(/static/media/seta-biografia-roxa.efcc78f0.svg);
  --colapseIconAbrir: url(/static/media/colapseBranco.7506e0f9.svg);
  --colapseIconFechar: url(/static/media/ColapseBrancoFechar.29c692ea.svg);
  --BackgroundFormLoginMobile: rgba(30, 7, 54, 0.7);
  --BorderSelectExpandButtom: #4b1196;
  --titulosTemas:#4b1196;
  --corItem: #3b0675;
  --corHoverItem: rgba(75, 17, 150, 0.3) ;
  --iconUsuario: url(/static/media/userIcon.22f1e53d.svg);
  --iconIA: url(/static/media/IAicon.96514694.svg);
  --iconSair: url(/static/media/sairNew.916552c1.svg);
  --iconTema: url(/static/media/iconTema.7688aafb.svg);
  --fontColor: #4B1196; }
  :root ::-webkit-input-placeholder {
    color: #8b8b8b;
    font-size: 11px;
    font-weight: lighter; }
  :root ::placeholder {
    color: #8b8b8b;
    font-size: 11px;
    font-weight: lighter; }
  :root #bglogin {
    background: #4b1196;
    background: var(--cielo01); }
  :root .btn-danger {
    background-color: #4b1196 !important;
    background-color: var(--btndangerbackgroundcolor) !important;
    border-color: #4b1196 !important;
    border-color: var(--btndangerbordercolor) !important; }
  :root .text-danger {
    color: #4b1196 !important;
    color: var(--btndangerbackgroundcolor) !important; }
  :root .css-1f6a0xi-DropdownIndicator {
    color: #4b1196 !important;
    color: var(--btndangerbackgroundcolor) !important; }
  :root .badge-danger {
    background-color: #4b1196 !important;
    background-color: var(--btndangerbackgroundcolor) !important; }

::-webkit-input-placeholder {
  font-size: 16px !important; }

@font-face {
  font-family: "icons";
  src: url(/static/media/icons.05766a7e.eot?#iefix) format("embedded-opentype"), url(/static/media/icons.b9a91d7d.woff2) format("woff2"), url(/static/media/icons.3d1ba36c.woff) format("woff"), url(/static/media/icons.85982309.ttf) format("truetype"), url(/static/media/icons.7d692163.svg#icons) format("svg"); }

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: "NewJune-Bold";
  src: url(/static/media/NewJune-Bold.0cc7fb5e.otf); }

@font-face {
  font-family: "NewJune-Book";
  src: url(/static/media/NewJune-Book.5329747a.otf); }

@font-face {
  font-family: "NewJune-Medium";
  src: url(/static/media/NewJune-Medium.394aeb98.otf); }

@font-face {
  font-family: "NewJune-Semibold";
  src: url(/static/media/NewJune-Semibold.16f59a9a.ttf); }

.icon-abrir-menu-mobile_custom:before {
  content: "\F101"; }

.icon-abrir-menu-mobile:before {
  content: "\F102"; }

.icon-abrir-modal:before {
  content: "\F103"; }

.icon-acoes-relevantes:before {
  content: "\F104"; }

.icon-actions-small:before {
  content: "\F105"; }

.icon-adm-publica:before {
  content: "\F106"; }

.icon-agenda-menu-mobile:before {
  content: "\F107"; }

.icon-agricultura:before {
  content: "\F108"; }

.icon-angle-down:before {
  content: "\F109"; }

.icon-aniversario-parlamentar:before {
  content: "\F10A"; }

.icon-arquivo:before {
  content: "\F10B"; }

.icon-arrow-down-small copy:before {
  content: "\F10C"; }

.icon-arrow-down-small-copy:before {
  content: "\F10D"; }

.icon-arrow-down-small:before {
  content: "\F10E"; }

.icon-arrow-straight-back-small:before {
  content: "\F10F"; }

.icon-arrow-straight-front-small:before {
  content: "\F110"; }

.icon-arrow-up-small copy:before {
  content: "\F111"; }

.icon-arrow-up-small-copy:before {
  content: "\F112"; }

.icon-arrow-up-small:before {
  content: "\F113"; }

.icon-audio_icon:before {
  content: "\F114"; }

.icon-audio-discurso:before {
  content: "\F115"; }

.icon-aviso-vermelho:before {
  content: "\F116"; }

.icon-bt-fechar:before {
  content: "\F117"; }

.icon-btn_limpar:before {
  content: "\F118"; }

.icon-btn_pesquisar:before {
  content: "\F119"; }

.icon-calendar-events-small:before {
  content: "\F11A"; }

.icon-cartoes:before {
  content: "\F11B"; }

.icon-chave-midia:before {
  content: "\F11C"; }

.icon-ciencias:before {
  content: "\F11D"; }

.icon-clips-red:before {
  content: "\F11E"; }

.icon-configEngineIcon:before {
  content: "\F11F"; }

.icon-configuracoes-citacoes:before {
  content: "\F120"; }

.icon-consumidor:before {
  content: "\F121"; }

.icon-contra:before {
  content: "\F122"; }

.icon-corrupcao:before {
  content: "\F123"; }

.icon-create_24px_outlined:before {
  content: "\F124"; }

.icon-crianca-e-adolescente:before {
  content: "\F125"; }

.icon-cultura:before {
  content: "\F126"; }

.icon-defesa-nacional:before {
  content: "\F127"; }

.icon-delete-small:before {
  content: "\F128"; }

.icon-dislike:before {
  content: "\F129"; }

.icon-dots:before {
  content: "\F12A"; }

.icon-down-arrow:before {
  content: "\F12B"; }

.icon-download-small:before {
  content: "\F12C"; }

.icon-drogas:before {
  content: "\F12D"; }

.icon-economia:before {
  content: "\F12E"; }

.icon-edit-small:before {
  content: "\F12F"; }

.icon-edit:before {
  content: "\F130"; }

.icon-editar-gray:before {
  content: "\F131"; }

.icon-editorial-small:before {
  content: "\F132"; }

.icon-educacao:before {
  content: "\F133"; }

.icon-eleicoes:before {
  content: "\F134"; }

.icon-energia:before {
  content: "\F135"; }

.icon-expand-small:before {
  content: "\F136"; }

.icon-external-action-small:before {
  content: "\F137"; }

.icon-fechar-menu-mobile_custom:before {
  content: "\F138"; }

.icon-fechar-menu-mobile:before {
  content: "\F139"; }

.icon-fechar-modal:before {
  content: "\F13A"; }

.icon-filter-small:before {
  content: "\F13B"; }

.icon-filtrar:before {
  content: "\F13C"; }

.icon-fone-discurso:before {
  content: "\F13D"; }

.icon-graficos_febraban:before {
  content: "\F13E"; }

.icon-help-small:before {
  content: "\F13F"; }

.icon-home-small:before {
  content: "\F140"; }

.icon-honor:before {
  content: "\F141"; }

.icon-ia-icon:before {
  content: "\F142"; }

.icon-icon_identity_24px:before {
  content: "\F143"; }

.icon-icon_settings_24px:before {
  content: "\F144"; }

.icon-icon-edit-photo-usuarios:before {
  content: "\F145"; }

.icon-icon-new-notification:before {
  content: "\F146"; }

.icon-icon-notification:before {
  content: "\F147"; }

.icon-icone_usuario:before {
  content: "\F148"; }

.icon-icone-acoes-relatorio:before {
  content: "\F149"; }

.icon-icone-menu-insights:before {
  content: "\F14A"; }

.icon-icone-menu-relatorio:before {
  content: "\F14B"; }

.icon-icone-parlamentar-relatorio:before {
  content: "\F14C"; }

.icon-icone-proposicao-relatorio:before {
  content: "\F14D"; }

.icon-icons:before {
  content: "\F14E"; }

.icon-impressora:before {
  content: "\F14F"; }

.icon-industria:before {
  content: "\F150"; }

.icon-info-small:before {
  content: "\F151"; }

.icon-instituicoes-financeiras:before {
  content: "\F152"; }

.icon-internal-action-small:before {
  content: "\F153"; }

.icon-internet:before {
  content: "\F154"; }

.icon-joined-small:before {
  content: "\F155"; }

.icon-legisnote:before {
  content: "\F156"; }

.icon-lei:before {
  content: "\F157"; }

.icon-like:before {
  content: "\F158"; }

.icon-link-externo:before {
  content: "\F159"; }

.icon-lixeira:before {
  content: "\F15A"; }

.icon-loading:before {
  content: "\F15B"; }

.icon-logo_febraban_mobile:before {
  content: "\F15C"; }

.icon-logo_febraban:before {
  content: "\F15D"; }

.icon-logo-bradesco:before {
  content: "\F15E"; }

.icon-logo-menu:before {
  content: "\F15F"; }

.icon-logo-proposicao-febraban:before {
  content: "\F160"; }

.icon-lupa_buscar:before {
  content: "\F161"; }

.icon-menu-agenda:before {
  content: "\F162"; }

.icon-menu-home:before {
  content: "\F163"; }

.icon-menu-ia:before {
  content: "\F164"; }

.icon-menu-insights:before {
  content: "\F165"; }

.icon-menu-megafone:before {
  content: "\F166"; }

.icon-menu-msg:before {
  content: "\F167"; }

.icon-menu-parlamentares-new:before {
  content: "\F168"; }

.icon-menu-parlamentares:before {
  content: "\F169"; }

.icon-menu-prof-new:before {
  content: "\F16A"; }

.icon-menu-profile-new-b:before {
  content: "\F16B"; }

.icon-menu-profile-new-c:before {
  content: "\F16C"; }

.icon-menu-profile-new:before {
  content: "\F16D"; }

.icon-menu-profile:before {
  content: "\F16E"; }

.icon-menu-proposicao:before {
  content: "\F16F"; }

.icon-menu-relatorios:before {
  content: "\F170"; }

.icon-menu-usuario-d:before {
  content: "\F171"; }

.icon-menu-usuario-e:before {
  content: "\F172"; }

.icon-menu-usuario:before {
  content: "\F173"; }

.icon-midia-menu-mobile:before {
  content: "\F174"; }

.icon-midia-news-mobile:before {
  content: "\F175"; }

.icon-necessidades-especiais:before {
  content: "\F176"; }

.icon-next-page-pagination:before {
  content: "\F177"; }

.icon-no-photo:before {
  content: "\F178"; }

.icon-nomeacoes:before {
  content: "\F179"; }

.icon-noti-agenda:before {
  content: "\F17A"; }

.icon-noti-mencao:before {
  content: "\F17B"; }

.icon-noti-recomendada:before {
  content: "\F17C"; }

.icon-noti-update:before {
  content: "\F17D"; }

.icon-parlamentar-menu-mobile:before {
  content: "\F17E"; }

.icon-perfil-menu-mobile:before {
  content: "\F17F"; }

.icon-permanently-small:before {
  content: "\F180"; }

.icon-photo-user-default:before {
  content: "\F181"; }

.icon-prev-page-pagination:before {
  content: "\F182"; }

.icon-print:before {
  content: "\F183"; }

.icon-proposicao-menu-mobile:before {
  content: "\F184"; }

.icon-propositions-follow-small:before {
  content: "\F185"; }

.icon-propositions-recommendations-small:before {
  content: "\F186"; }

.icon-propositions-themes-small:before {
  content: "\F187"; }

.icon-relacoes-internacionais:before {
  content: "\F188"; }

.icon-relatorio-menu-mobile:before {
  content: "\F189"; }

.icon-sair-menu-mobile:before {
  content: "\F18A"; }

.icon-search-small:before {
  content: "\F18B"; }

.icon-seguranca:before {
  content: "\F18C"; }

.icon-seguro:before {
  content: "\F18D"; }

.icon-senator-all-small:before {
  content: "\F18E"; }

.icon-senator-small:before {
  content: "\F18F"; }

.icon-send-red:before {
  content: "\F190"; }

.icon-send:before {
  content: "\F191"; }

.icon-seta-direita:before {
  content: "\F192"; }

.icon-shape-copy-7:before {
  content: "\F193"; }

.icon-special-small:before {
  content: "\F194"; }

.icon-sport:before {
  content: "\F195"; }

.icon-star-without-fill:before {
  content: "\F196"; }

.icon-star:before {
  content: "\F197"; }

.icon-temporary-small:before {
  content: "\F198"; }

.icon-teste-botao-filtro:before {
  content: "\F199"; }

.icon-timeline-acao-infl:before {
  content: "\F19A"; }

.icon-timeline-acao-prep:before {
  content: "\F19B"; }

.icon-timeline-active:before {
  content: "\F19C"; }

.icon-timeline-ativo:before {
  content: "\F19D"; }

.icon-timeline-atual:before {
  content: "\F19E"; }

.icon-timeline-inactive:before {
  content: "\F19F"; }

.icon-timeline-inativo:before {
  content: "\F1A0"; }

.icon-timeline-inicio:before {
  content: "\F1A1"; }

.icon-timeline-pendente:before {
  content: "\F1A2"; }

.icon-transports:before {
  content: "\F1A3"; }

.icon-trash-gray:before {
  content: "\F1A4"; }

.icon-trending:before {
  content: "\F1A5"; }

.icon-turismo:before {
  content: "\F1A6"; }

.icon-up-arrow:before {
  content: "\F1A7"; }

.icon-urbanismo:before {
  content: "\F1A8"; }

.icon-user-contra:before {
  content: "\F1A9"; }

.icon-user-default-list-cad-usuario:before {
  content: "\F1AA"; }

.icon-user-favoravel:before {
  content: "\F1AB"; }

.icon-user-group-small:before {
  content: "\F1AC"; }

.icon-user-negative-small:before {
  content: "\F1AD"; }

.icon-user-neutral-small:before {
  content: "\F1AE"; }

.icon-user-neutro:before {
  content: "\F1AF"; }

.icon-user-positive-small:before {
  content: "\F1B0"; }

.icon-user-small:before {
  content: "\F1B1"; }

.icon-video-discurso:before {
  content: "\F1B2"; }

.icon-videoplay_icon:before {
  content: "\F1B3"; }

.icon-work:before {
  content: "\F1B4"; }

.custom-control {
  padding-left: 0 !important; }
  .custom-control.material-checkbox {
    --color: #008BFF; }
    .custom-control.material-checkbox .material-control-input {
      display: none; }
      .custom-control.material-checkbox .material-control-input:checked ~ .material-control-indicator {
        border-color: var(--color);
        -webkit-transform: rotateZ(45deg) translate(1px, -5px);
                transform: rotateZ(45deg) translate(1px, -5px);
        width: 10px;
        border-top: 0px solid #fff;
        border-left: 0px solid #fff; }
    .custom-control.material-checkbox .material-control-indicator {
      display: inline-block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 16px;
      height: 16px;
      border: 2px solid #aaa;
      transition: all 0.5s; }
  .custom-control.fill-checkbox {
    cursor: pointer;
    --color: #008BFF; }
    .custom-control.fill-checkbox .fill-control-input {
      display: none; }
      .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
        background-color: var(--color);
        border-color: var(--color);
        background-size: 80%; }
    .custom-control.fill-checkbox .fill-control-indicator {
      display: inline-block;
      vertical-align: middle;
      left: 0;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      transition: all 0.5s;
      background: #EEF1F6;
      background-size: 0%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }
    .custom-control.fill-checkbox .fill-control-description {
      vertical-align: middle;
      font-size: 12px; }
  .custom-control.overflow-checkbox .overflow-control-input {
    display: none; }
    .custom-control.overflow-checkbox .overflow-control-input:checked ~ .overflow-control-indicator::after {
      -webkit-transform: rotateZ(45deg) scale(1);
              transform: rotateZ(45deg) scale(1);
      top: -6px;
      left: 5px; }
    .custom-control.overflow-checkbox .overflow-control-input:checked ~ .overflow-control-indicator::before {
      opacity: 1; }
  .custom-control.overflow-checkbox .overflow-control-indicator {
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa; }
    .custom-control.overflow-checkbox .overflow-control-indicator::after {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      transition: all 0.5s;
      -webkit-transform: rotateZ(90deg) scale(0);
              transform: rotateZ(90deg) scale(0);
      width: 10px;
      border-bottom: 4px solid #aaa;
      border-right: 4px solid #aaa;
      border-radius: 3px;
      top: -2px;
      left: 2px; }
    .custom-control.overflow-checkbox .overflow-control-indicator::before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      transition: all 0.5s;
      width: 10px;
      border-right: 7px solid #fff;
      border-radius: 3px;
      -webkit-transform: rotateZ(45deg) scale(1);
              transform: rotateZ(45deg) scale(1);
      top: -4px;
      left: 5px;
      opacity: 0; }
  .custom-control.material-switch {
    --color: #26a69a;
    padding-left: 0; }
    .custom-control.material-switch .material-switch-control-input {
      display: none; }
      .custom-control.material-switch .material-switch-control-input:checked ~ .material-switch-control-indicator::after {
        background-color: var(--color);
        left: 17px; }
    .custom-control.material-switch .material-switch-control-indicator {
      display: inline-block;
      position: relative;
      margin: 0 10px;
      top: 4px;
      width: 32px;
      height: 16px;
      background: #ddd;
      border-radius: 16px;
      transition: all 0.5s; }
      .custom-control.material-switch .material-switch-control-indicator::after {
        content: '';
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        transition: all 0.5s;
        top: -1px;
        left: -1px;
        background: #fdfdfd;
        box-shadow: 0 2px 10px #aaa; }
  .custom-control.ios-switch {
    --color: #4cd964;
    padding-left: 0; }
    .custom-control.ios-switch .ios-switch-control-input {
      display: none; }
      .custom-control.ios-switch .ios-switch-control-input:active ~ .ios-switch-control-indicator::after {
        width: 20px; }
      .custom-control.ios-switch .ios-switch-control-input:checked ~ .ios-switch-control-indicator {
        border: 10px solid var(--color); }
        .custom-control.ios-switch .ios-switch-control-input:checked ~ .ios-switch-control-indicator::after {
          top: -8px;
          left: 4px; }
      .custom-control.ios-switch .ios-switch-control-input:checked:active ~ .ios-switch-control-indicator::after {
        left: 0px; }
    .custom-control.ios-switch .ios-switch-control-indicator {
      display: inline-block;
      position: relative;
      margin: 0 10px;
      top: 4px;
      width: 32px;
      height: 20px;
      background: #fff;
      border-radius: 16px;
      transition: all 0.5s;
      border: 2px solid #ddd; }
      .custom-control.ios-switch .ios-switch-control-indicator::after {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        transition: all 0.5s;
        top: 0px;
        left: 0px;
        background: #fff;
        box-shadow: 0 0 2px #aaa, 0 2px 5px #999; }
  .custom-control.border-switch {
    --color: #4cd964;
    padding-left: 0; }
    .custom-control.border-switch .border-switch-control-input {
      display: none; }
      .custom-control.border-switch .border-switch-control-input:checked ~ .border-switch-control-indicator {
        border-color: var(--color); }
        .custom-control.border-switch .border-switch-control-input:checked ~ .border-switch-control-indicator::after {
          left: 14px;
          background-color: var(--color); }
    .custom-control.border-switch .border-switch-control-indicator {
      display: inline-block;
      position: relative;
      margin: 0 10px;
      top: 4px;
      width: 32px;
      height: 20px;
      background: #fff;
      border-radius: 16px;
      transition: all 0.5s;
      border: 2px solid #ccc; }
      .custom-control.border-switch .border-switch-control-indicator::after {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transition: all 0.5s;
        top: 2px;
        left: 2px;
        background: #ccc; }
  .custom-control.teleport-switch {
    --color: #4cd964;
    padding-left: 0; }
    .custom-control.teleport-switch .teleport-switch-control-input {
      display: none; }
      .custom-control.teleport-switch .teleport-switch-control-input:checked ~ .teleport-switch-control-indicator {
        border-color: var(--color); }
        .custom-control.teleport-switch .teleport-switch-control-input:checked ~ .teleport-switch-control-indicator::after {
          left: -14px; }
        .custom-control.teleport-switch .teleport-switch-control-input:checked ~ .teleport-switch-control-indicator::before {
          right: 2px;
          background-color: var(--color); }
    .custom-control.teleport-switch .teleport-switch-control-indicator {
      display: inline-block;
      position: relative;
      margin: 0 10px;
      top: 4px;
      width: 32px;
      height: 20px;
      background: #fff;
      border-radius: 16px;
      transition: all 0.5s;
      border: 2px solid #ccc;
      overflow: hidden; }
      .custom-control.teleport-switch .teleport-switch-control-indicator::after {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transition: all 0.5s;
        top: 2px;
        left: 2px;
        background: #ccc; }
      .custom-control.teleport-switch .teleport-switch-control-indicator::before {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transition: all 0.5s;
        top: 2px;
        right: -14px;
        background: #ccc; }

.list-group:first-child .list-group-control:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.list-group::last-child .list-group-control:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem; }

.list-group.list-group-flush:last-child .list-group-control:last-child {
  border-bottom: 0;
  margin-bottom: 0; }

.list-group.list-group-flush:first-child .list-group-control:first-child {
  border-top: 0; }

.list-group.list-group-flush .list-group-control {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group .list-group-control {
  position: relative;
  display: block;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group .list-group-control .custom-control {
    margin: 0;
    padding: .75rem 1.25rem;
    padding-left: 3rem;
    display: block; }
    .list-group .list-group-control .custom-control .custom-control-indicator, .list-group .list-group-control .custom-control .material-control-indicator, .list-group .list-group-control .custom-control .fill-control-indicator, .list-group .list-group-control .custom-control .overflow-control-indicator {
      top: 1rem;
      left: 1.25rem; }

.CheckboxInput {
  display: inline-block;
  width: 36px;
  height: 20px;
  vertical-align: middle;
  border-radius: 50px;
  background-color: white;
  border: 1px solid #cdd8de;
  position: relative; }
  .CheckboxInput input {
    display: none; }
  .CheckboxInput:after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: #e1e7f2;
    border: 1px solid #cdd8de;
    position: absolute;
    top: -1px;
    left: -1px; }
  .CheckboxInput.checked {
    background-color: #59b58d;
    border: 1px solid #59b58d; }
    .CheckboxInput.checked:after {
      background-color: white;
      border: 1px solid #59b58d;
      left: auto;
      right: -1px; }

/*
  * content
  */
@media only screen and (min-width: 700px) {
  .content {
    min-height: 100vh;
    transition: all 0.5s;
    position: absolute;
    top: 0;
    right: 0;
    left: 66px !important;
    transition: all 0.5s;
    overflow-y: scroll;
    height: 100%; }
    .content.open {
      left: 291px !important; } }

.breadcrumb-item {
  font-size: 12px;
  text-transform: uppercase; }
  .breadcrumb-item > a {
    color: #292a2b;
    transition: all 0.5s; }
    .breadcrumb-item > a:hover {
      text-decoration: none; }
  .breadcrumb-item.active {
    color: #172b4d;
    font-weight: bold; }
  .breadcrumb-item.active {
    color: #172b4d;
    font-weight: bold; }

/*
  * Menu
  */
.logoMenu {
  content: url(/static/media/logo_purpleClient.61cf1546.svg);
  content: var(--logoMenu);
  width: 40px; }

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex !important;
  background-color: #3b0675;
  background-color: var(--bgcMenu);
  padding: 0 0.46rem;
  z-index: 101; }
  .menu > a {
    color: #fff !important; }
  .menu > a.active {
    background-color: white !important;
    color: #3e4049 !important; }

.menu_custom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex !important;
  background-color: #004177;
  padding: 0 0.46rem;
  margin-top: 15px;
  z-index: 101; }
  .menu_custom > a {
    color: #fff !important; }
  .menu_custom > a.active {
    background-color: #fff !important;
    color: #004177 !important; }

.barra_custom {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 0.46rem 0 0;
  z-index: 101; }

.logo_custom {
  padding: 10px; }

.logo_dash_custom {
  color: #ffffff; }

@media only screen and (max-width: 700px) {
  .menu {
    line-height: 52px;
    height: 50px; }
    .menu > a {
      padding: 0 1rem; }
      .menu > a.active {
        background: white !important;
        color: #3e4049 !important; }
    .menu > .footer {
      margin-left: auto;
      display: flex; } }

@media only screen and (max-width: 700px) {
  .menu_custom {
    line-height: 52px;
    height: 50px;
    margin-top: 0; }
    .menu_custom > a {
      padding: 0 1rem; }
    .menu_custom > .footer {
      margin-left: auto;
      display: flex; } }

@media only screen and (min-width: 700px) {
  .menu {
    bottom: 0;
    width: 66px;
    text-align: center;
    flex-direction: column !important; }
    .menu > .footer {
      margin-top: auto; }
    .menu > a {
      padding: 0.1rem 0;
      margin-bottom: 12px;
      border-radius: 2px; }
      .menu > a:first-child {
        margin-top: 15px;
        margin-bottom: 50px; }
      .menu > a:hover {
        background: #fff !important;
        color: #3e4049 !important;
        color: var(--colorToolTip) !important; } }

@media only screen and (min-width: 700px) {
  .menu_custom {
    bottom: 0;
    width: 66px;
    text-align: center;
    flex-direction: column !important; }
    .menu_custom > .footer {
      margin-top: auto; }
    .menu_custom > a {
      padding: 0.57rem 0;
      margin-bottom: 12px;
      border-radius: 2px; }
      .menu_custom > a:first-child {
        margin-top: 15px;
        margin-bottom: 50px; } }

@media only screen and (max-width: 700px) {
  .btn-menu-dropdown .btn-menu {
    line-height: 48px !important;
    border: none;
    background-color: transparent;
    color: #fff; }
  .btn-menu-dropdown .dropdown-menu .dropdown-item:hover {
    background: #fff !important;
    color: #3e4049 !important; }
  .dropdown-menu.px-2.show {
    position: relative;
    margin-top: -53px;
    /* margin-right: 77px; */ } }

@media only screen and (min-width: 700px) {
  .tudo {
    display: flex;
    flex-direction: row;
    border: solid 1px red; }
  .SubDropMenu {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 300px;
    background-color: #3e4049;
    border-radius: 5px !important;
    margin-left: 70px; }
  .notificacao-menu {
    margin-left: 30px; }
  .btn-menu-dropdown {
    position: relative; }
    .btn-menu-dropdown.hover-footer {
      border: solid 1px red; }
    .btn-menu-dropdown .btn-menu {
      cursor: pointer;
      width: 25px;
      padding-right: 18px;
      margin-bottom: 20px !important;
      border-radius: 5px !important;
      text-align: center !important;
      font-size: 12px !important;
      background-color: transparent;
      color: #fff;
      border: none; }
      .btn-menu-dropdown .btn-menu.white:hover {
        border: 1px solid #fff; }
      .btn-menu-dropdown .btn-menu.blue {
        width: 100%;
        height: 100%; }
      .btn-menu-dropdown .btn-menu.blue:hover:hover {
        margin-top: -4px;
        padding-top: 5px;
        background: #fff !important;
        color: #3e4049 !important; }
    .btn-menu-dropdown .dropdown-menu {
      box-shadow: 1px 2px 50px rgba(0, 0, 0, 0.3);
      border: none;
      background-color: white !important;
      top: -10px;
      left: 58px;
      margin-left: 5px;
      padding: 0px; }
      .btn-menu-dropdown .dropdown-menu .dropdown-item {
        margin: 0px;
        width: 100%;
        color: #fff;
        background-color: white; }
        .btn-menu-dropdown .dropdown-menu .dropdown-item:hover {
          border-radius: 2px;
          margin-left: -8px;
          width: 106%;
          background: rgba(75, 17, 150, 0.3) !important;
          background: var(--corHoverItem) !important;
          color: #4B1196 !important;
          color: var(--fontColor) !important; }
        .btn-menu-dropdown .dropdown-menu .dropdown-item.usuario {
          padding: 15px;
          color: #4B1196;
          color: var(--fontColor);
          font-weight: 700; }
          .btn-menu-dropdown .dropdown-menu .dropdown-item.usuario i {
            margin-right: 25px;
            margin-bottom: -5px; } }

@media only screen and (min-width: 320px) and (max-width: 767.98px) {
  .imagem-menu-mobile {
    display: flex;
    margin-left: auto; }
  .imagem-dash-menu {
    width: 100%;
    margin: auto; }
  .ul-item-menu {
    margin-top: -29px;
    height: auto;
    background: #3b0675;
    background: var(--bgcMenu); }
  .li-item-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.05);
    border-radius: 0px 8px 8px 0px;
    margin-top: 20px; }
  .toggle-menu-hide {
    display: none; }
  .toggle-menu-show {
    display: block;
    padding-top: 30px; }
  .ul-menu {
    padding: 30px; }
  .position-menu {
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.4);
    position: fixed;
    display: block;
    overflow: auto;
    margin-top: -5px;
    width: 100%;
    height: 380px;
    z-index: 100; }
  .li-item-menu-proposicao {
    position: absolute;
    color: white;
    list-style: none;
    left: 90px;
    top: 92px;
    z-index: 1000; }
  .li-item-menu-texto-agenda {
    position: absolute;
    color: white;
    list-style: none;
    left: 90px;
    z-index: 1000; }
  .li-item-menu-texto-parlamentar {
    position: absolute;
    color: white;
    list-style: none;
    left: 90px;
    top: 130px;
    z-index: 1000; }
  .tooltip-notification {
    padding: 0.75em;
    position: absolute;
    left: 60px;
    bottom: 1em;
    text-align: left;
    height: 35em;
    width: 20em;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    top: 50px; }
  .menu-texto-mobile {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    line-height: 28px;
    color: #ffffff; }
  .menu {
    background-color: #3b0675;
    background-color: var(--bgcMenu);
    bottom: 50px; } }

@media only screen and (min-width: 320px) and (max-width: 767.98px) {
  .imagem-menu-mobile_custom {
    display: flex;
    margin-left: auto; }
  .imagem-dash-menu_custom {
    width: 100%;
    margin: auto; }
  .ul-item-menu_custom {
    background: #004177; }
  .li-item-menu_custom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.05);
    border-radius: 0px 8px 8px 0px;
    margin-top: 20px; }
  .toggle-menu-hide_custom {
    display: none; }
  .toggle-menu-show_custom {
    display: block;
    margin-top: 5px; }
  .ul-menu_custom {
    padding: 30px; }
  .position-menu_custom {
    position: fixed;
    float: left;
    display: block;
    margin-top: 5px;
    width: 100%;
    z-index: 100; }
  .li-item-menu-proposicao {
    position: absolute;
    color: white;
    list-style: none;
    left: 90px;
    top: 92px;
    z-index: 1000; }
  .li-item-menu-texto-agenda {
    position: absolute;
    color: white;
    list-style: none;
    left: 90px;
    z-index: 1000; }
  .li-item-menu-texto-parlamentar {
    position: absolute;
    color: white;
    list-style: none;
    left: 90px;
    top: 130px;
    z-index: 1000; }
  .tooltip-notification {
    padding: 0.75em;
    position: absolute;
    left: 60px;
    bottom: 1em;
    text-align: left;
    height: 35em;
    width: 20em;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    top: 50px; }
  .menu-texto-mobile_custom {
    font-size: 16px;
    line-height: 22px;
    color: #ffffff; }
  .menu_custom {
    background-color: #004177;
    bottom: 50px; } }

.icones-menu-principal {
  padding: 5px; }

.icones-menu-principal:hover path {
  fill: #3e4049;
  fill: var(--colorToolTip); }

/* MEDIA QUERY */
/* Extra small devices (portrait phones, less than 576px)*/
/*/ Small devices (landscape phones, 576px and up)*/
/*/ Medium devices (tablets, 768px and up)*/
/*/ Large devices (desktops, 992px and up)*/
/* Extra large devices (large desktops, 1200px and up)*/
/*
  * Sidebar
  */
.sidebar {
  position: fixed;
  bottom: 0;
  z-index: 50;
  /* Behind the navbar */
  padding: 68px 15px 0;
  background-color: #EEF1F6;
  transition: all 0.5s;
  display: flex !important;
  flex-direction: column !important; }

@media only screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    top: 50px;
    left: -100%; }
    .sidebar.open {
      left: 0 !important; } }

@media only screen and (min-width: 700px) {
  .sidebar {
    width: 225px;
    top: 0;
    left: -159px; }
    .sidebar.open {
      left: 66px !important; } }

.media-item {
  display: flex;
  align-items: flex-start;
  padding: 12px 15px;
  border-radius: 2px;
  transition: all 0.5s; }
  .media-item > i {
    align-self: center !important;
    margin-right: 1rem !important; }
  .media-item.hover-border {
    border: 1px solid transparent !important; }
    .media-item.hover-border:hover {
      cursor: pointer;
      border: 1px solid #EEF1F6 !important; }
  .media-item.border:hover {
    cursor: pointer;
    border: 1px solid #008BFF !important; }
    .media-item.border:hover h6 {
      transition: all 0.5s;
      color: #008BFF !important; }
    .media-item.border:hover > i {
      color: #008BFF !important; }
  .media-item + .media-item {
    margin-top: 15px; }
  .media-item .list-inline {
    line-height: 1; }
  .media-item.interna {
    background-color: #F4FFEA;
    border-color: #F4FFEA !important;
    border-left: 4px solid #28a745 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin: 0 !important; }
    .media-item.interna h6 {
      color: #28a745 !important;
      transition: all 0.5s; }
    .media-item.interna:hover {
      border-color: #F4FFEA !important; }
      .media-item.interna:hover h6 {
        color: #057753 !important; }
  .media-item.externa {
    background-color: #EEF1F6;
    border-color: #EEF1F6 !important;
    border-left: 4px solid #6f42c1 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin: 0 !important; }
    .media-item.externa h6 {
      color: #6f42c1 !important;
      transition: all 0.5s; }
    .media-item.externa:hover {
      border-color: #EEF1F6 !important; }
      .media-item.externa:hover h6 {
        color: #6610f2 !important; }

.media-item.interna + .media-item.interna {
  margin: 0 !important; }

.media {
  margin-top: 15px; }
  .media > img {
    width: 27px !important;
    margin-right: 15px; }

@media only screen and (max-width: 700px) {
  .media-item {
    display: inherit;
    text-align: center;
    padding: 0; }
    .media-item > * {
      margin-top: 10px; } }

.carousel {
  border-bottom: 1px solid #EEF1F6;
  padding-bottom: 180px; }

.carousel-caption {
  position: initial !important;
  padding-bottom: 10px !important; }
  .carousel-caption > p {
    margin-bottom: 0; }

.carousel-indicators li {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #404040; }

.carousel-control-prev {
  left: -20px !important; }

.carousel-control-next {
  right: -20px !important; }

.card {
  border: none !important; }

.position {
  cursor: pointer;
  padding-top: 0.5rem !important;
  padding-bottom: 0.4rem !important;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  color: #929AA2;
  text-transform: uppercase;
  margin-bottom: 1rem;
  transition: all 0.5s; }
  .position:hover {
    color: #008BFF !important;
    border: 1px solid #008BFF; }
  .position > p {
    font-size: 10px;
    margin-bottom: 0; }
    .position > p > i {
      margin-right: 5px; }
  .position.opposite:hover, .position.opposite.active {
    color: #dc3545 !important;
    background-color: #fff2f3;
    border: none; }
  .position.neutral:hover, .position.neutral.active {
    color: #FFD436 !important;
    background-color: #fffcf1;
    border: none; }
  .position.favorable:hover, .position.favorable.active {
    color: #057753 !important;
    background-color: #EAFFF0;
    border: none; }

button:focus {
  outline: inherit;
  outline: 0px auto -webkit-focus-ring-color !important; }

.btn {
  font-weight: 600;
  transition: all 0.5s; }
  .btn.btn-file {
    height: 45px;
    background-color: #fff;
    border-color: #cdd8de !important;
    color: #172b4d;
    text-transform: uppercase;
    text-align: left; }
    .btn.btn-file > i {
      line-height: 1.5; }
    .btn.btn-file > span {
      vertical-align: super; }
    .btn.btn-file:hover {
      background-color: #172b4d;
      color: #fff; }

.form-group label {
  font-weight: 500;
  color: #172b4d;
  margin-left: 0.2rem; }

.btn-clean {
  font-weight: 400;
  color: #172b4d;
  text-transform: capitalize;
  text-align: left !important;
  padding: 0.58rem 0.8rem !important;
  transition: all 0.5s; }
  .btn-clean:hover, .btn-clean:focus, .btn-clean.active {
    color: #008BFF;
    background-color: #e1e8f2;
    border-color: transparent;
    box-shadow: none; }
  .btn-clean:disabled, .btn-clean.disabled {
    color: #818181;
    pointer-events: none; }
  .btn-clean > i {
    margin-right: 0.5rem !important; }

.dropdown-item:hover {
  color: #fff !important;
  background-color: #008bff !important; }

.dropdown-select {
  background-color: #EEF1F6 !important;
  width: 25px;
  height: 25px;
  padding: 0 0 13px 10px !important;
  border-radius: 50% !important;
  margin-top: px; }

.rounded-circle {
  border-radius: 50% !important;
  width: 4px;
  height: 4px;
  background-color: #929aa2;
  display: block;
  margin-top: 2px; }

.tag {
  display: block;
  width: 63px;
  height: 9px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  right: 40px; }
  .tag.purple {
    background-color: #7447a8; }
  .tag.green {
    background-color: #117c1d; }

.form-control {
  min-height: 40px; }
  .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #e1e8f2 inset; }

.input-group.input-inside .input-group-text {
  position: absolute;
  background-color: transparent;
  border: none;
  height: 40px;
  z-index: 10; }

.input-group.input-inside .input-group-append .input-group-text {
  right: 0; }

.input-group.input-inside .form-control {
  border-radius: 4px !important; }

.input-group.input-inside.left .form-control {
  padding-left: 40px; }

.input-group.input-inside.right .form-control {
  padding-right: 40px; }

/*

input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px white inset; }

*/
.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%; }

.react-datepicker-popper {
  width: 270px;
  height: 150px; }

.react-datepicker__time-container {
  display: flex; }

.react-datepicker__time-container {
  margin-right: 155px; }

.react-datepicker__time-list {
  margin-bottom: 30px; }

.react-datepicker__time-list-item {
  padding-right: 60px; }

.react-datepicker-time__header {
  margin-left: 111px; }

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 1px; }

.prazo-responsavel {
  margin: 0 auto; }
  @media screen and (min-width: 300px) and (max-width: 767.98px) {
    .prazo-responsavel {
      margin-left: 10px;
      margin-right: auto; } }

@media only screen and (max-width: 990px) {
  .mb-mobile-0 {
    margin-bottom: 0; }
  .mb-mobile-1 {
    margin-bottom: 0.25rem; }
  .mb-mobile-2 {
    margin-bottom: 0.5rem; }
  .mb-mobile-3 {
    margin-bottom: 1rem; }
  .mb-mobile-4 {
    margin-bottom: 1.5rem; }
  .mb-mobile-5 {
    margin-bottom: 3rem; } }

.text-wrap {
  word-wrap: break-word; }

.custom {
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: auto;
  margin-left: auto; }

.bglogin2 {
  height: 100%;
  width: 100%; }

.v-100 {
  height: 100vh; }

.logo-login {
  max-width: 274px; }

@media only screen and (max-width: 1024px) {
  .logo-login {
    margin-top: -140px; } }

@media only screen and (min-width: 1050px) {
  .logo-login {
    margin-top: -485px; } }

.span-login {
  display: block;
  width: 35px;
  margin: 0 auto -10px;
  background-color: #f3f5f7;
  font-weight: bold; }

@media screen and (max-width: 575.98px) {
  .form-login {
    padding: 1% 20px 0 20px; }
  #bglogin {
    height: 240px;
    width: 100%;
    z-index: -10; }
  .bglogin2 {
    height: 240px;
    width: 100%; }
  .cadastre {
    padding: 2% 0 25px 12%; } }

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .form-login {
    padding: 0px 30px; }
  .cadastre {
    padding: 2% 0 25px 47%; } }

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .form-login {
    padding: 30px; }
  .cadastre {
    padding: 7% 0 25px 29%; } }

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .form-login {
    padding: 30px 20px 0 20px; }
  .cadastre {
    padding: 7% 0 25px 44%; } }

@media screen and (min-width: 1200px) {
  .form-login {
    padding: 30px 20px 0 20px; }
    .form-login .cadastre {
      padding: 7% 0 25px 49%; }
  .div-titulo-login {
    margin-bottom: 30px; }
  .btnClient {
    background-color: #DC2D41; } }

/* MEDIA QUERY */
/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .titulo-login {
    font-weight: bold;
    color: "white";
    margin-top: 20px; }
  .topCriarConta {
    padding-top: 35px; }
  .imgBrasilia {
    content: url(/static/media/brasilia-img.fb5931fd.png);
    content: var(--imgBrasilia);
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 0; }
  .bglogin {
    background: linear-gradient(
        180deg,
        #1e0736 10.42%,
        #3b0675 27.6%,
        #3d0779 53.65%,
        #4b1196 80.21%,
        #6b09eb 98.44%
      ),
      #000000;
    background: var(--gradientLoginImg);
    opacity: 0.7;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 1; }
  .logoAlign {
    padding-top: 100px;
    align-items: normal;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 3; }
  .logoLogin {
    content: url(/static/media/logo-login-purpleClient.dcb804de.svg);
    content: var(--logoLogin);
    width: 200px;
    z-index: 3;
    overflow: hidden;
    position: absolute; }
  .divFormLoginMobile {
    width: 80%;
    margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(30, 7, 54, 0.7);
    background-color: var(--BackgroundFormLoginMobile);
    border-radius: 5px;
    padding-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 4; }
  .cadastre {
    padding: 25px 50px 0px 0px; }
  .overFlowDiv {
    overflow: hidden;
    margin-bottom: -150px; }
  .overFlowDivRight {
    padding-left: 20px;
    padding-right: 20px; }
  .divFormLogin {
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px; } }

/*/ Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .titulo-login {
    font-weight: bold; }
  .imgBrasilia, .bglogin, .logoLogin {
    color: #fff;
    display: block;
    line-height: 100px;
    position: absolute;
    align-content: center;
    width: 100px; }
  .imgBrasilia {
    content: url(/static/media/brasilia-img.fb5931fd.png);
    content: var(--imgBrasilia);
    width: 100%;
    z-index: 0; }
  .bglogin {
    background: linear-gradient(
        180deg,
        #1e0736 10.42%,
        #3b0675 27.6%,
        #3d0779 53.65%,
        #4b1196 80.21%,
        #6b09eb 98.44%
      ),
      #000000;
    background: var(--gradientLoginImg);
    opacity: 0.7;
    height: 100vh;
    width: 100%;
    z-index: 1; }
  .logoAlign {
    padding-top: 360px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2; }
  .logoLogin {
    content: url(/static/media/logo-login-purpleClient.dcb804de.svg);
    content: var(--logoLogin);
    width: 350px;
    z-index: 2; }
  .cadastre {
    padding: 25px 50px 0px 0px; }
  .overFlowDiv {
    overflow: hidden;
    height: 100vh; }
  .overFlowDivRightTop {
    overflow: hidden;
    height: 100vh; }
  .overFlowDivRight {
    overflow-y: auto;
    overflow-x: hidden;
    height: 650px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px; }
  .divFormLogin {
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px; } }

/*/ Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .titulo-login {
    font-weight: bold; }
  .imgBrasilia, .bglogin, .logoLogin {
    color: #fff;
    display: block;
    line-height: 100px;
    position: absolute;
    align-content: center;
    width: 100px; }
  .imgBrasilia {
    content: url(/static/media/brasilia-img.fb5931fd.png);
    content: var(--imgBrasilia);
    width: 100%;
    z-index: 0; }
  .bglogin {
    background: linear-gradient(
        180deg,
        #1e0736 10.42%,
        #3b0675 27.6%,
        #3d0779 53.65%,
        #4b1196 80.21%,
        #6b09eb 98.44%
      ),
      #000000;
    background: var(--gradientLoginImg);
    opacity: 0.7;
    height: 100vh;
    width: 100%;
    z-index: 1; }
  .logoAlign {
    padding-top: 360px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2; }
  .logoLogin {
    content: url(/static/media/logo-login-purpleClient.dcb804de.svg);
    content: var(--logoLogin);
    width: 280px;
    z-index: 2; }
  .cadastre {
    padding: 25px 50px 0px 0px; }
  .overFlowDiv {
    overflow: hidden;
    height: 100vh; }
  .overFlowDivRightTop {
    overflow: hidden;
    height: 100vh; }
  .overFlowDivRight {
    overflow-y: auto;
    overflow-x: hidden;
    height: 650px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px; }
  .divFormLogin {
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px; } }

/*/ Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .titulo-login {
    font-weight: bold; }
  .imgBrasilia, .bglogin, .logoLogin {
    color: #fff;
    display: block;
    line-height: 100px;
    position: absolute;
    align-content: center;
    width: 100px; }
  .imgBrasilia {
    content: url(/static/media/brasilia-img.fb5931fd.png);
    content: var(--imgBrasilia);
    width: 100%;
    z-index: 0; }
  .bglogin {
    background: linear-gradient(
        180deg,
        #1e0736 10.42%,
        #3b0675 27.6%,
        #3d0779 53.65%,
        #4b1196 80.21%,
        #6b09eb 98.44%
      ),
      #000000;
    background: var(--gradientLoginImg);
    opacity: 0.7;
    height: 100vh;
    width: 100%;
    z-index: 1; }
  .logoAlign {
    padding-top: 360px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2; }
  .logoLogin {
    content: url(/static/media/logo-login-purpleClient.dcb804de.svg);
    content: var(--logoLogin);
    width: 340px;
    z-index: 2; }
  .cadastre {
    padding: 25px 50px 0px 0px; }
  .overFlowDiv {
    overflow: hidden;
    height: 100vh; }
  .overFlowDivRightTop {
    overflow: hidden;
    height: 100vh; }
  .overFlowDivRight {
    overflow-y: auto;
    overflow-x: hidden;
    height: 650px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
  .divFormLogin {
    padding-top: 100px;
    padding-left: 70px;
    padding-right: 70px; } }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .titulo-login {
    font-weight: bold; }
  .imgBrasilia, .bglogin, .logoLogin {
    color: #fff;
    display: block;
    line-height: 100px;
    position: absolute;
    align-content: center;
    width: 100px; }
  .imgBrasilia {
    content: url(/static/media/brasilia-img.fb5931fd.png);
    content: var(--imgBrasilia);
    width: 100%;
    z-index: 0; }
  .bglogin {
    background: linear-gradient(
        180deg,
        #1e0736 10.42%,
        #3b0675 27.6%,
        #3d0779 53.65%,
        #4b1196 80.21%,
        #6b09eb 98.44%
      ),
      #000000;
    background: var(--gradientLoginImg);
    opacity: 0.7;
    height: 100vh;
    width: 100%;
    z-index: 1; }
  .logoAlign {
    padding-top: 360px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2; }
  .logoLogin {
    content: url(/static/media/logo-login-purpleClient.dcb804de.svg);
    content: var(--logoLogin);
    width: 450px;
    z-index: 2; }
  .cadastre {
    padding: 25px 50px 0px 0px; }
  .overFlowDiv {
    overflow: hidden;
    height: 100vh; }
  .overFlowDivRightTop {
    overflow: hidden;
    height: 100vh; }
  .overFlowDivRight {
    overflow-y: auto;
    overflow-x: hidden;
    height: 750px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px; }
  .divFormLogin {
    padding-top: 100px;
    padding-left: 70px;
    padding-right: 70px; } }

.li-priority {
  width: calc(20% - 7px); }

.badge.badge-priority {
  cursor: pointer;
  display: block !important;
  background-color: transparent;
  border: 1px solid #e1e8f2;
  border-radius: 2px;
  height: 10px;
  width: 100%; }

.badge.bpt-1.disabled:hover {
  background-color: transparent;
  border: 1px solid #e1e8f2; }

.badge.bpt-1.active, .badge.bpt-1:hover {
  background-color: #C0DEFF;
  border: 1px solid #C0DEFF; }

.badge.bpt-2.disabled:hover {
  background-color: transparent;
  border: 1px solid #e1e8f2; }

.badge.bpt-2.active, .badge.bpt-2:hover {
  background-color: #A0CEFF;
  border: 1px solid #A0CEFF; }

.badge.bpt-3.disabled:hover {
  background-color: transparent;
  border: 1px solid #e1e8f2; }

.badge.bpt-3.active, .badge.bpt-3:hover {
  background-color: #70B5FF;
  border: 1px solid #70B5FF; }

.badge.bpt-4.disabled:hover {
  background-color: transparent;
  border: 1px solid #e1e8f2; }

.badge.bpt-4.active, .badge.bpt-4:hover {
  background-color: #49A0FF;
  border: 1px solid #49A0FF; }

.badge.bpt-5.disabled:hover {
  background-color: transparent;
  border: 1px solid #e1e8f2; }

.badge.bpt-5.active, .badge.bpt-5:hover {
  background-color: #258DFF;
  border: 1px solid #258DFF; }

.clip-ellipse {
  -webkit-clip-path: ellipse(60px 60px at center);
          clip-path: ellipse(60px 60px at center); }

.carton {
  cursor: pointer;
  color: #008BFF;
  background-color: #fafbfd;
  border: 2px solid #EEF1F6;
  border-radius: 4px;
  text-align: center;
  height: 100%;
  padding-bottom: 15px;
  transition: all 0.5s; }
  .carton img {
    margin: 0 auto;
    height: 165px; }
  .carton:hover {
    background-color: #EEF1F6; }
  .carton > i[class^="icon-"], .carton i[class*=" icon-"] {
    padding: 35px 0 10px;
    display: block; }
  .carton > i[class^="icon-"]:before, .carton i[class*=" icon-"]:before {
    line-height: 1.2; }
  .carton .favorite {
    position: absolute;
    top: 5px;
    left: 10px;
    text-align: left;
    text-indent: 5px;
    color: #FFD436; }

.wrapper {
  position: absolute;
  width: 200px;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-height: 400px;
  margin: auto; }

.loader {
  height: 100%;
  display: flex;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }
  .loader div {
    flex: 1 1;
    background: #49A0FF;
    -webkit-animation: go 0.8s infinite alternate ease;
            animation: go 0.8s infinite alternate ease; }
    .loader div:nth-child(1) {
      -webkit-animation-delay: -0.72s;
              animation-delay: -0.72s; }
    .loader div:nth-child(2) {
      -webkit-animation-delay: -0.64s;
              animation-delay: -0.64s; }
    .loader div:nth-child(3) {
      -webkit-animation-delay: -0.56s;
              animation-delay: -0.56s; }
    .loader div:nth-child(4) {
      -webkit-animation-delay: -0.48s;
              animation-delay: -0.48s; }
    .loader div:nth-child(5) {
      -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s; }
    .loader div:nth-child(6) {
      -webkit-animation-delay: -0.32s;
              animation-delay: -0.32s; }
    .loader div:nth-child(7) {
      -webkit-animation-delay: -0.24s;
              animation-delay: -0.24s; }
    .loader div:nth-child(8) {
      -webkit-animation-delay: -0.16s;
              animation-delay: -0.16s; }
    .loader div:nth-child(9) {
      -webkit-animation-delay: -0.08s;
              animation-delay: -0.08s; }
    .loader div:nth-child(10) {
      -webkit-animation-delay: 0s;
              animation-delay: 0s; }

@-webkit-keyframes go {
  100% {
    background: transparent;
    flex: 10 1;
    box-shadow: 0 0 0 transparent; } }

@keyframes go {
  100% {
    background: transparent;
    flex: 10 1;
    box-shadow: 0 0 0 transparent; } }

.badge {
  padding: 0.3em 0.93em 0.2em 0.93em !important;
  line-height: 1.3 !important;
  letter-spacing: 1.2px; }
  @media screen and (min-width: 320px) and (max-width: 767.98px) {
    .badge {
      font-size: 58%; } }

.badge-orange {
  color: #4C4C4C !important;
  font-size: 12px !important;
  text-transform: uppercase;
  margin-top: -3px;
  vertical-align: middle !important; }

.control-multi {
  border-radius: 4px;
  padding: 6px; }
  .control-multi .badge-multi {
    color: #172b4d;
    margin-right: 6px;
    margin-bottom: 1px; }
  .control-multi input {
    width: 80px;
    border: none; }
    .control-multi input:focus {
      outline: none; }
  .control-multi .mascara {
    position: absolute;
    width: calc(100% - 15px);
    background-color: #FFF;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 15px;
    max-height: 150px;
    overflow-y: scroll;
    margin-left: -6px;
    z-index: 1500; }
  .control-multi ul.list-group.list-group {
    cursor: pointer; }
  .control-multi.active {
    border-color: #008BFF; }

.ehHoje {
  letter-spacing: normal;
  background: red;
  width: 29px;
  border-radius: 30px;
  color: #fff !important;
  text-align: center;
  height: 29px;
  line-height: 1.4;
  font-size: 20px !important; }

.agenda .table {
  border-top: none; }

@media only screen and (min-width: 700px) {
  .agenda tr.dia {
    min-height: 181px; } }

.agenda:first-child {
  border-right: 2px dashed #cdd8de; }

.table h3 {
  margin: 0 auto; }

.intervalo {
  padding: 0.55rem 1rem;
  border-radius: 2px;
  color: #172b4d;
  vertical-align: top;
  min-width: 114px;
  text-align: center;
  text-transform: uppercase; }

@media print {
  body {
    margin: 0;
    padding: 0;
    background: none !important; }
  .content {
    background: none !important; } }

.agenda-print {
  font-family: Roboto; }
  .agenda-print header h1 {
    font-size: 1.8rem; }
  .agenda-print header h2 {
    font-size: 1.4rem;
    color: #686D73 !important;
    text-transform: uppercase; }
  .agenda-print hr {
    border-color: #D9D9E6; }
  .agenda-print h3 {
    text-transform: uppercase; }
  .agenda-print .neutro {
    color: #008BFF;
    font-weight: bold; }
  .agenda-print .contrario {
    color: #E55B56;
    font-weight: bold; }
  .agenda-print .favoravel {
    color: #01CE8C;
    font-weight: bold; }
  .agenda-print .data .numero {
    font-size: 1.8rem;
    border-radius: 150px;
    padding: 10px;
    background-color: #172b4d;
    color: white; }
  .agenda-print .data .titulo {
    font-size: 1.6rem;
    font-weight: 600;
    color: #172b4d !important;
    text-transform: uppercase; }
  .agenda-print .evento .horario {
    font-size: 1.6rem;
    font-weight: bold;
    color: #404346; }
  .agenda-print .evento .titulo {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #404346; }
    .agenda-print .evento .titulo .azul {
      color: #172B4D; }
  .agenda-print .evento .subtitulo {
    color: #686D73;
    font-size: 1.2rem; }
  .agenda-print .evento .local {
    font-size: 1.2rem;
    font-weight: bold;
    color: #404346; }
    .agenda-print .evento .local span {
      font-weight: normal; }
  .agenda-print .evento .item {
    font-size: 1.2rem;
    color: #686D73; }
    .agenda-print .evento .item strong {
      color: #172B4D;
      font-weight: normal; }
  .agenda-print .obs {
    border: 1px solid #E1E8F2;
    height: 130px; }
    .agenda-print .obs .titulo {
      background-color: #E1E8F2;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      color: #172B4D; }

.help {
  position: relative;
  z-index: 1000;
  margin-left: 10px; }
  .help .help-icone {
    font-size: 14px;
    border-radius: 150px;
    padding: 5px 10px;
    background-color: #172b4d;
    color: white; }
  .help .help-tooltip {
    background: white;
    border-radius: 5px;
    border: 1px solid #333;
    padding: 2px 5px;
    display: none;
    position: absolute; }

.help:hover .help-tooltip {
  display: block;
  margin-top: 10px; }

.entradaArquivo {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

/*
  * Timeline
  */
.timeline {
  padding: 0;
  margin: 0; }
  .timeline .time-format {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: center; }
  .timeline li {
    position: relative;
    padding-left: 3em;
    list-style: none; }
  .timeline > li:hover {
    color: black; }
  .timeline > li:before {
    font-family: "icons";
    font-size: 10px;
    content: "\F105";
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    position: absolute;
    top: 0;
    left: 0;
    width: 2.1em;
    height: 2.1em;
    line-height: 2.1em;
    padding: 0px;
    margin-top: 5px;
    color: #fff;
    background: #2980b9;
    font-weight: bold;
    text-align: center;
    border-radius: 1em;
    z-index: 1;
    transition: all 0.5s; }
  .timeline > li.disabled:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2.1em;
    height: 2.1em;
    line-height: 2.1em;
    padding: 0px;
    margin-top: 5px;
    color: #fff;
    background: #fff;
    border: 2px solid #cdd8de;
    font-weight: bold;
    text-align: center;
    border-radius: 1em;
    z-index: 1;
    transition: all 0.5s; }
  .timeline > li:after {
    position: absolute;
    top: 1.4em;
    left: 0.7em;
    width: 1px;
    height: 100%;
    content: '';
    background-color: #5DA6F5;
    z-index: 0; }
  .timeline > li.disabled:after, .timeline > li.now:after {
    background-color: #cdd8de; }
  .timeline > li li {
    font-size: 0.8em; }
  .timeline > li ul {
    padding: 0; }
  .timeline > li ul li:before {
    content: "";
    position: absolute;
    top: 0;
    left: 4px;
    width: 0.92em;
    height: 0.92em;
    line-height: 0.92em;
    margin-top: 3px;
    color: #fff;
    background: #5C707F;
    border-radius: 1em; }
  .timeline > li ul li:after {
    position: absolute;
    top: 1.1em;
    left: 0.7em;
    width: 1px;
    height: calc(100% + 1em);
    content: '';
    background-color: #5C707F;
    z-index: 0; }
  .timeline > li:last-child:after, .timeline > li ul li:last-child:after {
    content: none; }

.retirar-border {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0; }

.comissoes-timeline {
  border-bottom: 3px solid #d8d8d8;
  cursor: pointer;
  width: 100%; }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    .comissoes-timeline {
      border-bottom: none; } }

.comissoes-timeline-active {
  border-bottom: 3px solid #dc2d41;
  width: 100%; }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    .comissoes-timeline-active {
      border-bottom: none; } }

.acordionsBordas {
  border: 1px solid #ccc !important;
  padding: 0.01em 16px;
  border-radius: 16px;
  margin: 0 0 10px 0; }

a > .comissoes-timeline:active {
  border-bottom: 3px solid #dc2d41;
  width: 100%;
  -webkit-text-decoration: 3px solid #dc2d41;
          text-decoration: 3px solid #dc2d41; }

.card-timeline {
  padding: 20px;
  margin: 10px;
  background-color: white; }

.circulo-timeline {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 150px; }

.circulo-timeline-atual {
  background-color: #3E4049;
  color: white; }

.circulo-timeline-concluido {
  background-color: #4CD964;
  color: white; }

.nome-comissao {
  color: #3E4049;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer; }

.acontecimento-timeline {
  width: 100%;
  padding: 20px;
  background: #D8D8D8;
  border-radius: 5px; }

.data-timeline {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #3E4049; }

.descricao-timeline {
  color: #3E4049; }

.timeline-image {
  margin-top: -5px; }

.status-atual-timeline {
  color: #3E4049;
  margin-top: -5px; }

.status-concluido-timeline {
  color: #4CD964;
  margin-top: -5px; }

.linha-timeline {
  width: 2px;
  height: 100%;
  background-color: #D8D8D8;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: 10%; }

.div-correcao {
  margin-left: -40px; }

body {
  font: normal 16px/1.5 'Roboto', sans-serif;
  background: #f1f1f1; }

/* Styles to Graph Index Results Actions*/
.donutchart-innertext {
  display: none; }

g.donutchart-arcs {
  -webkit-transform: translate(-316px, -17px);
          transform: translate(-316px, -17px); }

.donutchart {
  width: 550px;
  height: 165px;
  margin: 10px 0; }

rect.exitoStyle, path.exitoStyle {
  fill: #00ce8c;
  opacity: 1;
  rx: 100;
  ry: 100;
  width: 15px;
  height: 15px; }

.donutchart-legend {
  display: none; }
  .donutchart-legend .donutchart-legend-item {
    -webkit-transform: translate(500, -10);
            transform: translate(500, -10); }
  .donutchart-legend .donutchart-legend-item-label {
    width: 26.5;
    height: 8.5; }

rect.exitoParcialStyle, path.exitoParcialStyle {
  fill: #ffb15e;
  opacity: 1;
  rx: 100;
  ry: 100; }

rect.pendenteStyle, path.pendenteStyle {
  fill: #008bff;
  opacity: 1;
  rx: 100;
  ry: 100; }

rect.semExitoStyle, path.semExitoStyle {
  fill: #f91552;
  opacity: 1;
  rx: 100;
  ry: 100; }

.legend {
  list-style: none;
  margin-top: 16px;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px; }
  .legend li {
    margin-bottom: 12px;
    font-size: 12px; }
    .legend li span {
      border: none;
      float: left;
      width: 13px;
      height: 13px;
      margin: 4px 10px 5px 5px;
      border-radius: 8px; }
      .legend li span.exito {
        background-color: #00CE8C; }
      .legend li span.pendente {
        background-color: #FFB15E; }
      .legend li span.exito-parcial {
        background-color: #008BFF; }
      .legend li span.sem-exito {
        background-color: #F91552; }

/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@-webkit-keyframes rotate-one {
  100% {
    -webkit-transform: rotate(-0.4deg);
            transform: rotate(-0.4deg);
    /**
     * 12% => 21.6deg
     */ } }
@keyframes rotate-one {
  100% {
    -webkit-transform: rotate(-0.4deg);
            transform: rotate(-0.4deg);
    /**
     * 12% => 21.6deg
     */ } }

@-webkit-keyframes rotate-two {
  0% {
    -webkit-transform: rotate(21.6deg);
            transform: rotate(21.6deg); }
  100% {
    -webkit-transform: rotate(79.2deg);
            transform: rotate(79.2deg);
    /**
     * 32% => 57.6deg 
     * 57.6 + 21.6 => 79.2deg
     */ } }

@keyframes rotate-two {
  0% {
    -webkit-transform: rotate(21.6deg);
            transform: rotate(21.6deg); }
  100% {
    -webkit-transform: rotate(79.2deg);
            transform: rotate(79.2deg);
    /**
     * 32% => 57.6deg 
     * 57.6 + 21.6 => 79.2deg
     */ } }

@-webkit-keyframes rotate-three {
  0% {
    -webkit-transform: rotate(79.2deg);
            transform: rotate(79.2deg); }
  100% {
    -webkit-transform: rotate(140.4deg);
            transform: rotate(140.4deg);
    /**
     * 34% => 61.2deg 
     * 61.2 + 79.2 => 140.4deg
     */ } }

@keyframes rotate-three {
  0% {
    -webkit-transform: rotate(79.2deg);
            transform: rotate(79.2deg); }
  100% {
    -webkit-transform: rotate(140.4deg);
            transform: rotate(140.4deg);
    /**
     * 34% => 61.2deg 
     * 61.2 + 79.2 => 140.4deg
     */ } }

@-webkit-keyframes rotate-four {
  0% {
    -webkit-transform: rotate(140.4deg);
            transform: rotate(140.4deg); }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    /**
     * 22% => 39.6deg  
     * 140.4 + 39.6 => 180deg
     */ } }

@keyframes rotate-four {
  0% {
    -webkit-transform: rotate(140.4deg);
            transform: rotate(140.4deg); }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    /**
     * 22% => 39.6deg  
     * 140.4 + 39.6 => 180deg
     */ } }

@media (max-width: 425px) {
  g.donutchart-arcs {
    -webkit-transform: translate(216px, -17px);
            transform: translate(216px, -17px); }
  .donutchart {
    width: 200px; } }

.modal_centered {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75); }

.modal--container {
  padding: 30px 20px;
  background: white;
  border-radius: 5px;
  width: 600px;
  max-width: 100%; }

.modal--steps {
  padding: 10px 0; }
  .modal--steps ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex; }
    .modal--steps ul li {
      position: relative;
      margin-right: 10px;
      padding-right: 50px;
      color: #929AA2; }
      .modal--steps ul li a {
        color: #929AA2; }
      .modal--steps ul li.active {
        color: #172b4d;
        font-weight: 500; }
        .modal--steps ul li.active .modal--steps-number {
          background: #172b4d; }
      .modal--steps ul li::after {
        content: '';
        height: 1px;
        width: 40px;
        position: absolute;
        right: 0;
        top: 10px;
        background: #cdd8de; }
      .modal--steps ul li:last-child::after {
        display: none; }
  .modal--steps-number {
    display: inline-block;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: #929AA2;
    color: white;
    text-align: center;
    margin-right: 5px; }

.modal--form-inputs {
  margin-bottom: 30px; }

.modal--btn-files.btn {
  background: #e1e8f2;
  color: #008BFF; }
  .modal--btn-files.btn:hover {
    background: #008BFF;
    color: #e1e8f2; }

.modal .form-control-filter {
  width: 150px;
  margin-left: 10px; }

.modal--notas {
  height: 270px;
  overflow: auto;
  margin-top: 20px;
  padding-right: 20px; }
  .modal--notas-items {
    padding-bottom: 20px; }
    .modal--notas-items-title {
      color: #008BFF;
      background: #e1e8f2;
      border-radius: 5px 5px 0 0;
      display: inline-block;
      padding: 5px 15px; }
    .modal--notas-items-row {
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 0 8px 8px 0; }
      .modal--notas-items-row_info {
        font-size: 12px;
        padding: 0 10px;
        font-weight: 500; }

.modal--bar-posicionamento {
  margin-top: 20px; }
  .modal--bar-posicionamento.btn {
    padding: 15px 20px; }
  .modal--bar-posicionamento_left span {
    font-size: 18px; }
  .modal--bar-posicionamento_right {
    text-transform: uppercase; }
    .modal--bar-posicionamento_right i {
      font-size: 10px; }

.modal--breadcrumb.breadcrumb {
  padding: 0; }

.modal .row--impact .form-control {
  padding: 0.5rem 1rem; }
  .modal .row--impact .form-control.opposite {
    color: #dc3545 !important;
    background-color: #fff2f3; }
  .modal .row--impact .form-control.neutral {
    color: #FFD436 !important;
    background-color: #fffcf1; }
  .modal .row--impact .form-control.favorable {
    color: #057753 !important;
    background-color: #EAFFF0; }
  .modal .row--impact .form-control.negative {
    color: #fff !important;
    background-color: #dc3545; }

.modal .row--justification {
  margin-top: 20px; }
  .modal .row--justification .overflow-auto {
    max-height: 130px;
    padding-right: 20px; }

.button-container {
  display: flex;
  margin-top: 20px; }
  .button-container > button {
    font-weight: normal;
    padding: 0.4rem 2rem; }
  .button-container-right {
    justify-content: flex-end; }
    .button-container-right > button {
      margin-left: 10px; }

.list-none {
  list-style: none; }

.thumbnail {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden; }

.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.thumbnail img.portrait {
  width: 100%;
  height: auto; }

.linha-vertical-midia {
  border-right: 1px solid #E3E3E3; }

.palavra-chave-midia {
  font-size: 16px;
  color: #3E4049; }

#remover-bordas-inputs-midia .form-control:focus {
  color: #292a2b;
  background-color: #fff;
  border-color: #cdd8de;
  outline: 0;
  box-shadow: none;
  border-right: 0; }

#remover-bordas-inputs-midia .form-control {
  border-right: 0; }

.btn-editExc-proposicoes {
  background-color: #FFF !important;
  border-radius: 0px !important;
  color: #9B9B9B !important;
  font-weight: normal !important;
  border: 2px solid #9B9B9B !important;
  border-radius: 5px !important;
  padding: 26px; }

.quantidadeAcoesPreparatorias {
  font-size: 1.2rem;
  font-weight: bold; }

.quantidadeAcoesPreparatoriasAtraso {
  border-bottom: #4b1196;
  border-bottom: var(--linkText);
  color: #4b1196;
  color: var(--linkText);
  font-size: 0.9rem; }

.area-listar-proposicao {
  background-color: #f5f5f5; }

.card-proposicao-estadual-municipal {
  border: 1px solid #CDD8DE;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-color: white; }

.icone-proposicao {
  font-size: 2.5rem; }

.descricao-proposicao {
  font-weight: bold;
  font-size: 20px; }

.data-atualizacao-proposicao {
  color: #979797; }

.subtitulo-proposicao {
  font-weight: bold; }

.tipo-proposicao {
  color: white;
  background-color: #004177;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  padding: 5px;
  border-radius: 4px;
  letter-spacing: 1.2px; }

.botao-filtro-proposicao {
  background-color: #4b1196;
  background-color: var(--btndangerbordercolor);
  border: 2px solid #4b1196;
  border: 2px solid var(--btndangerbordercolor);
  padding: 16px;
  color: white; }

.texto-botao-filtro-proposicao {
  color: white;
  font-size: 17px; }

.botao-importar-proposicao {
  background-color: white;
  border: 2px solid #3E4049;
  padding: 16px;
  color: #3E4049;
  font-size: 17px; }

.margin-top-botoes {
  margin-top: 20px; }

.area-total {
  background-color: #f5f5f5; }

.container-proposicao {
  margin-left: 50px;
  margin-right: 50px; }

.area-formulario-filtro {
  margin-top: 50px;
  background-color: #f5f5f5; }

.area-pesquisa-filtro {
  margin-top: 50px;
  background-color: white;
  border-radius: 4px;
  width: 70%; }

.titulo-filtro {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 41px;
  color: #3E4049; }

.margin-botao {
  margin-top: 40px; }

.botao-ativo {
  background-color: #039EFB;
  border: 2px solid #039EFB;
  padding: 16px;
  color: white;
  font-size: 17px; }

.botao-nao-ativo {
  background-color: white;
  border: 2px solid #3E4049;
  padding: 16px;
  color: #3E4049;
  font-size: 17px; }

.padding-pesquisa {
  padding: 18px; }

.titulo-pesquisa {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #3E4049; }

.tipo-pesquisa-selecao {
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #3E4049; }

.descricao-pesquisa-selecao {
  background-color: #039EFB;
  border-radius: 4px;
  padding: 10px;
  margin: 0;
  color: white; }

.area-excluir-selecao {
  border-left: 1px solid #F5F5F5;
  cursor: pointer; }

.area-excluir-selecao-x {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center; }

.botao-resetar-filtro {
  margin-top: 40px;
  border: 2px solid #3E4049;
  background-color: white;
  color: #3E4049;
  padding: 15px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px; }

.form-margin-top {
  margin-top: 40px; }

.label-form-filtro {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #039EFB; }

.parlamentar-card-outros-titulo-seta {
  cursor: pointer; }

.foto-parlamentar-proposicao {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 20px; }

.botao-ia {
  background-color: #3E4049;
  color: white;
  width: 100%;
  padding: 8px; }

.botao-descartar-correlatas {
  color: red;
  cursor: pointer; }

.limpar-filtro {
  color: #4b1196;
  color: var(--linkText);
  border-bottom: 1px solid #4b1196;
  border-bottom: 1px solid var(--linkText);
  font-size: 0.8rem;
  cursor: pointer; }

.remove-link-proposicao {
  font-style: normal;
  font-weight: normal;
  font-size: .8rem;
  text-align: center; }

.bordas-links-proposicao .nav-link {
  color: #d8d8d8;
  border-bottom: 3px solid #bebebe;
  padding: 0; }
  .bordas-links-proposicao .nav-link.active {
    color: #3e4049;
    font-weight: bold;
    border-bottom: 3px solid #4b1196;
    border-bottom: 3px solid var(--bordas-links-insights-active); }

.proposicao-arrow .slick-prev {
  left: -15px; }

.proposicao-arrow .slick-next {
  right: -15px; }

.label-analise-impacto-proposicao {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #3E4049; }

.inputAcaoInfluencia {
  border: none;
  border-color: #cccccc;
  outline: 0;
  box-shadow: none; }

.selectAmbitoAcaoInfluencia {
  border: 1px solid grey;
  height: 140px;
  overflow-x: auto; }

.optionAmbitoAcaoInfluencia {
  width: 100%;
  border-bottom: 1px solid grey;
  cursor: pointer;
  padding: 5px; }

.borderBotaoModalAcaoInfluencia {
  cursor: pointer;
  margin-top: 20px;
  padding: 15px;
  border-top: 1px solid #b3b2b2; }

.borderBotaoModalAcaoInfluenciaLeft {
  border-right: 1px solid #b3b2b2; }

.optionUsuauriosAcaoInfluencia {
  margin-top: 10px;
  width: 280px;
  overflow-y: auto; }

.botaoFecharModalEmendas {
  cursor: pointer;
  margin-top: 20px;
  padding: 15px;
  border-top: 1px solid #b3b2b2; }

@media screen and (max-width: 575.98px) {
  .marginTopXs {
    margin-top: 50px; } }

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .marginTopXs {
    margin-top: 50px; } }

/* MEDIA QUERY POPOSICAO LISTA*/
/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .botaoSeguir {
    width: 100%;
    margin-top: 20px; }
  .casaStyle {
    font-Size: 15px;
    color: black;
    padding-Left: 8px;
    margin-top: -7px;
    margin-bottom: 8px; }
  .topSpaceCadastroProp {
    margin-top: 10px;
    padding-left: 18px; }
  .marginCadastroProp {
    padding: 15px; }
  .MarginTopCampoCadastroProp {
    margin-top: 13px; } }

/*/ Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .botaoSeguir {
    width: 100%;
    margin-top: 20px; }
  .casaStyle {
    font-Size: 15px;
    color: black;
    padding-Top: 2px;
    padding-Left: 5px; }
  .topSpaceCadastroProp {
    margin-top: 20px; }
  .marginCadastroProp {
    padding: 50px; } }

/*/ Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .botaoSeguir {
    width: 100%;
    position: absolute;
    right: 0px; }
  .casaStyle {
    font-Size: 15px;
    color: black;
    padding-Top: 2px;
    padding-Left: 5px; }
  .topSpaceCadastroProp {
    margin-top: 20px; }
  .marginCadastroProp {
    padding: 50px; } }

/*/ Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .botaoSeguir {
    width: 78%;
    position: absolute;
    right: 0px; }
  .casaStyle {
    font-Size: 15px;
    color: black;
    padding-Top: 2px;
    padding-Left: 5px; }
  .topSpaceCadastroProp {
    margin-top: 20px; }
  .marginCadastroProp {
    padding: 50px; } }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .botaoSeguir {
    width: 78%;
    position: absolute;
    right: 0px; }
  .casaStyle {
    font-Size: 15px;
    color: black;
    padding-Top: 2px;
    padding-Left: 5px; }
  .topSpaceCadastroProp {
    margin-top: 20px; }
  .marginCadastroProp {
    padding: 50px; } }

.background-parlamentar-card {
  background-color: white; }

.parlamentar-card-proposicoes {
  border: 1px solid #CDD8DE;
  border-radius: 4px;
  width: 100%;
  padding: 20px; }

.parlamentar-card-proposicoes-descricao {
  color: #3E4049;
  font-size: 20px;
  font-weight: bold; }

.parlamentar-card-proposicoes-data {
  color: #929AA2;
  margin-left: 40px; }

.parlamentar-card-proposicoes-ementa {
  color: #4C4C4C;
  font-size: 16px;
  margin-top: 20px; }

.parlamentar-card-comissoes {
  border: 1px solid #CDD8DE;
  border-radius: 4px;
  width: 100%; }

.parlamentar-card-comissoes-descricao {
  color: #3E4049;
  font-size: 20px;
  font-weight: bold; }

.parlamentar-card-comissoes-titulo {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #3E4049; }

.parlamentar-card-discursos {
  border: 1px solid #CDD8DE;
  border-radius: 4px;
  width: 100%; }

.parlamentar-card-discursos-descricao {
  color: #3E4049;
  font-size: 20px;
  font-weight: bold; }

.parlamentar-card-discursos-titulo {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #3E4049; }

.parlamentar-card-discursos-descartar {
  border-top: 1px solid #CDD8DE;
  color: #DC2D41; }

.parlamentar-card-discursos-click {
  cursor: pointer;
  border-bottom: 1px solid #DC2D41; }

.parlamentar-card-historico {
  border: 1px solid #CDD8DE;
  border-radius: 4px;
  width: 100%;
  padding: 20px; }

.parlamentar-card-historico-partido {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #3E4049; }

.parlamentar-card-outros-historico-titulo {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #3E4049; }

.parlamentar-card-frentes {
  border: 1px solid #CDD8DE;
  border-radius: 4px;
  width: 100%;
  padding: 20px; }

.parlamentar-card-frentes-partido {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #3E4049; }

.parlamentar-card-outros-frentes-titulo {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #3E4049; }

.parlamentar-card-eventos {
  border: 1px solid #CDD8DE;
  border-radius: 4px;
  width: 100%;
  padding: 20px; }

.parlamentar-card-eventos-partido {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #3E4049; }

.parlamentar-card-outros-eventos-titulo {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #3E4049; }

.mandato-atual {
  width: 20px;
  height: 20px;
  background-color: #3E4049;
  border-radius: 150px; }

.mandato-atual-detalhe {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 150px;
  left: 4px;
  top: 5px; }

.mandato-linha {
  position: relative;
  border-left: 1px solid #D8D8D8;
  height: 70px;
  left: 9px; }

.mandato-antigo {
  position: relative;
  width: 15px;
  height: 15px;
  background-color: #D8D8D8;
  border-radius: 150px;
  left: 2px; }

.titulo-grafico-linha {
  font-size: 23px;
  line-height: 41px;
  color: #3E4049;
  font-weight: bold; }

.titulo-grafico-pizza {
  font-size: 23px;
  line-height: 41px;
  color: #3E4049; }

.div-indice {
  margin-top: 50px; }

.indice-exito {
  width: 12px;
  height: 12px;
  border-radius: 150px;
  background-color: #4CD964; }

.indice-sem-exito {
  width: 12px;
  height: 12px;
  border-radius: 150px;
  background-color: #F63854; }

.indice-exito-parcial {
  width: 12px;
  height: 12px;
  border-radius: 150px;
  background-color: #5856D7; }

.indice-pendente {
  width: 12px;
  height: 12px;
  border-radius: 150px;
  background-color: #FFD200; }

.indice-contrario-emenda {
  width: 12px;
  height: 12px;
  border-radius: 150px;
  background-color: #007AFF; }

.indice-descricao {
  padding-left: 5px !important; }

.indice-sem-info {
  width: 12px;
  height: 12px;
  border-radius: 150px;
  background-color: #E1E8F2; }

.card-acao-influencia-parlamentar {
  background: #FFFFFF;
  border: 1px solid #E1E8F2;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 20px; }

.titulo-card-acao-influencia {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #3E4049; }

.numero-acoes-influencia {
  color: #F63854;
  font-size: 23px; }

.proposicao-descricao-acoes-influencia {
  font-size: 20px;
  line-height: 20px;
  color: #DC2D41;
  font-weight: bold; }

.lista-campo-influencia-sobre {
  font-size: 0.9rem; }

.click-excluir-influencia-sobre {
  margin-left: 5px;
  color: red;
  cursor: pointer; }

.input-influencia-sobre {
  border: none;
  box-shadow: none;
  outline: none; }

.sucesso-foto {
  border: 6px solid #4cd964; }

.negativo-foto {
  border: 6px solid #f63854; }

.pendente-foto {
  border: 6px solid #007aff; }

.neutro-foto {
  border: 6px solid #d8d8d8; }

.carousel-item1 img {
  border-radius: 50%;
  width: 150px;
  height: 150px; }

.carousel-item a img {
  border-radius: 50%;
  width: 150px;
  height: 150px; }

.card-anexo-pag-parlamentar {
  border: 1px solid #E1E8F2;
  padding: 15px; }

.menu-parlamentar {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #3E4049; }

.qualificacao-titulo-parlamentar {
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 1.2px;
  color: #9b9b9b; }

.biografia-parlamentar {
  color: #4b1196;
  color: var(--cliente); }

.biografia-parlamentar-link > a:hover {
  color: #4b1196;
  color: var(--cliente); }

.card-proposicao-parlamentar {
  border: 1px solid #E1E8F2;
  border-radius: 6px;
  padding: 20px; }

.parlamentar-card {
  background: #FFFFFF;
  border: 1px solid #CDD8DE;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px; }

.parlamentar-foto-default {
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 160px;
  border-radius: 150px;
  background-color: white; }

.parlamentar-foto-convergente {
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 160px;
  border-radius: 150px;
  background-color: #4CD964; }

.parlamentar-foto-divergente {
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 160px;
  border-radius: 150px;
  background-color: #F63854; }

.parlamentar-foto-nao-classificado {
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 160px;
  border-radius: 150px;
  background-color: #5856D7; }

.parlamentar-foto-neutro {
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 160px;
  border-radius: 150px;
  background-color: #D8D8D8; }

.parlamentar-foto-config {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 150px;
  height: 150px;
  border-radius: 150px;
  object-fit: contain;
  background-color: white; }

.parlamentar-nome {
  font-size: 19px;
  line-height: 22px;
  color: #3E4049; }

.parlamentar-tratamento {
  font-size: 16px;
  line-height: 22px;
  color: #3E4049; }

.parlamentar-partido {
  font-size: 16px;
  line-height: 20px;
  color: #9B9B9B; }

.parlamentar-botao-seguir {
  padding: 15px;
  border: none;
  width: 100%;
  background-color: #4b1196;
  background-color: var(--parlamentar-botao-seguir);
  color: white; }

.parlamentar-botao-seguindo {
  padding: 15px;
  border: none;
  width: 100%;
  background-color: white;
  color: #4b1196;
  color: var(--parlamentar-botao-seguir);
  border: 1px solid #4b1196;
  border: 1px solid var(--parlamentar-botao-seguir); }

.card-grafico-parlamentar {
  background-color: white;
  padding: 30px; }

.parlamentar-titulo-grafico-preto {
  font-size: 23px;
  line-height: 41px;
  text-align: center;
  color: #3E4049;
  font-weight: bold; }

.parlamentar-titulo-grafico-vermelho {
  font-size: 23px;
  line-height: 41px;
  text-align: center;
  color: #4b1196;
  color: var(--parlamentar-titulo-grafico);
  font-weight: bold; }

.link-indice-dashboard-parlamentar {
  cursor: pointer; }

.link-indice-dashboard-parlamentar:hover {
  color: blue;
  border-bottom: 1px solid blue;
  cursor: pointer; }

.link-discurso {
  color: #4b1196;
  color: var(--cliente); }

.link-discurso:hover {
  color: #4b1196;
  color: var(--cliente);
  border-bottom: 1px solid #4b1196;
  border-bottom: 1px solid var(--cliente); }

.foto-parlamentar {
  object-fit: contain;
  background-color: white; }

.qualificacao-modal-parlamentar {
  border-bottom: 1px solid black; }

.discurso-audio-video {
  cursor: pointer; }

.parlamentar-discurso-mensagem {
  background-color: #FEFEFE;
  font-size: 23px;
  line-height: 41px;
  color: #3E4049; }

/* **********TIMILINE PARLAMENTAR************** */
/* CONTAINER */
.container-global-timeline {
  margin-left: 50px; }

/* CONTAINER TIMILINE*/
.timeline {
  position: relative;
  max-width: 450px; }

/* **********CLASSES GERAIS********* */
.container-mandato {
  padding: 1px; }

/* LINHA TIMELINE LINHA*/
.timeline-linha-primeiro {
  position: absolute;
  width: 2px;
  height: 0%;
  background-color: #D8D8D8;
  top: 0;
  bottom: 0;
  left: 3%;
  margin-left: -3px; }

.timeline-linha {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #D8D8D8;
  top: 0;
  bottom: 0;
  left: 3%;
  margin-left: -3px; }

/* LINHA TIMELINE LINHA ULTIMO REGISTRO*/
.timeline-linha-ultimo {
  position: absolute;
  width: 2px;
  height: 0px;
  background-color: #D8D8D8;
  top: 0;
  bottom: 0;
  left: 3%;
  margin-left: -3px; }

.conteudo-titulo {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: #3E4049; }

.conteudo-descricao {
  font-size: 16px;
  line-height: 20px;
  color: #3E4049; }

/* PADDING EM % PARA COLOCAR CONTAINER A DIRETA */
.right {
  left: 0%; }

/* **********FIM CLASSES GERAIS********* */
/* *******CLASSES DE QUANDO ESTIVER ATIVO********* */
/* STATUS DO CIRCULO ATIVO*/
.timeline-ativa {
  position: absolute;
  width: 20px;
  height: 20px;
  right: -9px;
  background-color: white;
  border: 5px solid  #3E4049;
  top: 0px;
  border-radius: 50%;
  z-index: 1; }

/* CONTEUDO DO MANDATO*/
.mandato-ativo {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  padding: 0px 40px;
  color: #3E4049;
  /* border: 1px solid yellow; */ }

/*CONTAINER ATIVO */
.container-ativo {
  padding: 5px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
  margin-bottom: 20px;
  /* border: 1px solid yellow; */ }

/* CONTEUDO ATIVO*/
.conteudo-ativo {
  padding: 15px 30px;
  background-color: #D8D8D8;
  display: flex;
  flex-direction: column;
  border-radius: 6px; }

/* SPAN ATIVO */
.conteudo-ativo span {
  padding-bottom: 10px;
  font-size: 16px; }

/* ********FIM CLASSES ATIVAS******** */
/* ***********CLASSES QUANDO INATIVAS************* */
/*STATUS DO CIRCULO inATIVO */
.timeline-inativo {
  position: absolute;
  width: 20px;
  height: 20px;
  right: -9px;
  background-color: #D8D8D8;
  border: 5px solid #D8D8D8;
  top: 0px;
  border-radius: 50%;
  z-index: 1; }

/* CONTEUDO DO MANDATO*/
.mandato-inativo {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  padding: 0px 40px;
  color: #9B9B9B;
  /* border: 1px solid yellow; */ }

/* CONTAINER INATIVO*/
.container-inativo {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
  margin-bottom: 20px;
  /* border: 1px solid yellow; */ }

/* CONTEUDO INATTIVO */
.conteudo-inativo {
  padding: 15px 30px;
  background-color: #F0F0F0;
  display: flex;
  flex-direction: column;
  border-radius: 6px; }

/* SPAN INATIVO */
.conteudo-inativo span {
  padding-bottom: 10px;
  font-size: 16px; }

/* ********FIM CLASSES INATIVAS******** */
/* ******REPONSIVO**** */
/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  .marginTopXs {
    margin-top: 50px; }
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px; }
  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px; }
  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent; }
  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
    left: 15px; }
  /* Make all right containers behave like the left ones */
  .right {
    left: 0%; } }

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .marginTopXs {
    margin-top: 50px; } }

/* MEDIA QUERY MOBILE */
/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .hiddenMobileModelParlamentar {
    visibility: hidden;
    display: none; }
  .hiddenDeskModelParlamentar {
    visibility: visible; }
  .parlamentarEspecificodesk {
    height: 15em;
    overflow-y: scroll; } }

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .hiddenMobileModelParlamentar {
    visibility: hidden;
    position: absolute;
    display: none; }
  .parlamentarEspecificodesk {
    display: none; } }

/*/ Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .hiddenMobileModelParlamentar {
    visibility: visible;
    position: absolute; }
  .hiddenDeskModel {
    visibility: hidden;
    position: absolute;
    display: none; }
  .parlamentarEspecificoMobile {
    display: none; } }

/*/ Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hiddenMobileModelParlamentar {
    visibility: visible;
    position: absolute; }
  .hiddenDeskModel {
    visibility: hidden;
    position: absolute;
    display: none; }
  .parlamentarEspecificoMobile {
    display: none; } }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .hiddenDeskModelParlamentar {
    visibility: hidden;
    position: absolute;
    display: none; }
  .parlamentarEspecificoMobile {
    display: none; } }

.despacho-texto {
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  color: #292A2B; }

.tramitacao-data {
  font-size: 20px;
  line-height: 20px;
  color: #929AA2; }

.anexo-linha {
  padding: 10px;
  border: 1px solid #E1E8F2;
  color: #4b1196;
  color: var(--linkText);
  width: 100%; }
  @media screen and (min-width: 320px) and (max-width: 767.98px) {
    .anexo-linha {
      padding: 0px; } }

.nome-relator {
  font-size: 16px;
  line-height: 22px;
  color: #3E4049;
  border-radius: 2px;
  font-weight: bold; }

.casa-acontecimento {
  font-size: 19px;
  line-height: 22px;
  color: #172B4D;
  font-weight: bold; }

.delete-anexo {
  cursor: pointer; }

.acontecimento-texto {
  width: 100%;
  border: none;
  outline: none; }

.clipsAnexos {
  content: url(/static/media/clipsPreto.dc68b378.svg);
  content: var(--clipsAnexos);
  width: 11px; }

.Client-titulo {
  color: #3e4049;
  font-family: "NewJune-Semibold";
  font-size: 1.4375rem; }

.Client-titulo-header {
  color: #3e4049;
  font-family: "NewJune-Book";
  font-size: 1.4375rem; }

.Client-titulo-agrupador {
  color: #3e4049;
  font-family: "NewJune-Book";
  font-size: 1.25rem; }

.Client-titulo-card {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 1.25rem;
  display: flex;
  flex-direction: column; }

.Client-titulo-modal-t {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 23px;
  line-height: 41px;
  display: flex;
  flex-direction: column; }

.Client-titulo-modal-s {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: column; }

.Client-texto-destaque {
  color: #3e4049;
  font-family: "NewJune-Semibold";
  font-size: 1.1875rem; }

.Client-texto-form {
  color: #3e4049;
  font-family: "NewJune-Medium"; }

.Client-menu-iten {
  font-family: "NewJune-Medium";
  font-size: 0.7273rem; }

.Client-link {
  color: #4b1196;
  color: var(--cliente);
  font-family: "NewJune-Medium";
  font-size: 0.7273rem;
  text-decoration: underline; }
  @media screen and (min-width: 320px) and (max-width: 767.98px) {
    .Client-link {
      font-size: 0.5273rem; } }

.Client-texto {
  color: #4b1196;
  color: var(--cliente);
  font-family: "NewJune-Medium";
  font-size: 0.7273rem; }

.Client-paragrafo {
  color: #3e4049;
  font-family: "NewJune-Book";
  font-size: 0.7273rem; }

.Client-label {
  color: #3e4049;
  font-size: 0.75rem;
  margin-left: 5px; }

.Client-detalhe {
  color: #9b9b9b;
  font-family: "NewJune-Book";
  font-size: 0.7273rem; }

.Client-btn {
  padding: 15px;
  font-family: "NewJune-Book";
  font-size: 1.0625rem;
  text-align: center;
  cursor: pointer;
  display: block;
  text-transform: capitalize; }
  .Client-btn:active {
    font-size: 0.7273rem; }
  .Client-btn.btn-primario {
    background: #4b1196;
    background: var(--cliente);
    color: #ffffff;
    border: none; }
    .Client-btn.btn-primario.desabilitado {
      background: #c3c3c3;
      background: var(--clienteDesativado); }
    .Client-btn.btn-primario:hover {
      background: #222222;
      background: var(--clienteOver);
      color: #a269df;
      color: var(--clienteOverText); }
  .Client-btn.btn-secundario {
    background: #ffffff;
    color: #3e4049;
    border: 2px solid #3e4049; }
    .Client-btn.btn-secundario:hover {
      background: #3e4049;
      color: #ffffff; }

.Client-mini-card {
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 1em; }

.Client-btn-seguir {
  height: 55px;
  font-family: "NewJune-Book";
  font-size: 1.0625rem;
  text-align: center;
  cursor: pointer;
  display: block;
  text-transform: capitalize;
  width: 100%; }
  .Client-btn-seguir:active {
    font-size: 0.7273rem; }
  .Client-btn-seguir.btn-seguir {
    background: #ffffff;
    color: #4b1196;
    color: var(--cliente);
    border: 2px solid #4b1196;
    border: 2px solid var(--cliente); }
    .Client-btn-seguir.btn-seguir.desabilitado {
      background: #c3c3c3;
      background: var(--clienteDesativado); }
  .Client-btn-seguir.btn-seguindo {
    background: #4b1196;
    background: var(--cliente);
    color: #ffffff;
    border: none;
    text-align: center; }

.Client-input-esfor-valor-impact {
  display: block;
  background: none;
  border: none;
  width: 100%;
  border-bottom: 1px solid #3e4049;
  padding-top: 20px;
  outline: none;
  font-family: "NewJune-Medium"; }

input.Client-input {
  display: block;
  background: none;
  border: none;
  width: 100%;
  border-bottom: 1px solid #3e4049;
  padding-top: 14px;
  padding-bottom: 9.688px;
  outline: none;
  font-family: "NewJune-Medium"; }

input.Client-input-proposicao {
  display: block;
  background: none;
  border: none;
  width: 100%;
  border-bottom: 1px solid #3e4049;
  outline: none;
  font-family: "NewJune-Medium"; }

.Client-select {
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer; }
  .Client-select .option,
  .Client-select .select {
    display: flex;
    align-items: center;
    font-family: "NewJune-Medium";
    padding-top: 10px; }
  .Client-select .select {
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #3e4049;
    font-family: "NewJune-Medium"; }
    .Client-select .select.disable {
      border-bottom: none; }
  .Client-select .options {
    position: absolute;
    background: #ffffff;
    width: 100%;
    border: 1px solid #3e4049;
    z-index: 99;
    padding: 10px;
    transition: 1s; }
    .Client-select .options .option:hover {
      background: #d8d8d8; }

.Client-select-modal-esforco {
  display: block;
  width: 100%;
  cursor: pointer; }
  .Client-select-modal-esforco .option,
  .Client-select-modal-esforco .select {
    display: flex;
    align-items: center;
    font-family: "NewJune-Medium"; }
  .Client-select-modal-esforco .select {
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #3e4049;
    font-family: "NewJune-Medium"; }
    .Client-select-modal-esforco .select.disable {
      border-bottom: none; }
  .Client-select-modal-esforco .options {
    position: absolute;
    background: #ffffff;
    width: 100%;
    border: 1px solid #3e4049;
    z-index: 99;
    padding: 10px;
    transition: 1s; }
    .Client-select-modal-esforco .options .option:hover {
      background: #d8d8d8; }

.Client-select-analise-impacto {
  display: block;
  width: 100%;
  cursor: pointer; }
  .Client-select-analise-impacto .option,
  .Client-select-analise-impacto .select {
    display: flex;
    align-items: center;
    font-family: "NewJune-Medium"; }
  .Client-select-analise-impacto .select {
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #3e4049;
    font-family: "NewJune-Medium"; }
    .Client-select-analise-impacto .select.disable {
      border-bottom: none; }
  .Client-select-analise-impacto .options {
    position: absolute;
    background: #ffffff;
    width: 100%;
    border: 1px solid #3e4049;
    z-index: 99;
    padding: 20px;
    transition: 1s; }
    .Client-select-analise-impacto .options .option:hover {
      background: #d8d8d8; }

.label-disabled .font-weight-bold {
  color: grey; }

.Client-checkbox input[type="checkbox"] {
  display: none;
  /* Esconde os inputs */ }

.Client-checkbox label {
  cursor: pointer; }

.Client-checkbox input[type="checkbox"] + label:before {
  border: 2px solid #4b1196;
  border: 2px solid var(--cliente);
  content: "\A0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 20px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 20px;
  border-radius: 2px; }

.Client-checkbox input[type="checkbox"]:checked + label:before {
  color: #4b1196;
  color: var(--cliente);
  border-color: #d8d8d8;
  content: "\2713";
  text-align: center; }

.Client-checkbox input[type="checkbox"]:checked + label:after {
  font-weight: bold; }

.Client-circulo {
  border-radius: 100px;
  width: 10px;
  height: 10px;
  margin-right: 0.4em;
  margin-left: -0.3em;
  font-family: "NewJune-Medium"; }
  .Client-circulo.sucesso {
    background: #4cd964; }
  .Client-circulo.neutro {
    background: #d8d8d8; }
  .Client-circulo.negativo {
    background: #f63854; }
  .Client-circulo.mensuravel {
    background: #9b9b9b; }
  .Client-circulo.aviso {
    background: #ffd200; }
  .Client-circulo.pendente {
    background: #007aff; }
  .Client-circulo.favoravel {
    background: #5856d7; }
  .Client-circulo.favoravel2 {
    background: #5856d7; }
  .Client-circulo.prioridade-1 {
    background: #c3c3c3;
    background: var(--clienteDesativado); }
  .Client-circulo.prioridade-2 {
    background: #a269df;
    background: var(--clienteOverText); }
  .Client-circulo.prioridade-3 {
    background: #5a19af;
    background: var(--clienteClaro); }
  .Client-circulo.prioridade-4 {
    background: #4b1196;
    background: var(--cliente); }
  .Client-circulo.prioridade-5 {
    background: #222222;
    background: var(--clienteOver); }

.Client-angulo {
  border: solid #4b1196;
  border: solid var(--btndangerbackgroundcolor);
  border-width: 0 2.3px 2.3px 0;
  display: inline-block;
  padding: 0.2em; }
  .Client-angulo.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .Client-angulo.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .Client-angulo.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); }
  .Client-angulo.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.Client-comentario {
  width: 78%;
  padding: 0.5em;
  border-bottom: 1px solid #9b9b9b;
  outline: none; }
  @media screen and (min-width: 320px) and (max-width: 560px) {
    .Client-comentario {
      width: 57%; } }

.Client-comentario-button {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #9b9b9b; }

.Client-comentario-button {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  .Client-comentario-button img {
    position: relative;
    bottom: 0.2em; }

.Client-comentario-image img {
  width: 40px;
  border-radius: 100px; }

.quantidade-acao-influencia {
  color: #4b1196;
  color: var(--cliente); }

.Client-vermelho-aprovado-diretoria {
  color: #4b1196;
  color: var(--cliente);
  font-size: 16px;
  line-height: 20px; }

.Client-vermelho {
  color: #4b1196;
  color: var(--cliente);
  border-bottom: 1px solid #4b1196;
  border-bottom: 1px solid var(--cliente);
  font-size: 16px;
  line-height: 13px; }

.Client-vermelho-over {
  color: #222222;
  color: var(--clienteOver); }

.Client-vermelho-desativado {
  color: #c3c3c3;
  color: var(--clienteDesativado); }

.Client-vermelho-over-text {
  color: #a269df;
  color: var(--clienteOverText); }

.Client-vermelho-claro {
  color: #5a19af;
  color: var(--clienteClaro); }

.Client-bg-vermelho {
  background: #4b1196;
  background: var(--cliente); }

.Client-bg-vermelho-over {
  background: #222222;
  background: var(--clienteOver); }

.Client-bg-vermelho-desativado {
  background: #c3c3c3;
  background: var(--clienteDesativado); }

.Client-bg-vermelho-over-text {
  background: #a269df;
  background: var(--clienteOverText); }

.Client-bg-vermelho-claro {
  background: #5a19af;
  background: var(--clienteClaro); }

.Client-cinza-estrela {
  color: #bababa; }

.Client-cinza-adjacente {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 1.0625rem;
  line-height: 0.8182rem;
  margin-top: 8px; }

.Client-cinza-background {
  color: #d8d8d8; }

.Client-cinza-header {
  color: #2c2e35; }

.Client-cinza-claro {
  color: #9b9b9b; }

.Client-bg-cinza-estrela {
  background: #bababa; }

.Client-bg-cinza-adjacente {
  background: #3e4049; }

.Client-bg-cinza-background {
  background: #d8d8d8; }

.Client-bg-cinza-header {
  background: #2c2e35; }

.Client-bg-cinza-claro {
  background: #9b9b9b; }

.Client-roxo {
  color: #5856d7; }

.Client-bg-roxo {
  background: #5856d7; }

.Client-azul {
  color: #007aff; }

.Client-azul-claro {
  color: #5856d7; }

.Client-bg-azul {
  background: #007aff; }

.Client-bg-azul-claro {
  background: #5856d7; }

.Client-amarelo {
  color: #ffd200; }

.Client-amarelo-estrela {
  color: #ffc100; }

.Client-bg-amarelo {
  background: #ffd200; }

.Client-bg-amarelo-estrela {
  background: #ffc100; }

.Client-verde {
  color: #86a239; }

.Client-verde-claro {
  color: #4cd964; }

.Client-bg-verde {
  background: #86a239; }

.Client-bg-verde-claro {
  background: #4cd964; }

.analise-impacto-detalhe {
  font-family: "NewJune-Semibold";
  font-size: 16px;
  color: #3e4049; }

.btn-acao-criar {
  width: 80%; }

.text-area-analise-impacto {
  background: #ffffff;
  border: 1px solid #cdd8de;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "NewJune-Book";
  font-size: 0.7273rem;
  line-height: 1rem; }

.btns-acao-preparatoria {
  border-bottom: 1px solid #e3e3e3;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1px;
  font-family: "NewJune-Medium"; }

.acao-preparatoria {
  display: flex; }

button.btn-form-acao-prepatoria {
  flex-wrap: wrap;
  justify-content: center;
  width: 55%;
  padding: 20px;
  margin-left: -14px;
  margin-right: -14px;
  background-color: transparent;
  border: none; }

.linha {
  border-left: 1px solid #e3e3e3;
  height: 65px; }

.btn-acao-preparatoria-anexo {
  color: #222222;
  color: var(--clienteOver);
  width: 20%; }

.btn-acao-preparatoria-anexo-h6 {
  margin-right: 15px; }

.border-bottom-input {
  border-bottom: 1px solid #3e4049; }

input.acao-preparatoria-input {
  border: 0 none;
  outline: 0;
  box-shadow: none; }

.label-esforco-colaborativo {
  color: #222222;
  color: var(--clienteOver); }

.seprarar-acao-influencia {
  border-bottom: 1px solid #e3e3e3;
  margin-top: 15px;
  margin-bottom: 15px; }

.close {
  display: none; }

::-webkit-input-placeholder {
  color: #3e4049; }

.label-form-analise-impacto {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 0.75rem;
  position: absolute; }

#analise-posicionamento {
  margin-top: 25px; }

#analise-impacto-financeiro {
  margin-top: 5px; }

#esfcol_modal_resp {
  padding-top: 20px; }

#esfcol_modal_dep {
  padding-top: 20px; }

#esfcol_modal_pos {
  padding-top: 20px; }

#esfcol_modal_prio {
  padding-top: 20px; }

#esfcol_modal_tipimpf {
  padding-top: 8px;
  margin-top: 27px; }

#esfcol_modal_valimpf {
  margin-top: 22px; }

.label-form-filtro-esfcol {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 0.75rem; }

.label-form-filtro-esfcol-line3 {
  margin-top: 15px;
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 0.75rem;
  position: absolute; }

.label-modal-impacto-financeiro {
  padding-top: 15px;
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 0.75rem;
  position: absolute; }

.label-form-modal-esfcol {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 0.75rem;
  position: absolute; }

.label-form-analise-impacto-departamento {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 0.75rem;
  position: absolute; }

.Client-pointer {
  padding-left: 0px; }
  @media screen and (min-width: 320px) and (max-width: 767.98px) {
    .Client-pointer {
      padding-left: 0px; } }

.Client-span-criar-acoes-preparatoria {
  font-size: 1.4375rem;
  color: #5a19af;
  color: var(--clienteClaro);
  font-family: "NewJune-Book"; }

.label-form-analise-impacto-apelido {
  color: #3e4049;
  font-family: "NewJune-Medium";
  font-size: 0.75rem;
  margin-bottom: -12px; }

.tirar-borda-input-Client {
  border: none;
  outline: none; }

.border-bottom-visualizador {
  border-bottom: 1px solid #3e4049;
  width: 100%; }

@media screen and (max-width: 575.98px) {
  .Client-cinza-claro {
    font-size: 14px; }
  .Client-cinza-header {
    font-size: 16px;
    text-align: right;
    font-weight: bold; }
  .mobile-text-right {
    margin-left: 30px; }
  div.zerar-div {
    padding-left: 0px;
    padding-right: 0px; }
  #esfcol_modal_pos {
    padding-top: 20px;
    font-size: 12.5px; }
  #esfcol_modal_prio {
    padding-top: 20px;
    font-size: 12.5px; }
  #esfcol_modal_tipimpf {
    margin-top: 23px;
    font-size: 12.5px; }
  #esfcol_modal_valimpf {
    margin-top: 17px; } }

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .Client-cinza-claro {
    font-size: 14px;
    font-weight: bold; }
  .Client-cinza-header {
    font-size: 16px;
    text-align: right;
    font-weight: bold; }
  .mobile-text-right {
    margin-left: 30px; }
  div.zerar-div {
    padding-left: 0px;
    padding-right: 0px; } }

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  div.zerar-div {
    padding-left: 0px;
    padding-right: 0px; } }

/*acontecimentos*/
.label-data-acontecimento {
  font-size: 16px; }

#data-atual {
  border-bottom: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  outline-color: 0 none;
  box-shadow: none; }

#data-atual-editar {
  border-bottom: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  outline-color: 0 none;
  box-shadow: none; }

[contenteditable]:focus {
  outline: 0px solid transparent; }

.dashGraficoAlign {
  justify-content: flex-end; }

.dashGraficoAlignIndice {
  justify-content: left;
  padding-top: 50px; }

p {
  margin: 0; }

.col-1.column {
  text-align: right; }

.fontPendencia {
  font-size: 0.935rem; }

.widget {
  border-radius: 4px; }
  .widget .card-header {
    background: white;
    border: none !important; }
    .widget .card-header .title {
      font-family: Roboto;
      font-size: 20px;
      font-weight: bold;
      color: #172b4d;
      vertical-align: middle; }
      .widget .card-header .title .icon-info-small {
        font-size: 16px;
        margin-left: 5px; }
    .widget .card-header .icon-dots {
      color: #929aa2; }
  .widget .acao-do-lado-direito {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: #172b4d; }
    .widget .acao-do-lado-direito .dropdown-toggle {
      position: relative; }
  .widget .info {
    font-family: Roboto;
    margin: 5px 0; }
    .widget .info strong {
      font-size: 28px;
      color: #008bff !important;
      margin: 0 10px; }
    .widget .info span {
      font-size: 14px;
      font-weight: 500;
      color: #172b4d; }
  .widget ol {
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    color: #172b4d; }
  .widget .blue-title {
    font-size: 14px;
    font-weight: bold;
    color: #008bff; }
  .widget .priorities {
    display: flex;
    align-items: center; }
    .widget .priorities .label {
      width: 35px; }
  .widget .label {
    font-size: 14px;
    font-weight: bold;
    color: #D8D8D8; }
  .widget .positive-result,
  .widget .negative-result {
    font-size: 28px; }
  .widget .positive-result {
    color: #3ebe54; }
  .widget .negative-result {
    color: #f91552; }
  .widget .coin {
    font-size: 15px; }
  .widget .general-position {
    margin-bottom: 1em; }
    .widget .general-position img {
      width: 100px;
      height: 30px;
      margin-left: 0.3em; }
  .widget.parliamentarians .title {
    font-size: 20px;
    font-weight: bold;
    color: #172b4d; }
  .widget.parliamentarians .slick-next:focus:before,
  .widget.parliamentarians .slick-next:hover:before,
  .widget.parliamentarians .slick-prev:focus:before,
  .widget.parliamentarians .slick-prev:hover:before {
    color: #172b4d; }
  .widget.parliamentarians .slick-next:before,
  .widget.parliamentarians .slick-next:before,
  .widget.parliamentarians .slick-prev:before,
  .widget.parliamentarians .slick-prev:before {
    color: #7b869a; }
  .widget.parliamentarians .parliamentary {
    display: flex !important;
    align-items: center; }
    .widget.parliamentarians .parliamentary .image img {
      border-radius: 100px;
      height: 70px;
      width: 60px;
      margin-right: 2em; }
    .widget.parliamentarians .parliamentary .infos .title {
      text-transform: uppercase;
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .widget.parliamentarians .parliamentary .infos .location {
      font-size: 14px;
      color: #4c4c4c; }
    .widget.parliamentarians .parliamentary .infos .influences .influence {
      display: flex;
      align-items: center; }
      .widget.parliamentarians .parliamentary .infos .influences .influence i {
        width: 20px; }
      .widget.parliamentarians .parliamentary .infos .influences .influence .result {
        margin: 0;
        margin-left: 0.3em;
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }

.events {
  margin-left: 1em; }
  .events .title {
    font-size: 20px;
    font-weight: bold;
    color: #172b4d; }
  .events .event {
    margin: 1em 0; }
    .events .event .status {
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #929aa2; }
    .events .event .title {
      font-size: 14px; }

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none; }

.react-calendar {
  width: auto !important;
  border: none !important;
  padding: 1em;
  border-radius: 4px; }

.react-calendar__navigation__label {
  text-transform: uppercase;
  color: #172b4d;
  font-weight: bold;
  font-size: 20px; }

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border-radius: 100px; }

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  border-radius: 100px;
  background: #172b4d !important; }

.react-calendar__month-view__weekdays__weekday {
  font-size: 14px; }
  .react-calendar__month-view__weekdays__weekday abbr[title],
  .react-calendar__month-view__weekdays__weekday abbr[data-original-title] {
    font-weight: bold;
    color: #172b4d !important;
    text-decoration: none !important; }

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: none !important; }

@media (max-width: 1180px) {
  .widget.parliamentarians .parliamentary {
    display: inline-block !important; }
    .widget.parliamentarians .parliamentary .image img {
      margin: 0 2.7em; } }

.notificacoes-Client-dasboard {
  padding: 0.5em; }

.notificacoes-dashboard {
  margin-left: 67.1em;
  width: 90%; }

.card-grafico {
  background-color: white;
  border-radius: 5px; }

.dashboard-graficos-titulo {
  font-size: 23px;
  line-height: 41px;
  color: #3E4049; }

.acaoPreparatoriaDashboardProp {
  font-size: 23px;
  line-height: 41px;
  color: #3E4049; }

.dasboard-graficos-legenda {
  font-size: 16px;
  line-height: 22px;
  color: #192A3E; }

.dashboard-graficos-dados {
  font-size: 16px;
  line-height: 20px;
  color: #3E4049; }

.container-dash {
  display: flex;
  flex-wrap: wrap; }

@media (min-width: 768px) and (max-width: 1399px) {
  .dashboard-datas-filtro {
    margin-left: 20.9rem; } }

@media (min-width: 1400px) {
  .dashboard-datas-filtro {
    margin-left: 46rem; } }

#data-inicial {
  border-top: 0 none;
  border-right: 0 none;
  border-left: 0 none;
  background: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 100%; }

#data-final {
  border-top: 0 none;
  border-right: 0 none;
  border-left: 0 none;
  background: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 100%; }

img#pesquisa-dash-proposicao-inicial {
  width: 110px; }

img#limpar-dash-proposicao-inicial {
  width: 70px; }

@media screen and (max-width: 600px) {
  .acaoPreparatoriaDashboardProp {
    text-align: justify;
    font-size: 23px;
    line-height: 41px;
    color: #3E4049; }
  .dashGraficoAlign {
    justify-content: center; }
  .dashGraficoAlignIndice {
    justify-content: left;
    margin-left: 13px;
    padding-bottom: 10px; }
  .marginItensPrioridadeDashboardMobile {
    margin-left: 15px; }
  .fontPendencia {
    font-size: 13px; }
  .dashboard-inicial {
    margin-top: 10px; }
  .mobile-top-dash {
    margin-top: 10px; } }

.data-dashboard-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
  line-height: 18px; }

.dashboad-filtro-listagem {
  font-size: 23px; }

.dashboard-font-filtro-listagem {
  font-size: 11px; }

@media (min-width: 576px) and (max-width: 767.98px) {
  .bigDisplayNone {
    display: none; } }

@media (max-width: 576px) {
  .marginTopMobileDashboard {
    margin-top: 50px; }
  .bigDisplayNone {
    display: none; } }

@media (min-width: 767.98px) {
  .smallDisplayNone {
    display: none; } }

/* MEDIA QUERY */
/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .dashboard-titulo {
    margin-top: 0px;
    font-size: 23px; } }

/*/ Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .dashboard-titulo {
    margin-top: 30px;
    font-size: 23px; }
  .alinhamentoGraficoQualificacaoParlamentar {
    margin-left: 10px; }
  .alinhamentoGraficoMomentoCritico {
    margin-left: 10px; } }

/*/ Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-titulo {
    margin-top: 30px;
    font-size: 23px; }
  .alinhamentoGraficoQualificacaoParlamentar {
    margin-left: 10px; }
  .alinhamentoGraficoMomentoCritico {
    margin-left: 10px; } }

/*/ Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dashboard-titulo {
    margin-top: 30px;
    font-size: 23px; }
  .alinhamentoGraficoQualificacaoParlamentar {
    margin-left: 10px; }
  .alinhamentoGraficoMomentoCritico {
    margin-left: 10px; } }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .dashboard-titulo {
    margin-top: 30px;
    font-size: 23px; }
  .alinhamentoGraficoQualificacaoParlamentar {
    margin-left: 10px; }
  .alinhamentoGraficoMomentoCritico {
    margin-left: 10px; } }

.grafico-card-proposicao {
  background-color: #FFFFFF; }

.titulo-grafico-universo {
  font-size: 23px;
  line-height: 41px;
  color: #3E4049; }

.container-grafico {
  display: flex;
  flex-wrap: wrap; }

.fechar-modal-grafico-universo {
  margin-left: 170px; }

.titulo-universo {
  color: #4b1196;
  color: var(--linkText); }

.graficos-ativo-inativo {
  border: 1px solid black;
  width: 100%; }

.link-graficos-legenda-proposicao {
  cursor: pointer;
  color: #4b1196;
  color: var(--linkText);
  border-bottom: 1px solid #4b1196;
  border-bottom: 1px solid var(--linkText); }

.dasboard-graficos-legenda-proposicao {
  cursor: pointer; }

@media screen and (max-width: 600px) {
  .titulo-grafico-universo {
    font-family: NewJuneSemibold;
    font-size: 16px; }
  .fechar-modal-grafico-universo {
    margin-left: 60px; }
  .mobile-momento-critico-proposicao {
    margin-bottom: 15px; } }

.data-dashboard-label-proposicao {
  font-style: normal;
  font-weight: normal;
  font-size: 12px; }

/* MEDIA QUERY */
/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .hiddenMobileModel {
    visibility: hidden;
    position: absolute;
    display: none; }
  .hiddenDeskModel {
    visibility: visible;
    position: absolute; }
  .marginBuscarProInput {
    margin-top: 31px; }
  .marginBotaoCVadastrarProp {
    margin-top: -3.7px; }
  .espacamentoBottom {
    margin-bottom: 20px; }
  .espacamentoData {
    margin-bottom: 15px; }
  .centerPesquisar {
    align-content: center;
    margin-bottom: 10px; }
  .espacamentoDataInt {
    margin-bottom: 10px; } }

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .hiddenMobileModel {
    visibility: hidden;
    position: absolute; }
  .marginBuscarProInput {
    margin-top: 31px; }
  .marginBotaoCVadastrarProp {
    margin-top: -3.7px; }
  .espacamentoBottom {
    margin-bottom: 20px; }
  .espacamentoData {
    margin-bottom: 15px; }
  .centerPesquisar {
    align-content: center;
    margin-bottom: 10px; }
  .espacamentoDataInt {
    margin-bottom: 10px; } }

/*/ Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .hiddenMobileModel {
    visibility: visible;
    position: absolute; }
  .hiddenDeskModel {
    visibility: hidden;
    position: absolute; }
  .marginBuscarProInput {
    margin-top: 31px; }
  .marginBotaoCVadastrarProp {
    margin-top: -3.7px; }
  .espacamentoBottom {
    margin-bottom: 20px; } }

/*/ Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hiddenMobileModel {
    visibility: visible;
    position: absolute; }
  .hiddenDeskModel {
    visibility: hidden;
    position: absolute; }
  .marginBuscarProInput {
    margin-top: 31px; }
  .marginBotaoCVadastrarProp {
    margin-top: -3.7px; }
  .espacamentoBottom {
    margin-bottom: 20px; } }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .hiddenDeskModel {
    visibility: hidden;
    position: absolute;
    display: none; }
  .marginBuscarProInput {
    margin-top: 31px; }
  .marginBotaoCVadastrarProp {
    margin-top: -3.7px; } }

.ehHoje {
  letter-spacing: normal;
  background: red;
  width: 29px;
  border-radius: 30px;
  color: #fff !important;
  text-align: center;
  height: 29px;
  line-height: 1.4;
  font-size: 20px !important; }

.agenda .table {
  border-top: none; }

@media only screen and (min-width: 700px) {
  .agenda tr.dia {
    min-height: 181px; } }

.agenda:first-child {
  border-right: 2px dashed #cdd8de; }

.table h3 {
  margin: 0 auto; }

.intervalo {
  padding: 0.55rem 1rem;
  border-radius: 2px;
  color: #172b4d;
  vertical-align: top;
  min-width: 114px;
  text-align: center;
  text-transform: uppercase; }

@media print {
  body {
    margin: 0;
    padding: 0;
    background: none !important; }
  .content {
    background: none !important; } }

.agenda-print {
  font-family: Roboto; }
  .agenda-print header h1 {
    font-size: 1.8rem; }
  .agenda-print header h2 {
    font-size: 1.4rem;
    color: #686D73 !important;
    text-transform: uppercase; }
  .agenda-print hr {
    border-color: #D9D9E6; }
  .agenda-print h3 {
    text-transform: uppercase; }
  .agenda-print .neutro {
    color: #008BFF;
    font-weight: bold; }
  .agenda-print .contrario {
    color: #E55B56;
    font-weight: bold; }
  .agenda-print .favoravel {
    color: #01CE8C;
    font-weight: bold; }
  .agenda-print .data .numero {
    font-size: 1.8rem;
    border-radius: 150px;
    padding: 10px;
    background-color: #172b4d;
    color: white; }
  .agenda-print .data .titulo {
    font-size: 1.6rem;
    font-weight: 600;
    color: #172b4d !important;
    text-transform: uppercase; }
  .agenda-print .evento .horario {
    font-size: 1.6rem;
    font-weight: bold;
    color: #404346; }
  .agenda-print .evento .titulo {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #404346; }
    .agenda-print .evento .titulo .azul {
      color: #172B4D; }
  .agenda-print .evento .subtitulo {
    color: #686D73;
    font-size: 1.2rem; }
  .agenda-print .evento .local {
    font-size: 1.2rem;
    font-weight: bold;
    color: #404346; }
    .agenda-print .evento .local span {
      font-weight: normal; }
  .agenda-print .evento .item {
    font-size: 1.2rem;
    color: #686D73; }
    .agenda-print .evento .item strong {
      color: #172B4D;
      font-weight: normal; }
  .agenda-print .obs {
    border: 1px solid #E1E8F2;
    height: 130px; }
    .agenda-print .obs .titulo {
      background-color: #E1E8F2;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      color: #172B4D; }

.help {
  position: relative;
  z-index: 1000;
  margin-left: 10px; }
  .help .help-icone {
    font-size: 14px;
    border-radius: 150px;
    padding: 5px 10px;
    background-color: #172b4d;
    color: white; }
  .help .help-tooltip {
    background: white;
    border-radius: 5px;
    border: 1px solid #333;
    padding: 2px 5px;
    display: none;
    position: absolute; }

.help:hover .help-tooltip {
  display: block;
  margin-top: 10px; }

.datetime-agenda input[type="date"] {
  border-bottom: 1px solid #bbb !important; }

.datetime-agenda input[type="date"]::-webkit-calendar-picker-indicator {
  color: #ccc; }

.change-button-week {
  width: 55px;
  background: #fff;
  color: #4b1196;
  color: var(--cliente);
  padding: 10px;
  height: 55px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: pointer; }
  .change-button-week:hover {
    background: #4b1196;
    background: var(--cliente);
    color: #fff; }

.change-button-week-inactive {
  background: #d8d8d8;
  color: #9b9b9b; }

.change-button-week-mobile {
  width: 40px;
  background: #fff;
  color: #4b1196;
  color: var(--cliente);
  padding: 10px;
  height: 40px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: pointer; }
  .change-button-week-mobile:hover {
    background: #4b1196;
    background: var(--cliente);
    color: #fff; }

.change-button-week-mobile-inactive {
  background: #d8d8d8;
  color: #9b9b9b; }

.text-week {
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 13px;
  font-weight: bold; }

.titulo-evento {
  margin-bottom: 10px; }

.data-eventos {
  font-size: 25px;
  line-height: 22px;
  margin-bottom: 30px;
  font-weight: lighter; }

.semana-eventos {
  font-size: 28px;
  line-height: 22px;
  color: #4b1196;
  color: var(--cliente); }

.titulo-comissao {
  font-size: 25px;
  font-weight: bold; }

.titulo-data {
  font-size: 25px;
  font-weight: lighter;
  margin-bottom: 40px; }

.item-evento {
  color: #4b1196;
  color: var(--cliente);
  font-size: 22px;
  font-weight: bold; }

.item-descricao {
  font-size: 22px;
  font-weight: bold; }

.item-descricao2 {
  font-size: 18px;
  font-weight: bold; }

.texto-evento {
  font-size: 20px;
  text-align: justify;
  display: block; }

.aviso-departamento {
  font-size: 18px;
  text-align: justify;
  margin-top: 10px; }

.espaco-texto {
  margin-bottom: 30px; }

.espaco-texto2 {
  margin-bottom: 50px; }

.data-numero {
  color: white;
  border: 1px solid black;
  border-radius: 150px;
  background-color: #3e4049;
  padding: 5px; }

.data-comissao {
  color: #4b1196;
  color: var(--cliente);
  font-size: 22px;
  font-weight: bold; }

.seta::after {
  content: "\BB"; }

@media screen and (max-width: 575.98px) {
  .titulo-items {
    font-size: 15px;
    font-weight: bold; }
  .marginTopXs {
    margin-top: 50px; }
  .agenda {
    margin-left: 10px;
    margin-right: 10px; }
    .agenda .bg-linear {
      background: #6742fa;
      background: -webkit-gradient(left top, right top, color-stop(0%, #6742fa), color-stop(100%, #37a1f6));
      background: linear-gradient(to right, #6742fa 0%, #37a1f6 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6742fa', endColorstr='#37a1f6', GradientType=1 ); }
    .agenda .events {
      margin: 0;
      display: grid;
      grid-template-rows: auto;
      grid-row-gap: 1em;
      grid-column-gap: 1em;
      grid-template-columns: auto;
      width: 100%; }
      .agenda .events .event-card {
        padding: 1em;
        border-radius: 4px;
        color: white;
        font-size: 0.75rem;
        height: 15em;
        overflow-y: scroll; }
        .agenda .events .event-card .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1em; }
          .agenda .events .event-card .header .hour {
            font-size: 1.5rem; }
        .agenda .events .event-card .orgaos,
        .agenda .events .event-card .infos {
          margin-top: 1em; }
    .agenda .schedule .header {
      margin-top: 50px;
      align-items: center;
      font-size: 1rem; }
      .agenda .schedule .header .week {
        padding: 1em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        width: 100%;
        position: relative; }
        .agenda .schedule .header .week .calendar {
          position: absolute;
          top: 0em; }
          .agenda .schedule .header .week .calendar .react-calendar {
            background: #f1f1f1; }
        .agenda .schedule .header .week .angle-button {
          width: 30px;
          background: #eef1f6;
          padding: 6px 15px;
          height: 30px;
          border-radius: 4px;
          cursor: pointer; }
          .agenda .schedule .header .week .angle-button:hover {
            background: #c6c9cf; }
        .agenda .schedule .header .week .schedule-button {
          cursor: pointer;
          font-size: 1rem; }
          .agenda .schedule .header .week .schedule-button:hover {
            background: #d2d5db; }
      .agenda .schedule .header .options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22em; }
        .agenda .schedule .header .options .select-button {
          background: white;
          color: #172b4d;
          border: 1px solid #172b4d;
          border-radius: 100px;
          padding: 0.1em 0.5em;
          width: 7em;
          cursor: pointer;
          text-align: center;
          font-weight: bold;
          transition: 0.3s; }
          .agenda .schedule .header .options .select-button.active {
            background: #172b4d;
            color: white; }
          .agenda .schedule .header .options .select-button:hover {
            background: #172b4d8c;
            color: white;
            transition: 0.3s; }
    .agenda .schedule .days .days-header {
      padding: 0.5em;
      width: 100%;
      background: #3e4049;
      color: white;
      border-radius: 4px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .agenda .schedule .days .day {
      display: flex;
      margin: 1em 0;
      border-bottom: 1px solid #d9d9e6;
      padding-bottom: 1em; }
      .agenda .schedule .days .day:last-child {
        border-bottom: none; }
      .agenda .schedule .days .day .calendar-days {
        width: 6em;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #172b4d; }
        .agenda .schedule .days .day .calendar-days p.number.active {
          border-radius: 100px;
          width: 30px;
          padding: 0.1em;
          text-align: center;
          color: white;
          background: #4b1196;
          background: var(--cliente); }
        .agenda .schedule .days .day .calendar-days p.day-of-week.active {
          color: #4b1196;
          color: var(--cliente); }
    .agenda .box-agenda {
      width: 100%;
      height: 100%;
      padding: 20px;
      border-radius: 5px; }
    .agenda .box-agenda-acontecimento {
      width: 320px;
      height: 70%;
      padding-left: 20px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 110px;
      border-radius: 5px; }
    .agenda .visualizado {
      color: #9b9b9b;
      background-color: #f6f6f9; }
    .agenda .nao-visualizado {
      border: 1px solid #9b9b9b; }
    .agenda .textH5 {
      color: #9b9b9b;
      background-color: #f6f6f9; }
  .text-button {
    font-size: 0.9rem;
    display: block; }
  .departamento-titulo {
    color: #4b1196;
    color: var(--cliente);
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: bold; }
  .titulo-evento {
    margin-bottom: 10px;
    font-size: 1.4rem; }
  .data-eventos {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
    font-weight: lighter; }
  .semana-eventos {
    font-size: 20px;
    line-height: 22px;
    color: #4b1196;
    color: var(--cliente); }
  .titulo-card {
    color: #4b1196;
    color: var(--cliente);
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 20px; }
  .titulo-comissao {
    font-size: 20px;
    font-weight: bold; }
  .titulo-data {
    font-size: 20px;
    font-weight: lighter;
    margin-bottom: 40px; }
  .item-evento {
    color: #4b1196;
    color: var(--cliente);
    font-size: 18px;
    font-weight: bold; }
  .item-descricao {
    font-size: 22px;
    font-weight: bold; }
  .item-descricao2 {
    font-size: 18px;
    font-weight: bold; }
  .texto-evento {
    font-size: 18px;
    text-align: justify;
    display: block; }
  .aviso-departamento {
    font-size: 18px;
    text-align: justify;
    margin-top: 10px; }
  .div-logo-Client {
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .space-header-jornal {
    margin-top: 50px; } }

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .titulo-items {
    font-size: 18px;
    font-weight: bold; }
  .marginTopXs {
    margin-top: 50px; }
  .agenda .bg-linear {
    background: #6742fa;
    background: -webkit-gradient(left top, right top, color-stop(0%, #6742fa), color-stop(100%, #37a1f6));
    background: linear-gradient(to right, #6742fa 0%, #37a1f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6742fa', endColorstr='#37a1f6', GradientType=1 ); }
  .agenda .events {
    margin: 0;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 1em;
    grid-column-gap: 1em;
    grid-template-columns: auto; }
    .agenda .events .event-card {
      padding: 1em;
      border-radius: 4px;
      color: white;
      font-size: 0.75rem;
      width: 25em;
      height: 15em;
      overflow-y: scroll; }
      .agenda .events .event-card .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em; }
        .agenda .events .event-card .header .hour {
          font-size: 1.5rem; }
      .agenda .events .event-card .orgaos,
      .agenda .events .event-card .infos {
        margin-top: 1em; }
  .agenda .schedule {
    padding: 20px; }
    .agenda .schedule .header {
      display: flex;
      align-items: center;
      font-size: 1rem; }
      .agenda .schedule .header .week {
        padding: 1em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        width: 20em;
        position: relative; }
        .agenda .schedule .header .week .calendar {
          position: absolute;
          top: 0em; }
          .agenda .schedule .header .week .calendar .react-calendar {
            background: #f1f1f1; }
        .agenda .schedule .header .week .angle-button {
          width: 30px;
          background: #eef1f6;
          padding: 6px 15px;
          height: 30px;
          border-radius: 4px;
          cursor: pointer; }
          .agenda .schedule .header .week .angle-button:hover {
            background: #c6c9cf; }
        .agenda .schedule .header .week .schedule-button {
          cursor: pointer;
          font-size: 1rem; }
          .agenda .schedule .header .week .schedule-button:hover {
            background: #d2d5db; }
      .agenda .schedule .header .options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22em; }
        .agenda .schedule .header .options .select-button {
          background: white;
          color: #172b4d;
          border: 1px solid #172b4d;
          border-radius: 100px;
          padding: 0.1em 0.5em;
          width: 7em;
          cursor: pointer;
          text-align: center;
          font-weight: bold;
          transition: 0.3s; }
          .agenda .schedule .header .options .select-button.active {
            background: #172b4d;
            color: white; }
          .agenda .schedule .header .options .select-button:hover {
            background: #172b4d8c;
            color: white;
            transition: 0.3s; }
    .agenda .schedule .days .days-header {
      padding: 0.5em;
      width: 100%;
      background: #3e4049;
      color: white;
      border-radius: 4px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .agenda .schedule .days .day {
      display: flex;
      margin: 1em 0;
      border-bottom: 1px solid #d9d9e6;
      padding-bottom: 1em; }
      .agenda .schedule .days .day:last-child {
        border-bottom: none; }
      .agenda .schedule .days .day .calendar-days {
        width: 6em;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #172b4d; }
        .agenda .schedule .days .day .calendar-days p.number.active {
          border-radius: 100px;
          width: 30px;
          padding: 0.1em;
          text-align: center;
          color: white;
          background: #4b1196;
          background: var(--cliente); }
        .agenda .schedule .days .day .calendar-days p.day-of-week.active {
          color: #4b1196;
          color: var(--cliente); }
  .agenda .box-agenda {
    width: 300px;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    border-radius: 5px; }
  .agenda .box-agenda-acontecimento {
    width: 320px;
    height: 70%;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 110px;
    border-radius: 5px; }
  .agenda .visualizado {
    color: #9b9b9b;
    background-color: #f6f6f9; }
  .agenda .nao-visualizado {
    border: 1px solid #9b9b9b; }
  .agenda .textH5 {
    color: #9b9b9b;
    background-color: #f6f6f9; }
  .text-button {
    font-size: 0.9rem; }
  .departamento-titulo {
    color: #4b1196;
    color: var(--cliente);
    font-size: 22px;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: bold; }
  .titulo-evento {
    margin-bottom: 10px;
    font-size: 1.6rem; }
  .data-eventos {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 30px;
    font-weight: lighter; }
  .semana-eventos {
    font-size: 22px;
    line-height: 22px;
    color: #4b1196;
    color: var(--cliente); }
  .titulo-card {
    color: #4b1196;
    color: var(--cliente);
    font-size: 24px;
    margin-top: -10px;
    margin-bottom: 20px; }
  .titulo-comissao {
    font-size: 22px;
    font-weight: bold; }
  .titulo-data {
    font-size: 22px;
    font-weight: lighter;
    margin-bottom: 40px; }
  .item-evento {
    color: #4b1196;
    color: var(--cliente);
    font-size: 20px;
    font-weight: bold; }
  .item-descricao {
    font-size: 22px;
    font-weight: bold; }
  .item-descricao2 {
    font-size: 18px;
    font-weight: bold; }
  .texto-evento {
    font-size: 18px;
    text-align: justify;
    display: block; }
  .aviso-departamento {
    font-size: 18px;
    text-align: justify;
    margin-top: 10px; }
  .div-logo-Client {
    margin-left: auto;
    margin-right: auto; }
  .space-header-jornal {
    margin-top: 50px; } }

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .titulo-items {
    font-size: 18px;
    font-weight: bold; }
  .agenda .bg-linear {
    background: #6742fa;
    background: -webkit-gradient(left top, right top, color-stop(0%, #6742fa), color-stop(100%, #37a1f6));
    background: linear-gradient(to right, #6742fa 0%, #37a1f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6742fa', endColorstr='#37a1f6', GradientType=1 ); }
  .agenda .events {
    margin: 0;
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 1em;
    grid-column-gap: 1em;
    grid-template-columns: auto auto; }
    .agenda .events .event-card {
      padding: 1em;
      border-radius: 4px;
      color: white;
      font-size: 0.75rem;
      width: 25em;
      height: 15em;
      overflow-y: scroll; }
      .agenda .events .event-card .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em; }
        .agenda .events .event-card .header .hour {
          font-size: 1.5rem; }
      .agenda .events .event-card .orgaos,
      .agenda .events .event-card .infos {
        margin-top: 1em; }
  .agenda .schedule {
    padding: 20px; }
    .agenda .schedule .header {
      display: flex;
      align-items: center;
      font-size: 1rem; }
      .agenda .schedule .header .week {
        padding: 1em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        width: 20em;
        position: relative; }
        .agenda .schedule .header .week .calendar {
          position: absolute;
          top: 0em; }
          .agenda .schedule .header .week .calendar .react-calendar {
            background: #f1f1f1; }
        .agenda .schedule .header .week .angle-button {
          width: 30px;
          background: #eef1f6;
          padding: 6px 15px;
          height: 30px;
          border-radius: 4px;
          cursor: pointer; }
          .agenda .schedule .header .week .angle-button:hover {
            background: #c6c9cf; }
        .agenda .schedule .header .week .schedule-button {
          cursor: pointer;
          font-size: 1rem; }
          .agenda .schedule .header .week .schedule-button:hover {
            background: #d2d5db; }
      .agenda .schedule .header .options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22em; }
        .agenda .schedule .header .options .select-button {
          background: white;
          color: #172b4d;
          border: 1px solid #172b4d;
          border-radius: 100px;
          padding: 0.1em 0.5em;
          width: 7em;
          cursor: pointer;
          text-align: center;
          font-weight: bold;
          transition: 0.3s; }
          .agenda .schedule .header .options .select-button.active {
            background: #172b4d;
            color: white; }
          .agenda .schedule .header .options .select-button:hover {
            background: #172b4d8c;
            color: white;
            transition: 0.3s; }
    .agenda .schedule .days .days-header {
      padding: 0.5em;
      width: 100%;
      background: #3e4049;
      color: white;
      border-radius: 4px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .agenda .schedule .days .day {
      display: flex;
      margin: 1em 0;
      border-bottom: 1px solid #d9d9e6;
      padding-bottom: 1em; }
      .agenda .schedule .days .day:last-child {
        border-bottom: none; }
      .agenda .schedule .days .day .calendar-days {
        width: 6em;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #172b4d; }
        .agenda .schedule .days .day .calendar-days p.number.active {
          border-radius: 100px;
          width: 30px;
          padding: 0.1em;
          text-align: center;
          color: white;
          background: #4b1196;
          background: var(--cliente); }
        .agenda .schedule .days .day .calendar-days p.day-of-week.active {
          color: #4b1196;
          color: var(--cliente); }
  .agenda .box-agenda {
    width: 300px;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    border-radius: 5px; }
  .agenda .box-agenda-acontecimento {
    width: 320px;
    height: 70%;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 110px;
    border-radius: 5px; }
  .agenda .visualizado {
    color: #9b9b9b;
    background-color: #f6f6f9; }
  .agenda .nao-visualizado {
    border: 1px solid #9b9b9b; }
  .agenda .textH5 {
    color: #9b9b9b;
    background-color: #f6f6f9; }
  .text-button {
    font-size: 1rem; }
  .departamento-titulo {
    color: #4b1196;
    color: var(--cliente);
    font-size: 25px;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: bold; }
  .titulo-evento {
    margin-bottom: 10px;
    font-size: 2rem; }
  .data-eventos {
    font-size: 25px;
    line-height: 22px;
    margin-bottom: 30px;
    font-weight: lighter; }
  .semana-eventos {
    font-size: 28px;
    line-height: 22px;
    color: #4b1196;
    color: var(--cliente); }
  .titulo-card {
    color: #4b1196;
    color: var(--cliente);
    font-size: 26px;
    margin-top: -10px;
    margin-bottom: 20px; }
  .titulo-comissao {
    font-size: 23px;
    font-weight: bold; }
  .titulo-data {
    font-size: 20px;
    font-weight: lighter;
    margin-bottom: 40px; }
  .item-evento {
    color: #4b1196;
    color: var(--cliente);
    font-size: 20px;
    font-weight: bold; }
  .item-descricao {
    font-size: 22px;
    font-weight: bold; }
  .item-descricao2 {
    font-size: 18px;
    font-weight: bold; }
  .texto-evento {
    font-size: 16px;
    text-align: justify;
    display: block; }
  .aviso-departamento {
    font-size: 18px;
    text-align: justify;
    margin-top: 10px; }
  .div-logo-Client {
    margin-left: auto;
    margin-right: auto; } }

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .titulo-items {
    font-size: 18px;
    font-weight: bold; }
  .agenda .bg-linear {
    background: #6742fa;
    background: -webkit-gradient(left top, right top, color-stop(0%, #6742fa), color-stop(100%, #37a1f6));
    background: linear-gradient(to right, #6742fa 0%, #37a1f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6742fa', endColorstr='#37a1f6', GradientType=1 ); }
  .agenda .events {
    margin: 0;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-row-gap: 1em;
    grid-column-gap: 1em;
    grid-template-columns: auto auto auto; }
    .agenda .events .event-card {
      padding: 1em;
      border-radius: 4px;
      color: white;
      font-size: 0.75rem;
      width: 25em;
      height: 15em;
      overflow-y: scroll; }
      .agenda .events .event-card .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em; }
        .agenda .events .event-card .header .hour {
          font-size: 1.5rem; }
      .agenda .events .event-card .orgaos,
      .agenda .events .event-card .infos {
        margin-top: 1em; }
  .agenda .schedule {
    padding: 20px; }
    .agenda .schedule .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem; }
      .agenda .schedule .header .week {
        padding: 1em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        width: 20em;
        position: relative; }
        .agenda .schedule .header .week .calendar {
          position: absolute;
          top: 0em; }
          .agenda .schedule .header .week .calendar .react-calendar {
            background: #f1f1f1; }
        .agenda .schedule .header .week .angle-button {
          width: 30px;
          background: #eef1f6;
          padding: 6px 15px;
          height: 30px;
          border-radius: 4px;
          cursor: pointer; }
          .agenda .schedule .header .week .angle-button:hover {
            background: #c6c9cf; }
        .agenda .schedule .header .week .schedule-button {
          cursor: pointer;
          font-size: 0.9rem; }
          .agenda .schedule .header .week .schedule-button:hover {
            background: #d2d5db; }
      .agenda .schedule .header .options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22em; }
        .agenda .schedule .header .options .select-button {
          background: white;
          color: #172b4d;
          border: 1px solid #172b4d;
          border-radius: 100px;
          padding: 0.1em 0.5em;
          width: 7em;
          cursor: pointer;
          text-align: center;
          font-weight: bold;
          transition: 0.3s; }
          .agenda .schedule .header .options .select-button.active {
            background: #172b4d;
            color: white; }
          .agenda .schedule .header .options .select-button:hover {
            background: #172b4d8c;
            color: white;
            transition: 0.3s; }
    .agenda .schedule .days .days-header {
      padding: 0.5em;
      width: 100%;
      background: #3e4049;
      color: white;
      border-radius: 4px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .agenda .schedule .days .day {
      display: flex;
      margin: 1em 0;
      border-bottom: 1px solid #d9d9e6;
      padding-bottom: 1em; }
      .agenda .schedule .days .day:last-child {
        border-bottom: none; }
      .agenda .schedule .days .day .calendar-days {
        width: 6em;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #172b4d; }
        .agenda .schedule .days .day .calendar-days p.number.active {
          border-radius: 100px;
          width: 30px;
          padding: 0.1em;
          text-align: center;
          color: white;
          background: #4b1196;
          background: var(--cliente); }
        .agenda .schedule .days .day .calendar-days p.day-of-week.active {
          color: #4b1196;
          color: var(--cliente); }
  .agenda .box-agenda {
    width: 280px;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    border-radius: 5px; }
  .agenda .box-agenda-acontecimento {
    width: 320px;
    height: 70%;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 110px;
    border-radius: 5px; }
  .agenda .visualizado {
    color: #9b9b9b;
    background-color: #f6f6f9; }
  .agenda .nao-visualizado {
    border: 1px solid #9b9b9b; }
  .agenda .textH5 {
    color: #9b9b9b;
    background-color: #f6f6f9; }
  .text-button {
    font-size: 0.8rem; }
  .departamento-titulo {
    color: #4b1196;
    color: var(--cliente);
    font-size: 25px;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: bold; }
  .titulo-evento {
    margin-bottom: 10px; }
  .data-eventos {
    font-size: 25px;
    line-height: 22px;
    margin-bottom: 30px;
    font-weight: lighter; }
  .semana-eventos {
    font-size: 28px;
    line-height: 22px;
    color: #4b1196;
    color: var(--cliente); }
  .titulo-card {
    color: #4b1196;
    color: var(--cliente);
    font-size: 28px;
    margin-top: -10px;
    margin-bottom: 20px; }
  .titulo-comissao {
    font-size: 25px;
    font-weight: bold; }
  .titulo-data {
    font-size: 25px;
    font-weight: lighter;
    margin-bottom: 40px; }
  .item-evento {
    color: #4b1196;
    color: var(--cliente);
    font-size: 22px;
    font-weight: bold; }
  .item-descricao {
    font-size: 22px;
    font-weight: bold; }
  .item-descricao2 {
    font-size: 18px;
    font-weight: bold; }
  .texto-evento {
    font-size: 20px;
    text-align: justify;
    display: block; }
  .aviso-departamento {
    font-size: 18px;
    text-align: justify;
    margin-top: 10px; }
  .div-logo-Client {
    margin-left: auto;
    margin-right: auto; } }

@media screen and (min-width: 1200px) {
  .titulo-items {
    font-size: 18px;
    font-weight: bold; }
  .agenda .bg-linear {
    background: #6742fa;
    background: -webkit-gradient(left top, right top, color-stop(0%, #6742fa), color-stop(100%, #37a1f6));
    background: linear-gradient(to right, #6742fa 0%, #37a1f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6742fa', endColorstr='#37a1f6', GradientType=1 ); }
  .agenda .events {
    margin: 0;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-row-gap: 1em;
    grid-column-gap: 1em;
    grid-template-columns: auto auto auto; }
    .agenda .events .event-card {
      padding: 1em;
      border-radius: 4px;
      color: white;
      font-size: 0.75rem;
      width: 25em;
      height: 15em;
      overflow-y: scroll; }
      .agenda .events .event-card .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em; }
        .agenda .events .event-card .header .hour {
          font-size: 1.5rem; }
      .agenda .events .event-card .orgaos,
      .agenda .events .event-card .infos {
        margin-top: 1em; }
  .agenda .schedule {
    padding: 20px; }
    .agenda .schedule .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.1rem; }
      .agenda .schedule .header .week {
        padding: 1em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        width: 20em;
        position: relative; }
        .agenda .schedule .header .week .calendar {
          position: absolute;
          top: 0em; }
          .agenda .schedule .header .week .calendar .react-calendar {
            background: #f1f1f1; }
        .agenda .schedule .header .week .angle-button {
          width: 40px;
          background: #eef1f6;
          padding: 6px 15px;
          height: 35px;
          border-radius: 4px;
          cursor: pointer; }
          .agenda .schedule .header .week .angle-button:hover {
            background: #c6c9cf; }
        .agenda .schedule .header .week .schedule-button {
          cursor: pointer; }
          .agenda .schedule .header .week .schedule-button:hover {
            background: #d2d5db; }
      .agenda .schedule .header .options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22em; }
        .agenda .schedule .header .options .select-button {
          background: white;
          color: #172b4d;
          border: 1px solid #172b4d;
          border-radius: 100px;
          padding: 0.1em 0.5em;
          width: 7em;
          cursor: pointer;
          text-align: center;
          font-weight: bold;
          transition: 0.3s; }
          .agenda .schedule .header .options .select-button.active {
            background: #172b4d;
            color: white; }
          .agenda .schedule .header .options .select-button:hover {
            background: #172b4d8c;
            color: white;
            transition: 0.3s; }
    .agenda .schedule .days .days-header {
      padding: 0.5em;
      width: 100%;
      background: #3e4049;
      color: white;
      border-radius: 4px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .agenda .schedule .days .day {
      display: flex;
      margin: 1em 0;
      border-bottom: 1px solid #d9d9e6;
      padding-bottom: 1em; }
      .agenda .schedule .days .day:last-child {
        border-bottom: none; }
      .agenda .schedule .days .day .calendar-days {
        width: 6em;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #172b4d; }
        .agenda .schedule .days .day .calendar-days p.number.active {
          border-radius: 100px;
          width: 30px;
          padding: 0.1em;
          text-align: center;
          color: white;
          background: #4b1196;
          background: var(--cliente); }
        .agenda .schedule .days .day .calendar-days p.day-of-week.active {
          color: #4b1196;
          color: var(--cliente); }
  .agenda .box-agenda {
    width: 320px;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    border-radius: 5px; }
  .agenda .box-agenda-acontecimento {
    width: 320px;
    height: 70%;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 110px;
    border-radius: 5px; }
  .agenda .visualizado {
    color: #9b9b9b;
    background-color: #f6f6f9; }
  .agenda .nao-visualizado {
    border: 1px solid #9b9b9b; }
  .agenda .textH5 {
    color: #9b9b9b;
    background-color: #f6f6f9; }
  .departamento-titulo {
    color: #4b1196;
    color: var(--cliente);
    font-size: 25px;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: bold; }
  .titulo-card {
    color: #4b1196;
    color: var(--cliente);
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 50px; } }

@media print {
  #non-printable {
    display: block; }
  #printable {
    display: contents !important; }
  .data-numero {
    background-color: #3e4049 !important;
    -webkit-print-color-adjust: exact; }
  @page {
    -moz-text-size-adjust: auto;
    page-break-inside: initial; }
  .myDivToPrint {
    line-break: initial;
    background-color: white;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 30px;
    line-height: 40px; } }

.logoAgendaJornal {
  content: url(/static/media/logo-relatorios-purpleClient.1928d775.svg);
  content: var(--logoAgendaJornal);
  width: 70px; }

.arrow-button {
  padding: 1em;
  border-radius: 4px;
  width: 46px;
  height: 47px;
  background-color: #eef1f6; }

.notificacoes .notifications-container {
  border-radius: 4px; }
  .notificacoes .notifications-container .left,
  .notificacoes .notifications-container .right,
  .notificacoes .notifications-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .notificacoes .notifications-container .header {
    border-bottom: 3px solid #f3f5f7;
    padding-bottom: 1em; }
    .notificacoes .notifications-container .header .left {
      width: 26%; }
  .notificacoes .notifications-container .notification.fs-13 {
    display: block !important; }
  .notificacoes .notifications-container .notification {
    cursor: pointer;
    margin: 1em 0;
    border-radius: 4px;
    border: solid 1px #cdd8de;
    padding: 1em;
    background-color: #fff; }
    .notificacoes .notifications-container .notification .header-notification {
      display: flex;
      justify-content: flex-end; }
    .notificacoes .notifications-container .notification .footer-notification-mobile {
      margin-top: -20px;
      margin-bottom: -15px;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .notificacoes .notifications-container .notification .footer-notification-mobile .footer-item {
        margin-left: 1em; }
    .notificacoes .notifications-container .notification .footer-notification {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .notificacoes .notifications-container .notification .footer-notification .footer-item {
        margin-left: 1em; }
    .notificacoes .notifications-container .notification .image img {
      border-radius: 100px;
      width: 50px;
      height: 50px; }
    .notificacoes .notifications-container .notification .infos {
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #D8D8D8;
      display: flex;
      align-items: center;
      margin: 1em 0; }
  .notificacoes .notifications-container .options {
    position: relative; }
    .notificacoes .notifications-container .options .period {
      margin-right: 1em;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #979797; }
    .notificacoes .notifications-container .options .menu-notificacoes {
      position: absolute;
      padding: 1em;
      background: white;
      top: 0;
      left: -3.5em;
      z-index: 10;
      width: 165px; }
      .notificacoes .notifications-container .options .menu-notificacoes .item {
        padding: 0.5em; }
        .notificacoes .notifications-container .options .menu-notificacoes .item:hover {
          background: #008bff;
          color: white; }

.Client-remove-link-notificacao {
  color: black; }

.borda-notificacao-dashboard {
  border-bottom: 1px solid #cdd8de;
  margin-left: -15px;
  margin-right: -15px; }

.titulo-notificacoes {
  color: #9B9B9B;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px; }

.card-descricao {
  font-weight: bold;
  font-size: 15px;
  color: #3E4049;
  margin-bottom: 5px; }

.card-sigla {
  font-weight: bold;
  font-size: 12px;
  color: #3E4049; }

.card-casa {
  font-weight: bold;
  font-size: 13px;
  color: #3E4049; }

.temas .themes-container {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: auto auto auto auto auto;
  grid-row-gap: 1em;
  margin: 2em 0;
  width: 100%;
  grid-column-gap: 1em; }

.temas .tema-card {
  padding: 0.5em;
  border: 1px solid #e1e5ea;
  width: 130px;
  height: 150px;
  text-align: center;
  border-radius: 4px; }
  .temas .tema-card .favorite {
    text-align: left;
    color: #ffd436;
    cursor: pointer; }
  .temas .tema-card .figure {
    text-align: center; }
    .temas .tema-card .figure img {
      width: 100%;
      height: auto; }
  .temas .tema-card .text {
    margin-top: 1em;
    font-weight: bold; }

@media (max-width: 467px) {
  .temas .themes-container {
    display: block;
    margin: 0 auto; }
  .temas .tema-card {
    margin: 1em 0; } }

.corClient {
  color: #DC2D41; }

.corCielo {
  color: #008BFF; }

.cordarkBlueClient {
  color: #004177; }

/* MEDIA QUERY */
/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  /* - - - - - -  GERAL - - - - - -*/
  .paddingGeralXS {
    padding: 20px; }
  .logoMenuRelatorios {
    content: url(/static/media/logo-relatorios-purpleClient.1928d775.svg);
    content: var(--logoRelatorios);
    width: 60px;
    padding-left: 5px; }
  .sizeLogo {
    width: 50px; }
  .tituloRelatorio {
    color: #3E4049;
    font-size: 25px;
    line-height: 41px;
    font-weight: 900;
    padding-left: 30px; }
  .descricaoTituloRelatorio {
    color: #292A2B;
    font-size: 17px;
    margin-top: -20px;
    padding-left: 30px; }
  .periodoFiltro {
    font-size: 23px;
    color: #4b1196;
    color: var(--corPeriodoFiltroRelatorios);
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 900; }
  .divGraficoBuilding {
    width: 350px;
    padding-left: 85px; }
  .divIndice {
    margin-left: 0px;
    padding-left: 150px; }
  #divAlignIndice {
    padding-left: 20px; }
  /* RELATÓRIO PROPOSIÇÃO POR CASA LEGISLATIVA */
  .corIndiceCamara {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceCamara {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCamara {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceSenado {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceSenado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceSenado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceCongresso {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceCongresso {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCongresso {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CAMARA*/
  .tituloTabelaCamara {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCamara {
    background-Color: #9B9B9B; }
  .headTipoCamara {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantCamara {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoCamara {
    text-align: left; }
  .dadosColunaQuantCamara {
    text-align: left; }
  /* TABELA SENADO*/
  .tituloTabelaSenado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleSenado {
    background-Color: #9B9B9B; }
  .headTipoSenado {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantSenado {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoSenado {
    text-align: left; }
  .dadosColunaQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO*/
  .tituloTabelaCongresso {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCongresso {
    background-Color: #9B9B9B; }
  .headTipoCongresso {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantCongresso {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoCongresso {
    text-align: left; }
  .dadosColunaQuantCongresso {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR NíVEL PRIORIDADE */
  .paddingGeralXS {
    padding: 20px; }
  .sizeLogo {
    width: 50px; }
  .tituloRelatorio {
    color: #3E4049;
    font-size: 25px;
    line-height: 41px;
    font-weight: 900;
    padding-left: 30px; }
  .descricaoTituloRelatorio {
    color: #292A2B;
    font-size: 17px;
    margin-top: -20px;
    padding-left: 30px; }
  .periodoFiltro {
    font-size: 23px;
    color: #4b1196;
    color: var(--corPeriodoFiltroRelatorios);
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 900; }
  .divGraficoBuilding {
    width: 330px;
    padding: 0px; }
  .divIndice {
    margin-left: 0px;
    padding-left: 150px; }
  #divAlignIndice {
    padding-left: 20px; }
  .corIndiceBaixa {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceBaixa {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceBaixa {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMinima {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMinima {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMinima {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMedia {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMedia {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMedia {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAlta {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAlta {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAlta {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMaxima {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMaxima {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMaxima {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA MÍNIMA*/
  .tituloTabelaMinima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMinima {
    background-Color: #9B9B9B; }
  .headTipoMinima {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantMinima {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoMinima {
    text-align: left; }
  .dadosColunaQuantMinima {
    text-align: left; }
  /* TABELA BAIXA*/
  .tituloTabelaBaixa {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleBaixa {
    background-Color: #9B9B9B; }
  .headTipoBaixa {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantBaixa {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoBaixa {
    text-align: left; }
  .dadosColunaQuantBaixa {
    text-align: left; }
  /* TABELA MÉDIA*/
  .tituloTabelaMedia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMedia {
    background-Color: #9B9B9B; }
  .headTipoMedia {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantMedia {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoMedia {
    text-align: left; }
  .dadosColunaQuantMedia {
    text-align: left; }
  /* TABELA ALTA*/
  .tituloTabelaAlta {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAlta {
    background-Color: #9B9B9B; }
  .headTipoAlta {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantAlta {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoAlta {
    text-align: left; }
  .dadosColunaQuantAlta {
    text-align: left; }
  /* TABELA MÁXIMA*/
  .tituloTabelaMaxima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMaxima {
    background-Color: #9B9B9B; }
  .headTipoMaxima {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantMaxima {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoMaxima {
    text-align: left; }
  .dadosColunaQuantMaxima {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR SEU POSICIONAMENTO */
  .corIndiceFavoravel {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceFavoravel {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceFavoravel {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceFavoravelComEmenda {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceFavoravelComEmenda {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceFavoravelComEmenda {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceContrario {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceContrario {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceContrario {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceContrarioComEmendaSaneadora {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceContrarioComEmendaSaneadora {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceContrarioComEmendaSaneadora {
    font-size: 18px;
    padding-left: 15px; }
  .corIndicePendente {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndicePendente {
    font-size: 18px;
    font-weight: 900; }
  .totalIndicePendente {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceNeutro {
    background-color: #D8D8D8;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA FAVORAVEL*/
  .tituloTabelaFavoravel {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravel {
    background-Color: #9B9B9B; }
  .headTipoFavoravel {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantFavoravel {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravel {
    text-align: left; }
  .dadosColunaQuantFavoravel {
    text-align: left; }
  /* TABELA FAVORAVEL COM EMENDA*/
  .tituloTabelaFavoravelComEmenda {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravelComEmenda {
    background-Color: #9B9B9B; }
  .headTipoFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravelComEmenda {
    text-align: left; }
  .dadosColunaQuantFavoravelComEmenda {
    text-align: left; }
  /* TABELA CONTRARIO*/
  .tituloTabelaContrario {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrario {
    background-Color: #9B9B9B; }
  .headTipoContrario {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantContrario {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoContrario {
    text-align: left; }
  .dadosColunaQuantContrario {
    text-align: left; }
  /* TABELA CONTRARIO COM EMENDA SANEADORA*/
  .tituloTabelaContrarioEmendaSaneadora {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrarioEmendaSaneadora {
    background-Color: #9B9B9B; }
  .headTipoContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoContrarioEmendaSaneadora {
    text-align: left; }
  .dadosColunaQuantContrarioEmendaSaneadora {
    text-align: left; }
  /* TABELA PENDENTE*/
  .tituloTabelaPendente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStylePendente {
    background-Color: #9B9B9B; }
  .headTipoPendente {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantPendente {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoPendente {
    text-align: left; }
  .dadosColunaQuantPendente {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro {
    background-Color: #9B9B9B; }
  .headTipoNeutro {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantNeutro {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoNeutro {
    text-align: left; }
  .dadosColunaQuantNeutro {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR ATIVO & INATIVO */
  .corIndiceAtivo-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAtivo-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAtivo-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceInativo-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceInativo-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceInativo-A {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA ATIVO*/
  .tituloTabelaAtivo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAtivo-A {
    background-Color: #9B9B9B; }
  .headTipoAtivo-A {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantAtivo-A {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoAtivo-A {
    text-align: left; }
  .dadosColunaQuantAtivo-A {
    text-align: left; }
  /* TABELA INATIVO*/
  .tituloTabelaInativo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleInativo-A {
    background-Color: #9B9B9B; }
  .headTipoInativo-A {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantInativo-A {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoInativo-A {
    text-align: left; }
  .dadosColunaQuantInativo-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR QUALIFICAÇÃO */
  .corIndiceConvergente {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceConvergente {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceConvergente {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceDivergente {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceDivergente {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceDivergente {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceNaoClassificado {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNaoClassificado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceNaoClassificado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceNeutro {
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CONVERGENTE*/
  .tituloTabelaConvergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleConvergente {
    background-Color: #9B9B9B; }
  .headParlamentarConvergente {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoConvergente {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headEstadoConvergente {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoConvergente {
    text-align: left; }
  .dadosColunaQuantConvergente {
    text-align: left; }
  /* TABELA DIVERGENTE*/
  .tituloTabelaDivergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleDivergente {
    background-Color: #9B9B9B; }
  .headParlamentarDivergente {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoDivergente {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoDivergente {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoDivergente {
    text-align: left; }
  .dadosColunaQuantDivergente {
    text-align: left; }
  /* TABELA NAO CLASSIFICADO*/
  .tituloTabelaNaoClassificado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNaoClassificado {
    background-Color: #9B9B9B; }
  .headParlamentarNaoClassificado {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNaoClassificado {
    text-align: left; }
  .dadosColunaQuantNaoClassificado {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro-A {
    background-Color: #9B9B9B; }
  .headParlamentarNeutro {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNeutro {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNeutro {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNeutro-A {
    text-align: left; }
  .dadosColunaQuantNeutro-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÕES NOVAS POR PERIODO */
  /* TABELA */
  .theadStyleNovos {
    background-Color: #9B9B9B; }
  .headTipoNovos {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantNovos {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoNovos {
    text-align: left; }
  .dadosColunaQuantNovos {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO QUANTIDADE ACOES REALIZADAS POR PERIODO */
  .corIndiceAcaoPreparatoria {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAcaoPreparatoria {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAcaoPreparatoria {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAcaoDeInfluencia {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAcaoDeInfluencia {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAcaoDeInfluencia {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA ACAO PREPARATORIA*/
  .tituloTabelaAcaoPreparatoria {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoPreparatoria {
    background-Color: #9B9B9B; }
  .headTipoAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoPreparatoria {
    text-align: left; }
  .dadosColunaQuantAcaoPreparatoria {
    text-align: left; }
  /* TABELA ACAO DE INFLUENCIA*/
  .tituloTabelaAcaoDeInfluencia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoDeInfluencia {
    background-Color: #9B9B9B; }
  .headTipoAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoDeInfluencia {
    text-align: left; }
  .dadosColunaQuantAcaoDeInfluencia {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES POR PARTIDO POLITICO */
  /* TABELA */
  .theadStylePorPartido {
    background-Color: #9B9B9B; }
  .headTipoPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headNomePorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPorPartido {
    text-align: left; }
  .dadosColunaQuantPorPartido {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR PROFISSAO */
  .corIndiceAdvogado {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdvogado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAdvogado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceEmpresario {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceEmpresario {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaEmpresario {
    font-size: 18px; }
  .totalIndiceEmpresario {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAdm {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdm {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaAdm {
    font-size: 18px; }
  .totalIndiceAdm {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMedico {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMedico {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaMedico {
    font-size: 18px; }
  .totalIndiceMedico {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceProfessor {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceProfessor {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaProfessor {
    font-size: 18px; }
  .totalIndiceProfessor {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA LISTA*/
  .tituloTabelaLista {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleLista {
    background-Color: #9B9B9B; }
  .headProfissaoLista {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantLista {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaProfissaoLista {
    text-align: left; }
  .dadosColunaQuantLista {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES DIVIDIDOS CASAS LEGISLATIVAS */
  .corIndiceCamara-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCamara-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCamara-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceSenado-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceSenado-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceSenado-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceCongresso-A {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCongresso-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCongresso-A {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CAMARA */
  .theadStylePorPartido {
    background-Color: #9B9B9B; }
  .theadStyleCamara {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headSiglaCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .headNomeCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .headQuantCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .dadosColunaSiglaCamara {
    text-align: left; }
  .dadosColunaNomeCamara {
    text-align: left; }
  .dadosColunaQuantCamara {
    text-align: left; }
  /* TABELA SENADO */
  .theadStylePorPartido {
    background-Color: #9B9B9B; }
  .theadStyleCamara {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headSiglaCamara {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headNomeCamara {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .headQuantCamara {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-left: 100px; }
  .dadosColunaSiglaCamara {
    text-align: left; }
  .dadosColunaNomeCamara {
    text-align: left; }
  .dadosColunaQuantCamara {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES DIVIDIDOS CASAS LEGISLATIVAS */
  .corIndiceCamara-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCamara-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCamara-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceSenado-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceSenado-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceSenado-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceCongresso-A {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCongresso-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCongresso-A {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CAMARA */
  .headStySiglaCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 80px; }
  .headStyNomeCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 300px; }
  .headStyQuantCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 100px; }
  .dadosColSiglaCamara {
    text-align: left; }
  .dadosColNomeCamara {
    text-align: left; }
  .dadosColQuantCamara {
    text-align: left; }
  /* TABELA SENADO */
  .headStySiglaSenado {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 80px; }
  .headStyNomeSenado {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 300px; }
  .headStyQuantSenado {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 100px; }
  .dadosColSiglaSenado {
    text-align: left; }
  .dadosColNomeSenado {
    text-align: left; }
  .dadosColQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO */
  .headStySiglaCongresso {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 80px; }
  .headStyNomeCongresso {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 300px; }
  .headStyQuantCongresso {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px;
    min-width: 100px; }
  .dadosColSiglaCongresso {
    text-align: left; }
  .dadosColNomeCongresso {
    text-align: left; }
  .dadosColQuantCongresso {
    text-align: left; } }

/*/ Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  /* - - - - - GERAL - - - - - - -*/
  .logoMenuRelatorios {
    content: url(/static/media/logo-relatorios-purpleClient.1928d775.svg);
    content: var(--logoRelatorios);
    width: 70px; }
  .sizeLogo {
    width: 50px; }
  .tituloRelatorio {
    color: #3E4049;
    font-size: 25px;
    line-height: 41px;
    font-weight: 900;
    padding-left: 20px; }
  .descricaoTituloRelatorio {
    color: #292A2B;
    font-size: 17px;
    margin-top: -20px;
    padding-left: 20px; }
  .periodoFiltro {
    font-size: 23px;
    color: #4b1196;
    color: var(--corPeriodoFiltroRelatorios);
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 900; }
  .divGrafico {
    padding-left: 100px; }
  #align-grafico {
    padding-left: 100px; }
  .divGraficoBuilding {
    width: 300px;
    padding-left: 50px; }
  .divIndice {
    margin-left: 0px;
    padding-left: 150px; }
  #divAlignIndice {
    padding-left: 20px; }
  /* RELATÓRIO PROPOSIÇÃO POR CASA LEGISLATIVA */
  .corIndiceCamara {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceCamara {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCamara {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceSenado {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceSenado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceSenado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceCongresso {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceCongresso {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCongresso {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CÂMARA*/
  .tituloTabelaCamara {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCamara {
    background-Color: #9B9B9B; }
  .headTipoCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoCamara {
    text-align: left; }
  .dadosColunaQuantCamara {
    text-align: left; }
  /* TABELA SENADO*/
  .tituloTabelaSenado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleSenado {
    background-Color: #9B9B9B; }
  .headTipoSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoSenado {
    text-align: left; }
  .dadosColunaQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO*/
  .tituloTabelaCongresso {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCongresso {
    background-Color: #9B9B9B; }
  .headTipoCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoCongresso {
    text-align: left; }
  .dadosColunaQuantCongresso {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR NíVEL PRIORIDADE */
  .corIndiceBaixa {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceBaixa {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceBaixa {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMinima {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMinima {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMinima {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMedia {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMedia {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMedia {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAlta {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAlta {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAlta {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMaxima {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMaxima {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMaxima {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA MÍNIMA*/
  .tituloTabelaMinima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMinima {
    background-Color: #9B9B9B; }
  .headTipoMinima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMinima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMinima {
    text-align: left; }
  .dadosColunaQuantMinima {
    text-align: left; }
  /* TABELA BAIXA*/
  .tituloTabelaBaixa {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleBaixa {
    background-Color: #9B9B9B; }
  .headTipoBaixa {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantBaixa {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoBaixa {
    text-align: left; }
  .dadosColunaQuantBaixa {
    text-align: left; }
  /* TABELA MÉDIA*/
  .tituloTabelaMedia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMedia {
    background-Color: #9B9B9B; }
  .headTipoMedia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMedia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMedia {
    text-align: left; }
  .dadosColunaQuantMedia {
    text-align: left; }
  /* TABELA ALTA*/
  .tituloTabelaAlta {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAlta {
    background-Color: #9B9B9B; }
  .headTipoAlta {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAlta {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAlta {
    text-align: left; }
  .dadosColunaQuantAlta {
    text-align: left; }
  /* TABELA MÁXIMA*/
  .tituloTabelaMaxima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMaxima {
    background-Color: #9B9B9B; }
  .headTipoMaxima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMaxima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMaxima {
    text-align: left; }
  .dadosColunaQuantMaxima {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR SEU POSICIONAMENTO */
  .corIndiceFavoravel {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceFavoravel {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceFavoravel {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceFavoravelComEmenda {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceFavoravelComEmenda {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceFavoravelComEmenda {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceContrario {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceContrario {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceContrario {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceContrarioComEmendaSaneadora {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceContrarioComEmendaSaneadora {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceContrarioComEmendaSaneadora {
    font-size: 18px;
    padding-left: 15px; }
  .corIndicePendente {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndicePendente {
    font-size: 18px;
    font-weight: 900; }
  .totalIndicePendente {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceNeutro {
    background-color: #D8D8D8;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA FAVORAVEL*/
  .tituloTabelaFavoravel {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravel {
    background-Color: #9B9B9B; }
  .headTipoFavoravel {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantFavoravel {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravel {
    text-align: left; }
  .dadosColunaQuantFavoravel {
    text-align: left; }
  /* TABELA FAVORAVEL COM EMENDA*/
  .tituloTabelaFavoravelComEmenda {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravelComEmenda {
    background-Color: #9B9B9B; }
  .headTipoFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravelComEmenda {
    text-align: left; }
  .dadosColunaQuantFavoravelComEmenda {
    text-align: left; }
  /* TABELA CONTRARIO*/
  .tituloTabelaContrario {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrario {
    background-Color: #9B9B9B; }
  .headTipoContrario {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantContrario {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoContrario {
    text-align: left; }
  .dadosColunaQuantContrario {
    text-align: left; }
  /* TABELA CONTRARIO COM EMENDA SANEADORA*/
  .tituloTabelaContrarioEmendaSaneadora {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrarioEmendaSaneadora {
    background-Color: #9B9B9B; }
  .headTipoContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoContrarioEmendaSaneadora {
    text-align: left; }
  .dadosColunaQuantContrarioEmendaSaneadora {
    text-align: left; }
  /* TABELA PENDENTE*/
  .tituloTabelaPendente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStylePendente {
    background-Color: #9B9B9B; }
  .headTipoPendente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPendente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPendente {
    text-align: left; }
  .dadosColunaQuantPendente {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro {
    background-Color: #9B9B9B; }
  .headTipoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoNeutro {
    text-align: left; }
  .dadosColunaQuantNeutro {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR ATIVO & INATIVO */
  .corIndiceAtivo-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAtivo-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAtivo-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceInativo-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceInativo-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceInativo-A {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA ATIVO*/
  .tituloTabelaAtivo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAtivo-A {
    background-Color: #9B9B9B; }
  .headTipoAtivo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAtivo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAtivo-A {
    text-align: left; }
  .dadosColunaQuantAtivo-A {
    text-align: left; }
  /* TABELA INATIVO*/
  .tituloTabelaInativo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleInativo-A {
    background-Color: #9B9B9B; }
  .headTipoInativo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantInativo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoInativo-A {
    text-align: left; }
  .dadosColunaQuantInativo-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR QUALIFICAÇÃO */
  .corIndiceConvergente {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceConvergente {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceConvergente {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceDivergente {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceDivergente {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceDivergente {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceNaoClassificado {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNaoClassificado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceNaoClassificado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceNeutro {
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CONVERGENTE*/
  .tituloTabelaConvergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleConvergente {
    background-Color: #9B9B9B; }
  .headParlamentarConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headEstadoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoConvergente {
    text-align: left; }
  .dadosColunaQuantConvergente {
    text-align: left; }
  /* TABELA DIVERGENTE*/
  .tituloTabelaDivergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleDivergente {
    background-Color: #9B9B9B; }
  .headParlamentarDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoDivergente {
    text-align: left; }
  .dadosColunaQuantDivergente {
    text-align: left; }
  /* TABELA NAO CLASSIFICADO*/
  .tituloTabelaNaoClassificado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNaoClassificado {
    background-Color: #9B9B9B; }
  .headParlamentarNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNaoClassificado {
    text-align: left; }
  .dadosColunaQuantNaoClassificado {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro-A {
    background-Color: #9B9B9B; }
  .headParlamentarNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNeutro-A {
    text-align: left; }
  .dadosColunaQuantNeutro-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÕES NOVAS POR PERIODO */
  /* TABELA */
  .theadStyleNovos {
    background-Color: #9B9B9B; }
  .headTipoNovos {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantNovos {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoNovos {
    text-align: left; }
  .dadosColunaQuantNovos {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO QUANTIDADE ACOES REALIZADAS POR PERIODO */
  .corIndiceAcaoPreparatoria {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAcaoPreparatoria {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAcaoPreparatoria {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAcaoDeInfluencia {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAcaoDeInfluencia {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAcaoDeInfluencia {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA ACAO PREPARATORIA*/
  .tituloTabelaAcaoPreparatoria {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoPreparatoria {
    background-Color: #9B9B9B; }
  .headTipoAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoPreparatoria {
    text-align: left; }
  .dadosColunaQuantAcaoPreparatoria {
    text-align: left; }
  /* TABELA ACAO DE INFLUENCIA*/
  .tituloTabelaAcaoDeInfluencia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoDeInfluencia {
    background-Color: #9B9B9B; }
  .headTipoAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoDeInfluencia {
    text-align: left; }
  .dadosColunaQuantAcaoDeInfluencia {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES POR PARTIDO POLITICO */
  /* TABELA */
  .theadStylePorPartido {
    background-Color: #9B9B9B; }
  .headTipoPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headNomePorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPorPartido {
    text-align: left; }
  .dadosColunaQuantPorPartido {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR PROFISSAO */
  .corIndiceAdvogado {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdvogado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAdvogado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceEmpresario {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceEmpresario {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaEmpresario {
    font-size: 18px; }
  .totalIndiceEmpresario {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAdm {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdm {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaAdm {
    font-size: 18px; }
  .totalIndiceAdm {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMedico {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMedico {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaMedico {
    font-size: 18px; }
  .totalIndiceMedico {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceProfessor {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceProfessor {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaProfessor {
    font-size: 18px; }
  .totalIndiceProfessor {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA LISTA*/
  .tituloTabelaLista {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleLista {
    background-Color: #9B9B9B; }
  .headProfissaoLista {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantLista {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaProfissaoLista {
    text-align: left; }
  .dadosColunaQuantLista {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES DIVIDIDOS CASAS LEGISLATIVAS */
  .corIndiceCamara-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCamara-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCamara-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceSenado-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceSenado-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceSenado-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceCongresso-A {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCongresso-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCongresso-A {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CAMARA */
  .headStySiglaCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .headStyNomeCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .headStyQuantCamara {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .dadosColSiglaCamara {
    text-align: left; }
  .dadosColNomeCamara {
    text-align: left; }
  .dadosColQuantCamara {
    text-align: left; }
  /* TABELA SENADO */
  .headStySiglaSenado {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .headStyNomeSenado {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .headStyQuantSenado {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .dadosColSiglaSenado {
    text-align: left; }
  .dadosColNomeSenado {
    text-align: left; }
  .dadosColQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO */
  .headStySiglaCongresso {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .headStyNomeCongresso {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .headStyQuantCongresso {
    color: white;
    text-align: left;
    font-size: 18px;
    margin-left: 100px; }
  .dadosColSiglaCongresso {
    text-align: left; }
  .dadosColNomeCongresso {
    text-align: left; }
  .dadosColQuantCongresso {
    text-align: left; } }

/*/ Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  /*- - - - - GERAL - - - - -*/
  .logoMenuRelatorios {
    content: url(/static/media/logo-relatorios-purpleClient.1928d775.svg);
    content: var(--logoRelatorios);
    width: 70px; }
  .sizeLogo {
    width: 50px; }
  .tituloRelatorio {
    color: #3E4049;
    font-size: 30px;
    line-height: 41px;
    font-weight: 900;
    padding-left: 20px; }
  .descricaoTituloRelatorio {
    color: #292A2B;
    font-size: 20px;
    margin-top: -10px;
    padding-left: 20px; }
  .periodoFiltro {
    font-size: 27px;
    color: #4b1196;
    color: var(--corPeriodoFiltroRelatorios);
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 900; }
  .divGrafico {
    padding-left: 100px; }
  #align-grafico {
    padding-left: 100px; }
  .divGraficoBuilding {
    width: 260px; }
  .divIndice {
    margin-left: 0px;
    padding-left: 100px; }
  #divAlignIndice {
    padding-left: 20px; }
  /* RELATÓRIO PROPOSIÇÃO POR CASA LEGISLATIVA */
  .corIndiceCamara {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceCamara {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCamara {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceSenado {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceSenado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceSenado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceCongresso {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceCongresso {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCongresso {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CÂMARA*/
  .tituloTabelaCamara {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCamara {
    background-Color: #9B9B9B; }
  .headTipoCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoCamara {
    text-align: left; }
  .dadosColunaQuantCamara {
    text-align: left; }
  /* TABELA SENADO*/
  .tituloTabelaSenado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleSenado {
    background-Color: #9B9B9B; }
  .headTipoSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoSenado {
    text-align: left; }
  .dadosColunaQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO*/
  .tituloTabelaCongresso {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCongresso {
    background-Color: #9B9B9B; }
  .headTipoCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoCongresso {
    text-align: left; }
  .dadosColunaQuantCongresso {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR NíVEL PRIORIDADE */
  .corIndiceBaixa {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceBaixa {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceBaixa {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMinima {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMinima {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMinima {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMedia {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMedia {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMedia {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAlta {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAlta {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAlta {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMaxima {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceMaxima {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceMaxima {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA MÍNIMA*/
  .tituloTabelaMinima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMinima {
    background-Color: #9B9B9B; }
  .headTipoMinima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMinima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMinima {
    text-align: left; }
  .dadosColunaQuantMinima {
    text-align: left; }
  /* TABELA BAIXA*/
  .tituloTabelaBaixa {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleBaixa {
    background-Color: #9B9B9B; }
  .headTipoBaixa {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantBaixa {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoBaixa {
    text-align: left; }
  .dadosColunaQuantBaixa {
    text-align: left; }
  /* TABELA MÉDIA*/
  .tituloTabelaMedia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMedia {
    background-Color: #9B9B9B; }
  .headTipoMedia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMedia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMedia {
    text-align: left; }
  .dadosColunaQuantMedia {
    text-align: left; }
  /* TABELA ALTA*/
  .tituloTabelaAlta {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAlta {
    background-Color: #9B9B9B; }
  .headTipoAlta {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAlta {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAlta {
    text-align: left; }
  .dadosColunaQuantAlta {
    text-align: left; }
  /* TABELA MÁXIMA*/
  .tituloTabelaMaxima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMaxima {
    background-Color: #9B9B9B; }
  .headTipoMaxima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMaxima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMaxima {
    text-align: left; }
  .dadosColunaQuantMaxima {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR SEU POSICIONAMENTO */
  .corIndiceFavoravel {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceFavoravel {
    font-size: 15px;
    font-weight: 900; }
  .totalIndiceFavoravel {
    font-size: 15px;
    padding-left: 15px; }
  .corIndiceFavoravelComEmenda {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceFavoravelComEmenda {
    font-size: 15px;
    font-weight: 900; }
  .totalIndiceFavoravelComEmenda {
    font-size: 15px;
    padding-left: 15px; }
  .corIndiceContrario {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceContrario {
    font-size: 15px;
    font-weight: 900; }
  .totalIndiceContrario {
    font-size: 15px;
    padding-left: 15px; }
  .corIndiceContrarioComEmendaSaneadora {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceContrarioComEmendaSaneadora {
    font-size: 15px;
    font-weight: 900; }
  .totalIndiceContrarioComEmendaSaneadora {
    font-size: 15px;
    padding-left: 15px; }
  .corIndicePendente {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndicePendente {
    font-size: 15px;
    font-weight: 900; }
  .totalIndicePendente {
    font-size: 15px;
    padding-left: 15px; }
  .corIndiceNeutro {
    background-color: #D8D8D8;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 15px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 15px;
    padding-left: 15px; }
  /* TABELA FAVORAVEL*/
  .tituloTabelaFavoravel {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravel {
    background-Color: #9B9B9B; }
  .headTipoFavoravel {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantFavoravel {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravel {
    text-align: left; }
  .dadosColunaQuantFavoravel {
    text-align: left; }
  /* TABELA FAVORAVEL COM EMENDA*/
  .tituloTabelaFavoravelComEmenda {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravelComEmenda {
    background-Color: #9B9B9B; }
  .headTipoFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravelComEmenda {
    text-align: left; }
  .dadosColunaQuantFavoravelComEmenda {
    text-align: left; }
  /* TABELA CONTRARIO*/
  .tituloTabelaContrario {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrario {
    background-Color: #9B9B9B; }
  .headTipoContrario {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantContrario {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoContrario {
    text-align: left; }
  .dadosColunaQuantContrario {
    text-align: left; }
  /* TABELA CONTRARIO COM EMENDA SANEADORA*/
  .tituloTabelaContrarioEmendaSaneadora {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrarioEmendaSaneadora {
    background-Color: #9B9B9B; }
  .headTipoContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoContrarioEmendaSaneadora {
    text-align: left; }
  .dadosColunaQuantContrarioEmendaSaneadora {
    text-align: left; }
  /* TABELA PENDENTE*/
  .tituloTabelaPendente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStylePendente {
    background-Color: #9B9B9B; }
  .headTipoPendente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPendente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPendente {
    text-align: left; }
  .dadosColunaQuantPendente {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro {
    background-Color: #9B9B9B; }
  .headTipoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoNeutro {
    text-align: left; }
  .dadosColunaQuantNeutro {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR ATIVO & INATIVO */
  .corIndiceAtivo-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAtivo-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAtivo-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceInativo-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceInativo-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceInativo-A {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA ATIVO*/
  .tituloTabelaAtivo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAtivo-A {
    background-Color: #9B9B9B; }
  .headTipoAtivo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAtivo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAtivo-A {
    text-align: left; }
  .dadosColunaQuantAtivo-A {
    text-align: left; }
  /* TABELA INATIVO*/
  .tituloTabelaInativo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleInativo-A {
    background-Color: #9B9B9B; }
  .headTipoInativo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantInativo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoInativo-A {
    text-align: left; }
  .dadosColunaQuantInativo-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR QUALIFICAÇÃO */
  .corIndiceConvergente {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceConvergente {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceConvergente {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceDivergente {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceDivergente {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceDivergente {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceNaoClassificado {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNaoClassificado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceNaoClassificado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceNeutro {
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CONVERGENTE*/
  .tituloTabelaConvergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleConvergente {
    background-Color: #9B9B9B; }
  .headParlamentarConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headEstadoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoConvergente {
    text-align: left; }
  .dadosColunaQuantConvergente {
    text-align: left; }
  /* TABELA DIVERGENTE*/
  .tituloTabelaDivergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleDivergente {
    background-Color: #9B9B9B; }
  .headParlamentarDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoDivergente {
    text-align: left; }
  .dadosColunaQuantDivergente {
    text-align: left; }
  /* TABELA NAO CLASSIFICADO*/
  .tituloTabelaNaoClassificado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNaoClassificado {
    background-Color: #9B9B9B; }
  .headParlamentarNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNaoClassificado {
    text-align: left; }
  .dadosColunaQuantNaoClassificado {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro-A {
    background-Color: #9B9B9B; }
  .headParlamentarNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNeutro-A {
    text-align: left; }
  .dadosColunaQuantNeutro-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÕES NOVAS POR PERIODO */
  /* TABELA */
  .theadStyleNovos {
    background-Color: #9B9B9B; }
  .headTipoNovos {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantNovos {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoNovos {
    text-align: left; }
  .dadosColunaQuantNovos {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO QUANTIDADE ACOES REALIZADAS POR PERIODO */
  .corIndiceAcaoPreparatoria {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAcaoPreparatoria {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAcaoPreparatoria {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAcaoDeInfluencia {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-right: 15px; }
  .textoIndiceAcaoDeInfluencia {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAcaoDeInfluencia {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA ACAO PREPARATORIA*/
  .tituloTabelaAcaoPreparatoria {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoPreparatoria {
    background-Color: #9B9B9B; }
  .headTipoAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoPreparatoria {
    text-align: left; }
  .dadosColunaQuantAcaoPreparatoria {
    text-align: left; }
  /* TABELA ACAO DE INFLUENCIA*/
  .tituloTabelaAcaoDeInfluencia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoDeInfluencia {
    background-Color: #9B9B9B; }
  .headTipoAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoDeInfluencia {
    text-align: left; }
  .dadosColunaQuantAcaoDeInfluencia {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES POR PARTIDO POLITICO */
  /* TABELA */
  .theadStylePorPartido {
    background-Color: #9B9B9B; }
  .headTipoPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headNomePorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPorPartido {
    text-align: left; }
  .dadosColunaQuantPorPartido {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR PROFISSAO */
  .corIndiceAdvogado {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdvogado {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceAdvogado {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceEmpresario {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceEmpresario {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaEmpresario {
    font-size: 18px; }
  .totalIndiceEmpresario {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceAdm {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdm {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaAdm {
    font-size: 18px; }
  .totalIndiceAdm {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceMedico {
    background-color: #FFD200;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMedico {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaMedico {
    font-size: 18px; }
  .totalIndiceMedico {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceProfessor {
    background-color: #007AFF;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceProfessor {
    font-size: 18px;
    font-weight: 900; }
  .tituloTabelaProfessor {
    font-size: 18px; }
  .totalIndiceProfessor {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA LISTA*/
  .tituloTabelaLista {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleLista {
    background-Color: #9B9B9B; }
  .headProfissaoLista {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantLista {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaProfissaoLista {
    text-align: left; }
  .dadosColunaQuantLista {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES DIVIDIDOS CASAS LEGISLATIVAS */
  .corIndiceCamara-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCamara-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCamara-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceSenado-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceSenado-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceSenado-A {
    font-size: 18px;
    padding-left: 15px; }
  .corIndiceCongresso-A {
    background-color: #5856D7;
    border-radius: 150px;
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCongresso-A {
    font-size: 18px;
    font-weight: 900; }
  .totalIndiceCongresso-A {
    font-size: 18px;
    padding-left: 15px; }
  /* TABELA CAMARA */
  .headStySiglaCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaCamara {
    text-align: left; }
  .dadosColNomeCamara {
    text-align: left; }
  .dadosColQuantCamara {
    text-align: left; }
  /* TABELA SENADO */
  .headStySiglaSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaSenado {
    text-align: left; }
  .dadosColNomeSenado {
    text-align: left; }
  .dadosColQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO */
  .headStySiglaCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaCongresso {
    text-align: left; }
  .dadosColNomeCongresso {
    text-align: left; }
  .dadosColQuantCongresso {
    text-align: left; } }

/*/ Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* - - - - - -GERAL - - - - - */
  .logoMenuRelatorios {
    content: url(/static/media/logo-relatorios-purpleClient.1928d775.svg);
    content: var(--logoRelatorios);
    width: 70px; }
  .sizeLogo {
    width: 70px; }
  .divGraficoBuilding {
    width: 330px; }
  .tituloRelatorio {
    color: #3E4049;
    font-size: 30px;
    line-height: 41px;
    font-weight: 900; }
  .descricaoTituloRelatorio {
    color: #292A2B;
    font-size: 20px;
    margin-top: -10px; }
  .periodoFiltro {
    font-size: 27px;
    color: #4b1196;
    color: var(--corPeriodoFiltroRelatorios);
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 900; }
  .divGrafico {
    margin-left: 130px; }
  .divIndice {
    margin-left: -130px; }
  /* RELATÓRIO PROPOSIÇÃO POR CASA LEGISLATIVA */
  .corIndiceCamara {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCamara {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceCamara {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceSenado {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceSenado {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaSenado {
    font-size: 26px; }
  .totalIndiceSenado {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceCongresso {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCongresso {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaCongresso {
    font-size: 26px; }
  .totalIndiceCongresso {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA CÂMARA*/
  .tituloTabelaCamara {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCamara {
    background-Color: #9B9B9B; }
  .headTipoCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoCamara {
    text-align: left; }
  .dadosColunaQuantCamara {
    text-align: left; }
  /* TABELA SENADO*/
  .tituloTabelaSenado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleSenado {
    background-Color: #9B9B9B; }
  .headTipoSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoSenado {
    text-align: left; }
  .dadosColunaQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO*/
  .tituloTabelaCongresso {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCongresso {
    background-Color: #9B9B9B; }
  .headTipoCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoCongresso {
    text-align: left; }
  .dadosColunaQuantCongresso {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR NíVEL PRIORIDADE */
  .corIndiceMinima {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMinima {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceMinima {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceBaixa {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceBaixa {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceBaixa {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceMedia {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMedia {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceMedia {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceAlta {
    background-color: #FFD200;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAlta {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAlta {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceMaxima {
    background-color: #007AFF;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMaxima {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceMaxima {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA MÍNIMA*/
  .tituloTabelaMinima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMinima {
    background-Color: #9B9B9B; }
  .headTipoMinima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMinima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMinima {
    text-align: left; }
  .dadosColunaQuantMinima {
    text-align: left; }
  /* TABELA BAIXA*/
  .tituloTabelaBaixa {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleBaixa {
    background-Color: #9B9B9B; }
  .headTipoBaixa {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantBaixa {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoBaixa {
    text-align: left; }
  .dadosColunaQuantBaixa {
    text-align: left; }
  /* TABELA MÉDIA*/
  .tituloTabelaMedia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMedia {
    background-Color: #9B9B9B; }
  .headTipoMedia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMedia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMedia {
    text-align: left; }
  .dadosColunaQuantMedia {
    text-align: left; }
  /* TABELA ALTA*/
  .tituloTabelaAlta {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAlta {
    background-Color: #9B9B9B; }
  .headTipoAlta {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAlta {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAlta {
    text-align: left; }
  .dadosColunaQuantAlta {
    text-align: left; }
  /* TABELA MÁXIMA*/
  .tituloTabelaMaxima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMaxima {
    background-Color: #9B9B9B; }
  .headTipoMaxima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMaxima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMaxima {
    text-align: left; }
  .dadosColunaQuantMaxima {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR SEU POSICIONAMENTO */
  .corIndiceFavoravel {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceFavoravel {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceFavoravel {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceFavoravelComEmenda {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceFavoravelComEmenda {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceFavoravelComEmenda {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceContrario {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceContrario {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceContrario {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceContrarioComEmendaSaneadora {
    background-color: #007AFF;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceContrarioComEmendaSaneadora {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceContrarioComEmendaSaneadora {
    font-size: 21px;
    padding-left: 15px; }
  .corIndicePendente {
    background-color: #FFD200;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndicePendente {
    font-size: 20px;
    font-weight: 900; }
  .totalIndicePendente {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceNeutro {
    background-color: #D8D8D8;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA FAVORAVEL*/
  .tituloTabelaFavoravel {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravel {
    background-Color: #9B9B9B; }
  .headTipoFavoravel {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantFavoravel {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravel {
    text-align: left; }
  .dadosColunaQuantFavoravel {
    text-align: left; }
  /* TABELA FAVORAVEL COM EMENDA*/
  .tituloTabelaFavoravelComEmenda {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravelComEmenda {
    background-Color: #9B9B9B; }
  .headTipoFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravelComEmenda {
    text-align: left; }
  .dadosColunaQuantFavoravelComEmenda {
    text-align: left; }
  /* TABELA CONTRARIO*/
  .tituloTabelaContrario {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrario {
    background-Color: #9B9B9B; }
  .headTipoContrario {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantContrario {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoContrario {
    text-align: left; }
  .dadosColunaQuantContrario {
    text-align: left; }
  /* TABELA CONTRARIO COM EMENDA SANEADORA*/
  .tituloTabelaContrarioEmendaSaneadora {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrarioEmendaSaneadora {
    background-Color: #9B9B9B; }
  .headTipoContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoContrarioEmendaSaneadora {
    text-align: left; }
  .dadosColunaQuantContrarioEmendaSaneadora {
    text-align: left; }
  /* TABELA PENDENTE*/
  .tituloTabelaPendente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStylePendente {
    background-Color: #9B9B9B; }
  .headTipoPendente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPendente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPendente {
    text-align: left; }
  .dadosColunaQuantPendente {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro {
    background-Color: #9B9B9B; }
  .headTipoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoNeutro {
    text-align: left; }
  .dadosColunaQuantNeutro {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR ATIVO & INATIVO */
  .corIndiceAtivo-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAtivo-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAtivo-A {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceInativo-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceInativo-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceInativo-A {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA ATIVO*/
  .tituloTabelaAtivo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAtivo-A {
    background-Color: #9B9B9B; }
  .headTipoAtivo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAtivo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAtivo-A {
    text-align: left; }
  .dadosColunaQuantAtivo-A {
    text-align: left; }
  /* TABELA INATIVO*/
  .tituloTabelaInativo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleInativo-A {
    background-Color: #9B9B9B; }
  .headTipoInativo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantInativo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoInativo-A {
    text-align: left; }
  .dadosColunaQuantInativo-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR QUALIFICAÇÃO */
  .corIndiceConvergente {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceConvergente {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceConvergente {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceDivergente {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceDivergente {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceDivergente {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceNaoClassificado {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceNaoClassificado {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceNaoClassificado {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceNeutro {
    background-color: #D8D8D8;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA CONVERGENTE*/
  .tituloTabelaConvergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleConvergente {
    background-Color: #9B9B9B; }
  .headParlamentarConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headEstadoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoConvergente {
    text-align: left; }
  .dadosColunaQuantConvergente {
    text-align: left; }
  /* TABELA DIVERGENTE*/
  .tituloTabelaDivergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleDivergente {
    background-Color: #9B9B9B; }
  .headParlamentarDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoDivergente {
    text-align: left; }
  .dadosColunaQuantDivergente {
    text-align: left; }
  /* TABELA NAO CLASSIFICADO*/
  .tituloTabelaNaoClassificado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNaoClassificado {
    background-Color: #9B9B9B; }
  .headParlamentarNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNaoClassificado {
    text-align: left; }
  .dadosColunaQuantNaoClassificado {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro-A {
    background-Color: #9B9B9B; }
  .headParlamentarNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNeutro-A {
    text-align: left; }
  .dadosColunaQuantNeutro-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÕES NOVAS POR PERIODO */
  /* TABELA */
  .theadStyleNovos {
    background-Color: #9B9B9B; }
  .headTipoNovos {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantNovos {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoNovos {
    text-align: left; }
  .dadosColunaQuantNovos {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO QUANTIDADE ACOES REALIZADAS POR PERIODO */
  .corIndiceAcaoPreparatoria {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAcaoPreparatoria {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAcaoPreparatoria {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceAcaoDeInfluencia {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAcaoDeInfluencia {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAcaoDeInfluencia {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA ACAO PREPARATORIA*/
  .tituloTabelaAcaoPreparatoria {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoPreparatoria {
    background-Color: #9B9B9B; }
  .headTipoAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoPreparatoria {
    text-align: left; }
  .dadosColunaQuantAcaoPreparatoria {
    text-align: left; }
  /* TABELA ACAO DE INFLUENCIA*/
  .tituloTabelaAcaoDeInfluencia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoDeInfluencia {
    background-Color: #9B9B9B; }
  .headTipoAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoDeInfluencia {
    text-align: left; }
  .dadosColunaQuantAcaoDeInfluencia {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES POR PARTIDO POLITICO */
  /* TABELA */
  .theadStylePorPartido {
    background-Color: #9B9B9B; }
  .headTipoPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headNomePorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPorPartido {
    text-align: left; }
  .dadosColunaQuantPorPartido {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR PROFISSAO */
  .corIndiceAdvogado {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdvogado {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAdvogado {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceEmpresario {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceEmpresario {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaEmpresario {
    font-size: 26px; }
  .totalIndiceEmpresario {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceAdm {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdm {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaAdm {
    font-size: 26px; }
  .totalIndiceAdm {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceMedico {
    background-color: #FFD200;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMedico {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaMedico {
    font-size: 26px; }
  .totalIndiceMedico {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceProfessor {
    background-color: #007AFF;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceProfessor {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaProfessor {
    font-size: 26px; }
  .totalIndiceProfessor {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA LISTA*/
  .tituloTabelaLista {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleLista {
    background-Color: #9B9B9B; }
  .headProfissaoLista {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantLista {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaProfissaoLista {
    text-align: left; }
  .dadosColunaQuantLista {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES DIVIDIDOS CASAS LEGISLATIVAS */
  .corIndiceCamara-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCamara-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceCamara-A {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceSenado-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceSenado-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceSenado-A {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceCongresso-A {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCongresso-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceCongresso-A {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA CAMARA */
  .headStySiglaCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaCamara {
    text-align: left; }
  .dadosColNomeCamara {
    text-align: left; }
  .dadosColQuantCamara {
    text-align: left; }
  /* TABELA SENADO */
  .headStySiglaSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaSenado {
    text-align: left; }
  .dadosColNomeSenado {
    text-align: left; }
  .dadosColQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO */
  .headStySiglaCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaCongresso {
    text-align: left; }
  .dadosColNomeCongresso {
    text-align: left; }
  .dadosColQuantCongresso {
    text-align: left; } }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  /* - - - - - - GERAL - - - - - - -*/
  .logoMenuRelatorios {
    content: url(/static/media/logo-relatorios-purpleClient.1928d775.svg);
    content: var(--logoRelatorios);
    width: 70px; }
  .sizeLogo {
    width: 70px; }
  .divGraficoBuilding {
    width: 330px; }
  .tituloRelatorio {
    color: #3E4049;
    font-size: 30px;
    line-height: 41px;
    font-weight: 900; }
  .descricaoTituloRelatorio {
    color: #292A2B;
    font-size: 20px;
    margin-top: -10px; }
  .periodoFiltro {
    font-size: 27px;
    color: #4b1196;
    color: var(--corPeriodoFiltroRelatorios);
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 900; }
  .divGrafico {
    margin-left: 200px; }
  .divIndice {
    margin-left: -200px; }
  /* RELATÓRIO PROPOSIÇÃO POR CASA LEGISLATIVA */
  .corIndiceCamara {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCamara {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceCamara {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceSenado {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceSenado {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaSenado {
    font-size: 26px; }
  .totalIndiceSenado {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceCongresso {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCongresso {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaCongresso {
    font-size: 26px; }
  .totalIndiceCongresso {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA CÂMARA*/
  .tituloTabelaCamara {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCamara {
    background-Color: #9B9B9B; }
  .headTipoCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoCamara {
    text-align: left; }
  .dadosColunaQuantCamara {
    text-align: left; }
  /* TABELA SENADO*/
  .tituloTabelaSenado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleSenado {
    background-Color: #9B9B9B; }
  .headTipoSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoSenado {
    text-align: left; }
  .dadosColunaQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO*/
  .tituloTabelaCongresso {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleCongresso {
    background-Color: #9B9B9B; }
  .headTipoCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoCongresso {
    text-align: left; }
  .dadosColunaQuantCongresso {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR NÍVEL DE PRIORIDADE */
  .corIndiceMinima {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMinima {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceMinima {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceBaixa {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceBaixa {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceBaixa {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceMedia {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMedia {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceMedia {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceAlta {
    background-color: #FFD200;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAlta {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAlta {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceMaxima {
    background-color: #007AFF;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMaxima {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceMaxima {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA MÍNIMA*/
  .tituloTabelaMinima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMinima {
    background-Color: #9B9B9B; }
  .headTipoMinima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMinima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMinima {
    text-align: left; }
  .dadosColunaQuantMinima {
    text-align: left; }
  /* TABELA BAIXA*/
  .tituloTabelaBaixa {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleBaixa {
    background-Color: #9B9B9B; }
  .headTipoBaixa {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantBaixa {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoBaixa {
    text-align: left; }
  .dadosColunaQuantBaixa {
    text-align: left; }
  /* TABELA MÉDIA*/
  .tituloTabelaMedia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMedia {
    background-Color: #9B9B9B; }
  .headTipoMedia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMedia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMedia {
    text-align: left; }
  .dadosColunaQuantMedia {
    text-align: left; }
  /* TABELA ALTA*/
  .tituloTabelaAlta {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAlta {
    background-Color: #9B9B9B; }
  .headTipoAlta {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAlta {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAlta {
    text-align: left; }
  .dadosColunaQuantAlta {
    text-align: left; }
  /* TABELA MÁXIMA*/
  .tituloTabelaMaxima {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleMaxima {
    background-Color: #9B9B9B; }
  .headTipoMaxima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantMaxima {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoMaxima {
    text-align: left; }
  .dadosColunaQuantMaxima {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR SEU POSICIONAMENTO */
  .corIndiceFavoravel {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceFavoravel {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceFavoravel {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceFavoravelComEmenda {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceFavoravelComEmenda {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceFavoravelComEmenda {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceContrario {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceContrario {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceContrario {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceContrarioComEmendaSaneadora {
    background-color: #007AFF;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceContrarioComEmendaSaneadora {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceContrarioComEmendaSaneadora {
    font-size: 21px;
    padding-left: 15px; }
  .corIndicePendente {
    background-color: #FFD200;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndicePendente {
    font-size: 20px;
    font-weight: 900; }
  .totalIndicePendente {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceNeutro {
    background-color: #D8D8D8;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA FAVORAVEL*/
  .tituloTabelaFavoravel {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravel {
    background-Color: #9B9B9B; }
  .headTipoFavoravel {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantFavoravel {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravel {
    text-align: left; }
  .dadosColunaQuantFavoravel {
    text-align: left; }
  /* TABELA FAVORAVEL COM EMENDA*/
  .tituloTabelaFavoravelComEmenda {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleFavoravelComEmenda {
    background-Color: #9B9B9B; }
  .headTipoFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantFavoravelComEmenda {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoFavoravelComEmenda {
    text-align: left; }
  .dadosColunaQuantFavoravelComEmenda {
    text-align: left; }
  /* TABELA CONTRARIO*/
  .tituloTabelaContrario {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrario {
    background-Color: #9B9B9B; }
  .headTipoContrario {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantContrario {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoContrario {
    text-align: left; }
  .dadosColunaQuantContrario {
    text-align: left; }
  /* TABELA CONTRARIO COM EMENDA SANEADORA*/
  .tituloTabelaContrarioEmendaSaneadora {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleContrarioEmendaSaneadora {
    background-Color: #9B9B9B; }
  .headTipoContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantContrarioEmendaSaneadora {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoContrarioEmendaSaneadora {
    text-align: left; }
  .dadosColunaQuantContrarioEmendaSaneadora {
    text-align: left; }
  /* TABELA PENDENTE*/
  .tituloTabelaPendente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStylePendente {
    background-Color: #9B9B9B; }
  .headTipoPendente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPendente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPendente {
    text-align: left; }
  .dadosColunaQuantPendente {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro {
    background-Color: #9B9B9B; }
  .headTipoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoNeutro {
    text-align: left; }
  .dadosColunaQuantNeutro {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÃO POR ATIVO & INATIVO */
  .corIndiceAtivo-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAtivo-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAtivo-A {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceInativo-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceInativo-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceInativo-A {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA ATIVO*/
  .tituloTabelaAtivo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAtivo-A {
    background-Color: #9B9B9B; }
  .headTipoAtivo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAtivo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAtivo-A {
    text-align: left; }
  .dadosColunaQuantAtivo-A {
    text-align: left; }
  /* TABELA INATIVO*/
  .tituloTabelaInativo-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleInativo-A {
    background-Color: #9B9B9B; }
  .headTipoInativo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantInativo-A {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoInativo-A {
    text-align: left; }
  .dadosColunaQuantInativo-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR QUALIFICAÇÃO */
  .corIndiceConvergente {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceConvergente {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceConvergente {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceDivergente {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceDivergente {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceDivergente {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceNaoClassificado {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceNaoClassificado {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceNaoClassificado {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceNeutro {
    background-color: #D8D8D8;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceNeutro {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceNeutro {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA CONVERGENTE*/
  .tituloTabelaConvergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleConvergente {
    background-Color: #9B9B9B; }
  .headParlamentarConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headEstadoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoConvergente {
    text-align: left; }
  .dadosColunaQuantConvergente {
    text-align: left; }
  /* TABELA CONVERGENTE*/
  .tituloTabelaConvergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleConvergente {
    background-Color: #9B9B9B; }
  .headParlamentarConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headEstadoConvergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoConvergente {
    text-align: left; }
  .dadosColunaQuantConvergente {
    text-align: left; }
  /* TABELA DIVERGENTE*/
  .tituloTabelaDivergente {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleDivergente {
    background-Color: #9B9B9B; }
  .headParlamentarDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoDivergente {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoDivergente {
    text-align: left; }
  .dadosColunaQuantDivergente {
    text-align: left; }
  /* TABELA NAO CLASSIFICADO*/
  .tituloTabelaNaoClassificado {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNaoClassificado {
    background-Color: #9B9B9B; }
  .headParlamentarNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNaoClassificado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNaoClassificado {
    text-align: left; }
  .dadosColunaQuantNaoClassificado {
    text-align: left; }
  /* TABELA NEUTRO*/
  .tituloTabelaNeutro-A {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleNeutro-A {
    background-Color: #9B9B9B; }
  .headParlamentarNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headPartidoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .headEstadoNeutro {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px;
    min-width: 200px; }
  .dadosColunaTipoNeutro-A {
    text-align: left; }
  .dadosColunaQuantNeutro-A {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PROPOSIÇÕES NOVAS POR PERIODO */
  /* TABELA */
  .theadStyleNovos {
    background-Color: #9B9B9B; }
  .headTipoNovos {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantNovos {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoNovos {
    text-align: left; }
  .dadosColunaQuantNovos {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO QUANTIDADE ACOES REALIZADAS POR PERIODO */
  .corIndiceAcaoPreparatoria {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAcaoPreparatoria {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAcaoPreparatoria {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceAcaoDeInfluencia {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAcaoDeInfluencia {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAcaoDeInfluencia {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA ACAO PREPARATORIA*/
  .tituloTabelaAcaoPreparatoria {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoPreparatoria {
    background-Color: #9B9B9B; }
  .headTipoAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAcaoPreparatoria {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoPreparatoria {
    text-align: left; }
  .dadosColunaQuantAcaoPreparatoria {
    text-align: left; }
  /* TABELA ACAO DE INFLUENCIA*/
  .tituloTabelaAcaoDeInfluencia {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleAcaoDeInfluencia {
    background-Color: #9B9B9B; }
  .headTipoAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantAcaoDeInfluencia {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoAcaoDeInfluencia {
    text-align: left; }
  .dadosColunaQuantAcaoDeInfluencia {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES POR PARTIDO POLITICO */
  /* TABELA */
  .theadStylePorPartido {
    background-Color: #9B9B9B; }
  .headTipoPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headNomePorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantPorPartido {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaTipoPorPartido {
    text-align: left; }
  .dadosColunaQuantPorPartido {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTAR POR PROFISSAO */
  .corIndiceAdvogado {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdvogado {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceAdvogado {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceEmpresario {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceEmpresario {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaEmpresario {
    font-size: 26px; }
  .totalIndiceEmpresario {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceAdm {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceAdm {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaAdm {
    font-size: 26px; }
  .totalIndiceAdm {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceMedico {
    background-color: #FFD200;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceMedico {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaMedico {
    font-size: 26px; }
  .totalIndiceMedico {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceProfessor {
    background-color: #007AFF;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceProfessor {
    font-size: 20px;
    font-weight: 900; }
  .tituloTabelaProfessor {
    font-size: 26px; }
  .totalIndiceProfessor {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA LISTA*/
  .tituloTabelaLista {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 3px; }
  .theadStyleLista {
    background-Color: #9B9B9B; }
  .headProfissaoLista {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headQuantLista {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColunaProfissaoLista {
    text-align: left; }
  .dadosColunaQuantLista {
    text-align: left; }
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* - - - - - - - - - - - - - - - - - - - - - */
  /* RELATÓRIO PARLAMENTARES DIVIDIDOS CASAS LEGISLATIVAS */
  .corIndiceCamara-A {
    background-color: #F63854;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCamara-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceCamara-A {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceSenado-A {
    background-color: #4CD964;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceSenado-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceSenado-A {
    font-size: 21px;
    padding-left: 15px; }
  .corIndiceCongresso-A {
    background-color: #5856D7;
    border-radius: 150px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
    margin-right: 15px; }
  .textoIndiceCongresso-A {
    font-size: 20px;
    font-weight: 900; }
  .totalIndiceCongresso-A {
    font-size: 21px;
    padding-left: 15px; }
  /* TABELA CAMARA */
  .headStySiglaCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantCamara {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaCamara {
    text-align: left; }
  .dadosColNomeCamara {
    text-align: left; }
  .dadosColQuantCamara {
    text-align: left; }
  /* TABELA SENADO */
  .headStySiglaSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantSenado {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaSenado {
    text-align: left; }
  .dadosColNomeSenado {
    text-align: left; }
  .dadosColQuantSenado {
    text-align: left; }
  /* TABELA CONGRESSO */
  .headStySiglaCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyNomeCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .headStyQuantCongresso {
    color: white;
    text-align: left;
    font-size: 23px;
    margin-left: 100px; }
  .dadosColSiglaCongresso {
    text-align: left; }
  .dadosColNomeCongresso {
    text-align: left; }
  .dadosColQuantCongresso {
    text-align: left; } }

.scroll-tabela {
  overflow-x: auto; }

.headParlamentarConvergente {
  min-width: 200px; }

.headPartidoConvergente {
  min-width: 200px; }

.headEstadoConvergente {
  min-width: 200px; }

.headParlamentarDivergente {
  min-width: 200px; }

.headPartidoDivergente {
  min-width: 200px; }

.headEstadoDivergente {
  min-width: 200px; }

.headParlamentarNaoClassificado {
  min-width: 200px; }

.headPartidoNaoClassificado {
  min-width: 200px; }

.headEstadoNaoClassificado {
  min-width: 200px; }

.headParlamentarNeutro {
  min-width: 200px; }

.headPartidoNeutro {
  min-width: 200px; }

.headEstadoNeutro {
  min-width: 200px; }

.headNomePorPartido {
  min-width: 500px; }

.headTipoPorPartido {
  min-width: 100px; }

.headQuantPorPartido {
  min-width: 80px; }

.card-relatorio {
  background-color: #fff;
  height: 250px;
  margin-bottom: 15px;
  border: 1px solid #dbdbdb; }

.text-insights {
  font-size: 19px;
  text-align: center;
  color: #292A2B;
  font-weight: bold;
  margin-top: 15px;
  padding: 15px; }

.relatorio-center {
  margin-top: 60px; }

.insights-titulo-dashboard {
  font-size: 23px;
  color: #3E4049;
  border-radius: 2px; }

.label-relatorio {
  font-size: 12px;
  color: #3E4049; }

.Client-select #tipos-relatorios .option {
  font-size: 14px;
  margin-bottom: -5px;
  width: 100%; }

.titulo-notificacoes {
  color: #9B9B9B;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px; }

.card-descricao {
  font-weight: bold;
  font-size: 15px;
  color: #3E4049;
  margin-bottom: 5px; }

.card-sigla {
  font-weight: bold;
  font-size: 12px;
  color: #3E4049; }

.card-casa {
  font-weight: bold;
  font-size: 13px;
  color: #3E4049; }

.show {
  display: block; }

.hide {
  display: none; }

.tooltip-notification {
  padding: 0.75em;
  position: absolute;
  left: 60px;
  bottom: 1em;
  text-align: left;
  height: 35em;
  width: 20em;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff; }
  .tooltip-notification .notification-container {
    margin-top: 10px;
    overflow-y: scroll;
    height: 85%; }

.new {
  background-color: #e1e8f2; }

.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  border: solid 1px #cdd8de;
  margin-bottom: 10px; }
  .notification .image img {
    width: 50px;
    height: 50px;
    border-radius: 100px; }
  .notification .infos {
    margin-left: 0.1em;
    font-size: 12px;
    line-height: 14px;
    font-stretch: normal;
    letter-spacing: 0.4px;
    color: #292a2b;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
    .notification .infos .message .mention {
      font-weight: bold;
      color: #172b4d; }
    .notification .infos .period {
      color: #979797; }

.link {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  color: #008BFF;
  cursor: pointer; }

.Alertas {
  position: fixed;
  top: 10px;
  left: 50%;
  margin-left: -244px;
  width: 496px;
  overflow: hidden;
  z-index: 99999; }
  .Alertas .Alerta {
    width: 488px;
    min-height: 50px;
    border-radius: 35px;
    box-shadow: 5px 5px 6px 0 rgba(2, 30, 50, 0.12);
    margin-bottom: 10px;
    color: white; }
    .Alertas .Alerta .icone {
      vertical-align: top;
      display: inline-block;
      margin: 8px; }
      .Alertas .Alerta .icone svg {
        width: 34px;
        height: 34px;
        display: inline-block !important;
        fill: white; }
    .Alertas .Alerta .texto {
      width: 390px;
      display: inline-block !important;
      padding: 5px 6px 6px 0;
      border-right: solid 1px rgba(0, 0, 0, 0.14); }
      .Alertas .Alerta .texto strong {
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        margin: 0; }
      .Alertas .Alerta .texto p {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        line-height: 14px;
        margin: 0; }
    .Alertas .Alerta .fechar {
      display: inline-block;
      vertical-align: top;
      height: 47px;
      width: 45px;
      border-radius: 39px;
      border: none;
      background: transparent;
      display: none; }
      .Alertas .Alerta .fechar svg {
        width: 11px;
        height: 11px;
        display: inline-block !important;
        fill: white; }
    .Alertas .Alerta.sucesso {
      background-color: #00ce8c; }
    .Alertas .Alerta.erro {
      background-color: #bd081c; }
    .Alertas .Alerta.aviso {
      background-color: #ffb100; }

@media only screen and (min-width: 320px) and (max-width: 767.98px) {
  .Alertas {
    position: fixed;
    top: 100px;
    left: 50%;
    margin-left: -125px;
    width: 290px;
    overflow: hidden;
    z-index: 99999;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 60px; }
    .Alertas .Alerta {
      width: 488px;
      min-height: 50px;
      border-radius: 35px;
      box-shadow: 5px 5px 6px 0 rgba(2, 30, 50, 0.12);
      margin-bottom: 10px;
      color: white; }
      .Alertas .Alerta .texto {
        width: 192px;
        display: inline-block !important;
        padding: 5px 6px 6px 0;
        border-right: solid 1px rgba(0, 0, 0, 0.14);
        text-align: center; }
    .Alertas.sucesso {
      background-color: #00ce8c; }
    .Alertas.erro {
      background-color: #bd081c; }
    .Alertas.aviso {
      background-color: #ffb100; } }

.doughnut {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .doughnut .legends .legend {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .doughnut .legends .legend .label {
      width: 50px; }
      .doughnut .legends .legend .label:last-child {
        width: 150px; }
    .doughnut .legends .legend .color {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      margin-right: 1em; }
    .doughnut .legends .legend .percent {
      width: 150px; }

/* MEDIA QUERY */
/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .marginTopBotaoInt {
    margin-top: '20px'; }
  .caixaModalTreino {
    width: '100%';
    height: '100%';
    background-color: "white";
    color: '#3E4049'; }
  .tituloModal {
    font-size: '23px'; }
  .font-correlatas {
    font-size: 12px; } }

/*/ Small devices (landscape phones, 576px and up)*/
/*/ Medium devices (tablets, 768px and up)*/
/*/ Large devices (desktops, 992px and up)*/
/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .marginTopBotaoInt {
    margin-top: '20px'; }
  .caixaModalTreino {
    width: '100%';
    height: '100%';
    background-color: "white";
    color: '#3E4049'; }
  #tituloModal {
    font-size: '23px'; }
  .tituloPagina {
    font-size: 28px; }
  .corDeFundo {
    background-color: white;
    padding: 20px; }
  .divSubTitulo {
    margin-bottom: 20px; }
  .subTitulo {
    font-size: 23px; }
  .bordasAcordion {
    border: 1px solid #E1E8F2;
    border-radius: 6px;
    padding-top: 7px;
    padding-left: 15px;
    padding-right: 15px; }
  .tituloPrioEPosi {
    font-weight: 'bold'; }
  .tituloModal {
    font-size: '20px'; } }

.border-active-ia {
  border-bottom: 3px solid #4b1196;
  border-bottom: 3px solid var(--btndangerbordercolor);
  cursor: pointer;
  text-align: center; }

.border-inactive-ia {
  border-bottom: 3px solid #D8D8D8;
  cursor: pointer;
  text-align: center; }

.texto-aba-active-ia {
  color: #3E4049;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px; }

.texto-aba-inactive-ia {
  color: lightgray;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px; }

.msg-sem-registro {
  background: #f5f5f5;
  border-radius: 4px; }

.msg-sem-registro b {
  color: #3e4049;
  font-size: 14px; }

.titulo-insights {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 41px;
  color: #3e4049;
  border-radius: 2px; }

.remove-link-insigths {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center; }

.bordas-links-insights .nav-link {
  color: #5c646d;
  border-bottom: 3px solid #bebebe; }
  .bordas-links-insights .nav-link.active {
    color: #5c646d;
    font-weight: bold;
    border-bottom: 3px solid #4b1196;
    border-bottom: 3px solid var(--bordas-links-insights-active); }

@media screen and (max-width: 770px) and (min-width: 300px) {
  .slider-insigths {
    padding-right: 15px;
    padding-left: 15px; }
  #align-grafico-mobile {
    margin-right: 100px; } }

@media screen and (max-width: 1980px) and (min-width: 1400px) {
  .insightGraficoCPI {
    width: 1500px;
    margin: 0 auto;
    padding: 0; } }

.insightGraficoCPI {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0; }

.insightGraficoCORR {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0; }

.apexcharts-tooltip {
  background: #cccc;
  color: #000000;
  font-weight: 100; }

.MuiFormControlLabel-label {
  color: #3e4049;
  font-size: 16px; }

.tempo-tramitação {
  width: 100%;
  padding: 30px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 4px; }

.texto-tramitação {
  font-weight: bold;
  font-size: 14px;
  color: #3e4049;
  padding-right: 5px; }

.valor-tramitação {
  font-size: 14px;
  color: #3e4049; }

.container-citacoes {
  margin: 0 auto; }

.limpar-filtro-citacoes {
  color: red;
  border-bottom: 1px solid red;
  cursor: pointer;
  font-weight: bold; }

#icon-config-citacoes {
  width: 30px;
  margin-top: 5px; }

.btn-config-citacoes {
  background-color: #4b1196 !important;
  background-color: var(--btn-insigths) !important;
  border-radius: 7px !important;
  color: #ffffff !important;
  font-weight: normal !important; }

.headTableCitacoes {
  color: white;
  text-align: left;
  font-size: 16px; }

.bordasCitacoes {
  border: 1px solid #e1e8f2;
  border-radius: 6px; }

.fontsCitacoes {
  color: "#3E4049";
  font-weight: "bold"; }

.listaPalavras {
  height: 150px; }

.titulo-listaPalvras {
  background: #ffffff;
  height: 35px; }

.theadStyleCitacoes {
  background-color: #9b9b9b;
  color: #fff;
  font-size: 16px;
  text-align: left; }

@media screen and (max-width: 770px) and (min-width: 300px) {
  .listaPalavras {
    height: 250px; }
  .paginacao-citacoes .pagination {
    font-size: 0.5rem; } }

@media screen and (max-width: 812px) and (min-width: 375px) {
  .listaPalavras {
    height: 400px; } }

.table-thead {
  background: #9b9b9b; }

.table-thead-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff; }

.label-insight {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #3e4049; }

.label-insight-acordion {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #3e4049; }

.remove-link-usuario {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center; }

.bordas-links-usuario .nav-link {
  color: #5c646d;
  border-bottom: 3px solid #bebebe; }
  .bordas-links-usuario .nav-link.active {
    color: #5c646d;
    font-weight: bold;
    border-bottom: 3px solid #4b1196;
    border-bottom: 3px solid var(--bordas-links-insights-active); }

@media screen and (max-width: 770px) and (min-width: 300px) {
  .slider-usuario {
    padding-right: 15px;
    padding-left: 15px; } }

.btn-usuario {
  background: #4b1196;
  background: var(--btndangerbordercolor);
  outline: none;
  border: none;
  padding: 15px;
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF; }

.foto-usuario {
  width: 140px;
  height: 140px;
  background-color: #3E4049;
  border: 3px solid #3E4049;
  border-radius: 150px;
  margin-left: auto;
  margin-right: auto; }

.icone-editar-foto {
  position: relative;
  z-index: 99;
  width: 40px;
  height: 40px;
  background-color: #3E4049;
  border: 3px solid #FFFFFF;
  border-radius: 150px;
  margin-left: auto;
  margin-right: 190px;
  margin-top: -90px; }

.photo-user-default {
  color: #FFFFFF;
  font-size: 70px;
  margin-top: 15px; }

.foto-usuario-preview {
  width: 140px;
  height: 140px;
  border: 5px solid #3E4049;
  border-radius: 150px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  box-shadow: 0px 0px 2px 2px #000000; }

#img-foto-usuario-preview {
  width: 130px;
  height: 130px;
  border-radius: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto; }

.icon-list-user {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3E4049;
  display: flex;
  justify-content: center;
  align-items: center; }

.img-foto-list-usuario {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center; }

.label-form-filtro-usuario {
  color: #4b1196;
  color: var(--btndangerbordercolor);
  font-size: 12px; }

.messageError {
  color: '#DC2D41';
  font-size: '12px'; }

* {
  font-family: "NewJune-Book"; }

body {
  overflow-x: hidden; }

.cursor-pointer {
  cursor: pointer; }

.cursor-croshair {
  cursor: crosshair; }

.cursor-eresize {
  cursor: e-resize; }

.cursor-move {
  cursor: move; }

.cursor-inherit {
  cursor: inherit !important; }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5; }

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5; }

::-webkit-scrollbar-thumb {
  background-color: #a5a4a4; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.2rem; }

.text-inherit {
  text-transform: inherit; }

a {
  transition: all 0.5s; }
  a:hover {
    text-decoration: none !important; }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  padding-right: 15px !important;
  padding-left: 15px !important; }

.fs-1 {
  font-size: 1px !important; }

.fs-2 {
  font-size: 2px !important; }

.fs-3 {
  font-size: 3px !important; }

.fs-4 {
  font-size: 4px !important; }

.fs-5 {
  font-size: 5px !important; }

.fs-6 {
  font-size: 6px !important; }

.fs-7 {
  font-size: 7px !important; }

.fs-8 {
  font-size: 8px !important; }

.fs-9 {
  font-size: 9px !important; }

.fs-10 {
  font-size: 10px !important; }

.fs-11 {
  font-size: 11px !important; }

.fs-12 {
  font-size: 12px !important; }

.fs-13 {
  font-size: 13px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-15 {
  font-size: 15px !important; }

.fs-16 {
  font-size: 16px !important; }

.fs-17 {
  font-size: 17px !important; }

.fs-18 {
  font-size: 18px !important; }

.fs-19 {
  font-size: 19px !important; }

.fs-20 {
  font-size: 20px !important; }

.fs-21 {
  font-size: 21px !important; }

.fs-22 {
  font-size: 22px !important; }

.fs-23 {
  font-size: 23px !important; }

.fs-24 {
  font-size: 24px !important; }

.fs-25 {
  font-size: 25px !important; }

.fs-26 {
  font-size: 26px !important; }

.fs-27 {
  font-size: 27px !important; }

.fs-28 {
  font-size: 28px !important; }

.fs-29 {
  font-size: 29px !important; }

.fs-30 {
  font-size: 30px !important; }

.fs-31 {
  font-size: 31px !important; }

.fs-32 {
  font-size: 32px !important; }

.fs-33 {
  font-size: 33px !important; }

.fs-34 {
  font-size: 34px !important; }

.fs-35 {
  font-size: 35px !important; }

.fs-36 {
  font-size: 36px !important; }

.nav-link {
  color: #292a2b;
  border-bottom: 3px solid transparent; }
  .nav-link.active {
    color: #008BFF;
    font-weight: bold;
    font-weight: bold;
    border-bottom: 3px solid #008BFF; }

.big {
  font-size: 28px !important; }

.large {
  font-size: 38px !important; }

.extra-large {
  font-size: 45px !important; }

.oflow-y {
  overflow-y: auto; }

.voto-sim {
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: #00ce8c;
  margin: 4px; }

.voto-nao {
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: #f91552;
  margin: 4px; }

.voto-ausente {
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: #ffb15e;
  margin: 4px; }

.acao-green {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  float: left;
  background: #00ce8c; }

.acao-yellow {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  float: left;
  background: #ffc009; }

.acao-light {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  float: left;
  background: #D8D8D8; }

.acao-blue {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  float: left;
  background: #008bff; }

.acao-red {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  float: left;
  background: #f91552; }

.acao-orange {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  float: left;
  background: #d4ae65; }

.d-flex-spacearound {
  display: flex;
  justify-content: space-around; }

.text-secondary.text-capitalize.m-0.fs-12.label-result,
.text-secondary.text-capitalize.m-0.fs-12.label-no-result {
  font-size: 0.935rem !important; }

.label-result,
.text-secondary.text-capitalize.m-0.fs-12.label-result {
  font-weight: bold !important;
  color: #008bff !important; }

.label-no-result {
  font-weight: normal !important;
  color: #979797 !important; }

.min-width-200 {
  min-width: 200px; }

.acoesInfluencia .dropdown-toggle::after {
  display: none; }

.tooltip-title {
  max-width: 180px;
  margin-left: 10px; }

.selectBasic {
  display: flex !important;
  justify-content: space-between;
  position: relative !important;
  width: 10em !important;
  align-items: center; }
  .selectBasic select {
    width: 100%;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    outline: none; }
    .selectBasic select::-ms-expand {
      display: none; }

i.circle {
  padding: 1em;
  border-radius: 100px; }
  i.circle.red {
    background: #f91552; }
  i.circle.blue {
    background: #008bff; }
  i.circle.yellow {
    background: #ffb15e; }
  i.circle.green {
    background: #00ce8c; }

i.angle {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px; }
  i.angle.red {
    border-color: #f91552; }
  i.angle.blue {
    border-color: #008bff; }
  i.angle.yellow {
    border-color: #ffb15e; }
  i.angle.green {
    border-color: #00ce8c; }
  i.angle.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  i.angle.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  i.angle.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); }
  i.angle.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.titulo {
  display: inline-flex; }

.line-heigt08 {
  line-height: 0.75; }

#tooltip-item {
  float: right;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: 0.43px;
  word-wrap: normal;
  font-size: 10px;
  width: 153px;
  height: 19px;
  margin-left: 8px; }
  #tooltip-item .tooltip-arrow {
    top: 50%;
    left: auto;
    margin-left: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #343434; }
  #tooltip-item .tooltip-label {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #343434;
    border-radius: 2px;
    position: relative; }
    #tooltip-item .tooltip-label:before {
      content: "";
      height: 0;
      width: 0;
      border-right: 10px solid #343434;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      position: absolute;
      left: -10%;
      top: 0; }

.border-comissoes {
  border: 1px solid #cdd8de !important; }

.no-border {
  border: none !important; }

.w-18 {
  width: 18% !important; }

.visualizar-perfil {
  margin-top: 6px; }

.gerenciamento-background {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  color: white;
  text-align: center;
  padding-top: 7px; }

.mr-icon {
  margin-right: 0.8rem; }

.no-padding {
  padding: 0 !important; }

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: none !important;
  background-color: none !important; }

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #008BFF;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #f91552;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #FFD436;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #818181;
  --gray-dark: #343a40;
  --primary: #008BFF;
  --secondary: #929AA2;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #FFD436;
  --danger: #dc3545;
  --light: #fafbfd;
  --dark: #343a40;
  --pink: #f91552;
  --blue: #172b4d;
  --blue-600: #008BFF;
  --orange: #FFB15E;
  --gray-200: #EEF1F6;
  --gray-400: #cdd8de;
  --gray-600: #818181;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.935rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292a2b;
  text-align: left;
  background-color: #f3f5f7; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #008BFF;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0061b3;
    text-decoration: none; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #818181;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.2rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: #172b4d; }

h1, .h1 {
  font-size: 2.3375rem; }

h2, .h2 {
  font-size: 1.87rem; }

h3, .h3 {
  font-size: 1.63625rem; }

h4, .h4 {
  font-size: 1.4025rem; }

h5, .h5 {
  font-size: 1.16875rem; }

h6, .h6 {
  font-size: 0.935rem; }

.lead {
  font-size: 1.16875rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.16875rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #818181; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f3f5f7;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #818181; }

code {
  font-size: 87.5%;
  color: #f91552;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #292a2b;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #292a2b; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #292a2b;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e0e0e0; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e0e0e0; }
  .table tbody + tbody {
    border-top: 2px solid #e0e0e0; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e0e0e0; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e0e0e0; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #292a2b;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8dfff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ac3ff; }

.table-hover .table-primary:hover {
  background-color: #9fd4ff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fd4ff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e0e3e5; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c6cacf; }

.table-hover .table-secondary:hover {
  background-color: #d2d6d9; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d2d6d9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff3c7; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe996; }

.table-hover .table-warning:hover {
  background-color: #ffeeae; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffeeae; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfdfe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #fdbdcf; }

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #fc85a5; }

.table-hover .table-pink:hover {
  background-color: #fca4bd; }
  .table-hover .table-pink:hover > td,
  .table-hover .table-pink:hover > th {
    background-color: #fca4bd; }

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #bec4cd; }

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #8691a2; }

.table-hover .table-blue:hover {
  background-color: #b0b7c2; }
  .table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #b0b7c2; }

.table-blue-600,
.table-blue-600 > th,
.table-blue-600 > td {
  background-color: #b8dfff; }

.table-blue-600 th,
.table-blue-600 td,
.table-blue-600 thead th,
.table-blue-600 tbody + tbody {
  border-color: #7ac3ff; }

.table-hover .table-blue-600:hover {
  background-color: #9fd4ff; }
  .table-hover .table-blue-600:hover > td,
  .table-hover .table-blue-600:hover > th {
    background-color: #9fd4ff; }

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #ffe9d2; }

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #ffd6ab; }

.table-hover .table-orange:hover {
  background-color: #ffddb9; }
  .table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #ffddb9; }

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #fafbfc; }

.table-gray-200 th,
.table-gray-200 td,
.table-gray-200 thead th,
.table-gray-200 tbody + tbody {
  border-color: #f6f8fa; }

.table-hover .table-gray-200:hover {
  background-color: #eaeef2; }
  .table-hover .table-gray-200:hover > td,
  .table-hover .table-gray-200:hover > th {
    background-color: #eaeef2; }

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #f1f4f6; }

.table-gray-400 th,
.table-gray-400 td,
.table-gray-400 thead th,
.table-gray-400 tbody + tbody {
  border-color: #e5ebee; }

.table-hover .table-gray-400:hover {
  background-color: #e1e8ec; }
  .table-hover .table-gray-400:hover > td,
  .table-hover .table-gray-400:hover > th {
    background-color: #e1e8ec; }

.table-gray-600,
.table-gray-600 > th,
.table-gray-600 > td {
  background-color: gainsboro; }

.table-gray-600 th,
.table-gray-600 td,
.table-gray-600 thead th,
.table-gray-600 tbody + tbody {
  border-color: #bdbdbd; }

.table-hover .table-gray-600:hover {
  background-color: #cfcfcf; }
  .table-hover .table-gray-600:hover > td,
  .table-hover .table-gray-600:hover > th {
    background-color: #cfcfcf; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #f3f5f7;
  background-color: #292a2b;
  border-color: #3c3d3f; }

.table .thead-light th {
  color: #495057;
  background-color: #EEF1F6;
  border-color: #e0e0e0; }

.table-dark {
  color: #f3f5f7;
  background-color: #292a2b; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #3c3d3f; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #f3f5f7;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.2025rem + 2px);
  padding: 0.4rem 1rem;
  font-size: 0.935rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292a2b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cdd8de;
  border-radius: 0.25rem;
  transition: background-color 0.5s ease-in-out 0s, border-color 0.5s ease-in-out 0s; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #292a2b;
    background-color: #fff;
    border-color: #008BFF;
    outline: 0;
    box-shadow: none; }
  .form-control::-webkit-input-placeholder {
    color: #818181;
    opacity: 1; }
  .form-control::placeholder {
    color: #818181;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #EEF1F6;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #292a2b; }

select.form-control:focus::-ms-value {
  color: #292a2b;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.44rem + 1px);
  padding-bottom: calc(0.44rem + 1px);
  font-size: 0.935rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.81813rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.4rem 0;
  margin-bottom: 0;
  font-size: 0.935rem;
  line-height: 1.5;
  color: #292a2b;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.72719rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.81813rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.5465rem + 2px);
  padding: 0.44rem 1rem;
  font-size: 0.935rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #818181; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.81813rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.2025rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2rem) center;
  background-size: calc(0.75em + 0.4rem) calc(0.75em + 0.4rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.05rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 4rem !important;
  background-position: right 2rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.2025rem;
  background-position: top calc(0.375em + 0.2rem) right calc(0.375em + 0.2rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.4rem) calc(0.75em + 0.4rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.05rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.05rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.05rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.81813rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.2025rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2rem) center;
  background-size: calc(0.75em + 0.4rem) calc(0.75em + 0.4rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 4rem !important;
  background-position: right 2rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.2025rem;
  background-position: top calc(0.375em + 0.2rem) right calc(0.375em + 0.2rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.4rem) calc(0.75em + 0.4rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 600;
  color: #292a2b;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4rem 1rem;
  font-size: 0.935rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #292a2b;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: inherit !important; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #008BFF;
  border-color: #008BFF; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0076d9;
    border-color: #006fcc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0076d9;
    border-color: #006fcc;
    box-shadow: 0 0 0 0.05rem rgba(38, 156, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #008BFF;
    border-color: #008BFF; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #006fcc;
    border-color: #0068bf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(38, 156, 255, 0.5); }

.btn-secondary {
  color: #292a2b;
  background-color: #929AA2;
  border-color: #929AA2; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #7d8790;
    border-color: #76818b; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #7d8790;
    border-color: #76818b;
    box-shadow: 0 0 0 0.05rem rgba(130, 137, 144, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #292a2b;
    background-color: #929AA2;
    border-color: #929AA2; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #76818b;
    border-color: #707a84; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(130, 137, 144, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.05rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.05rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #292a2b;
  background-color: #FFD436;
  border-color: #FFD436; }
  .btn-warning:hover {
    color: #292a2b;
    background-color: #ffcc10;
    border-color: #ffc903; }
  .btn-warning:focus, .btn-warning.focus {
    color: #292a2b;
    background-color: #ffcc10;
    border-color: #ffc903;
    box-shadow: 0 0 0 0.05rem rgba(223, 187, 52, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #292a2b;
    background-color: #FFD436;
    border-color: #FFD436; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #292a2b;
    background-color: #ffc903;
    border-color: #f5c100; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(223, 187, 52, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.05rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #292a2b;
  background-color: #fafbfd;
  border-color: #fafbfd; }
  .btn-light:hover {
    color: #292a2b;
    background-color: #dfe5f2;
    border-color: #d6deee; }
  .btn-light:focus, .btn-light.focus {
    color: #292a2b;
    background-color: #dfe5f2;
    border-color: #d6deee;
    box-shadow: 0 0 0 0.05rem rgba(219, 220, 222, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #292a2b;
    background-color: #fafbfd;
    border-color: #fafbfd; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #292a2b;
    background-color: #d6deee;
    border-color: #ccd7eb; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(219, 220, 222, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.05rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(82, 88, 93, 0.5); }

.btn-pink {
  color: #fff;
  background-color: #f91552;
  border-color: #f91552; }
  .btn-pink:hover {
    color: #fff;
    background-color: #e20641;
    border-color: #d6053d; }
  .btn-pink:focus, .btn-pink.focus {
    color: #fff;
    background-color: #e20641;
    border-color: #d6053d;
    box-shadow: 0 0 0 0.05rem rgba(250, 56, 108, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #f91552;
    border-color: #f91552; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #d6053d;
    border-color: #c9053a; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(250, 56, 108, 0.5); }

.btn-blue {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d; }
  .btn-blue:hover {
    color: #fff;
    background-color: #0e1b30;
    border-color: #0b1526; }
  .btn-blue:focus, .btn-blue.focus {
    color: #fff;
    background-color: #0e1b30;
    border-color: #0b1526;
    box-shadow: 0 0 0 0.05rem rgba(58, 75, 104, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #172b4d;
    border-color: #172b4d; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #0b1526;
    border-color: #08101c; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(58, 75, 104, 0.5); }

.btn-blue-600 {
  color: #fff;
  background-color: #008BFF;
  border-color: #008BFF; }
  .btn-blue-600:hover {
    color: #fff;
    background-color: #0076d9;
    border-color: #006fcc; }
  .btn-blue-600:focus, .btn-blue-600.focus {
    color: #fff;
    background-color: #0076d9;
    border-color: #006fcc;
    box-shadow: 0 0 0 0.05rem rgba(38, 156, 255, 0.5); }
  .btn-blue-600.disabled, .btn-blue-600:disabled {
    color: #fff;
    background-color: #008BFF;
    border-color: #008BFF; }
  .btn-blue-600:not(:disabled):not(.disabled):active, .btn-blue-600:not(:disabled):not(.disabled).active,
  .show > .btn-blue-600.dropdown-toggle {
    color: #fff;
    background-color: #006fcc;
    border-color: #0068bf; }
    .btn-blue-600:not(:disabled):not(.disabled):active:focus, .btn-blue-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(38, 156, 255, 0.5); }

.btn-orange {
  color: #292a2b;
  background-color: #FFB15E;
  border-color: #FFB15E; }
  .btn-orange:hover {
    color: #292a2b;
    background-color: #ff9e38;
    border-color: #ff982b; }
  .btn-orange:focus, .btn-orange.focus {
    color: #292a2b;
    background-color: #ff9e38;
    border-color: #ff982b;
    box-shadow: 0 0 0 0.05rem rgba(223, 157, 86, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #292a2b;
    background-color: #FFB15E;
    border-color: #FFB15E; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #292a2b;
    background-color: #ff982b;
    border-color: #ff921e; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(223, 157, 86, 0.5); }

.btn-gray-200 {
  color: #292a2b;
  background-color: #EEF1F6;
  border-color: #EEF1F6; }
  .btn-gray-200:hover {
    color: #292a2b;
    background-color: #d5dce9;
    border-color: #cdd6e4; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    color: #292a2b;
    background-color: #d5dce9;
    border-color: #cdd6e4;
    box-shadow: 0 0 0 0.05rem rgba(208, 211, 216, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #292a2b;
    background-color: #EEF1F6;
    border-color: #EEF1F6; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #292a2b;
    background-color: #cdd6e4;
    border-color: #c4cfe0; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(208, 211, 216, 0.5); }

.btn-gray-400 {
  color: #292a2b;
  background-color: #cdd8de;
  border-color: #cdd8de; }
  .btn-gray-400:hover {
    color: #292a2b;
    background-color: #b6c6cf;
    border-color: #aec0ca; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    color: #292a2b;
    background-color: #b6c6cf;
    border-color: #aec0ca;
    box-shadow: 0 0 0 0.05rem rgba(180, 190, 195, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #292a2b;
    background-color: #cdd8de;
    border-color: #cdd8de; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #292a2b;
    background-color: #aec0ca;
    border-color: #a7bac5; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(180, 190, 195, 0.5); }

.btn-gray-600 {
  color: #fff;
  background-color: #818181;
  border-color: #818181; }
  .btn-gray-600:hover {
    color: #fff;
    background-color: #6e6e6e;
    border-color: #686868; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    color: #fff;
    background-color: #6e6e6e;
    border-color: #686868;
    box-shadow: 0 0 0 0.05rem rgba(148, 148, 148, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #fff;
    background-color: #818181;
    border-color: #818181; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #686868;
    border-color: #616161; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(148, 148, 148, 0.5); }

.btn-outline-primary {
  color: #008BFF;
  border-color: #008BFF; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #008BFF;
    border-color: #008BFF; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.05rem rgba(0, 139, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #008BFF;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #008BFF;
    border-color: #008BFF; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(0, 139, 255, 0.5); }

.btn-outline-secondary {
  color: #929AA2;
  border-color: #929AA2; }
  .btn-outline-secondary:hover {
    color: #292a2b;
    background-color: #929AA2;
    border-color: #929AA2; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.05rem rgba(146, 154, 162, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #929AA2;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #292a2b;
    background-color: #929AA2;
    border-color: #929AA2; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(146, 154, 162, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.05rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.05rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #FFD436;
  border-color: #FFD436; }
  .btn-outline-warning:hover {
    color: #292a2b;
    background-color: #FFD436;
    border-color: #FFD436; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.05rem rgba(255, 212, 54, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFD436;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #292a2b;
    background-color: #FFD436;
    border-color: #FFD436; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(255, 212, 54, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #fafbfd;
  border-color: #fafbfd; }
  .btn-outline-light:hover {
    color: #292a2b;
    background-color: #fafbfd;
    border-color: #fafbfd; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.05rem rgba(250, 251, 253, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fafbfd;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #292a2b;
    background-color: #fafbfd;
    border-color: #fafbfd; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(250, 251, 253, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.05rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(52, 58, 64, 0.5); }

.btn-outline-pink {
  color: #f91552;
  border-color: #f91552; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #f91552;
    border-color: #f91552; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.05rem rgba(249, 21, 82, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #f91552;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #f91552;
    border-color: #f91552; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(249, 21, 82, 0.5); }

.btn-outline-blue {
  color: #172b4d;
  border-color: #172b4d; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #172b4d;
    border-color: #172b4d; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0.05rem rgba(23, 43, 77, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #172b4d;
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #172b4d;
    border-color: #172b4d; }
    .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(23, 43, 77, 0.5); }

.btn-outline-blue-600 {
  color: #008BFF;
  border-color: #008BFF; }
  .btn-outline-blue-600:hover {
    color: #fff;
    background-color: #008BFF;
    border-color: #008BFF; }
  .btn-outline-blue-600:focus, .btn-outline-blue-600.focus {
    box-shadow: 0 0 0 0.05rem rgba(0, 139, 255, 0.5); }
  .btn-outline-blue-600.disabled, .btn-outline-blue-600:disabled {
    color: #008BFF;
    background-color: transparent; }
  .btn-outline-blue-600:not(:disabled):not(.disabled):active, .btn-outline-blue-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue-600.dropdown-toggle {
    color: #fff;
    background-color: #008BFF;
    border-color: #008BFF; }
    .btn-outline-blue-600:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(0, 139, 255, 0.5); }

.btn-outline-orange {
  color: #FFB15E;
  border-color: #FFB15E; }
  .btn-outline-orange:hover {
    color: #292a2b;
    background-color: #FFB15E;
    border-color: #FFB15E; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.05rem rgba(255, 177, 94, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #FFB15E;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #292a2b;
    background-color: #FFB15E;
    border-color: #FFB15E; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(255, 177, 94, 0.5); }

.btn-outline-gray-200 {
  color: #EEF1F6;
  border-color: #EEF1F6; }
  .btn-outline-gray-200:hover {
    color: #292a2b;
    background-color: #EEF1F6;
    border-color: #EEF1F6; }
  .btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    box-shadow: 0 0 0 0.05rem rgba(238, 241, 246, 0.5); }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #EEF1F6;
    background-color: transparent; }
  .btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #292a2b;
    background-color: #EEF1F6;
    border-color: #EEF1F6; }
    .btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(238, 241, 246, 0.5); }

.btn-outline-gray-400 {
  color: #cdd8de;
  border-color: #cdd8de; }
  .btn-outline-gray-400:hover {
    color: #292a2b;
    background-color: #cdd8de;
    border-color: #cdd8de; }
  .btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    box-shadow: 0 0 0 0.05rem rgba(205, 216, 222, 0.5); }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #cdd8de;
    background-color: transparent; }
  .btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #292a2b;
    background-color: #cdd8de;
    border-color: #cdd8de; }
    .btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(205, 216, 222, 0.5); }

.btn-outline-gray-600 {
  color: #818181;
  border-color: #818181; }
  .btn-outline-gray-600:hover {
    color: #fff;
    background-color: #818181;
    border-color: #818181; }
  .btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    box-shadow: 0 0 0 0.05rem rgba(129, 129, 129, 0.5); }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #818181;
    background-color: transparent; }
  .btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #818181;
    border-color: #818181; }
    .btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.05rem rgba(129, 129, 129, 0.5); }

.btn-link {
  font-weight: 400;
  color: #008BFF;
  text-decoration: none; }
  .btn-link:hover {
    color: #0061b3;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #818181;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.44rem 1rem;
  font-size: 0.935rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.81813rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.935rem;
  color: #292a2b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #EEF1F6; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #292a2b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1d1d1e;
    text-decoration: none;
    background-color: #fafbfd; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #008BFF; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #818181;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.81813rem;
  color: #818181;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #292a2b; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem;
  margin-bottom: 0;
  font-size: 0.935rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292a2b;
  text-align: center;
  white-space: nowrap;
  background-color: #EEF1F6;
  border: 1px solid #cdd8de;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.5465rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.44rem 1rem;
  font-size: 0.935rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.72719rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.81813rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.4025rem;
  padding-left: 2.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.20125rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #008BFF;
    background-color: #008BFF; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f3f5f7, none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #008BFF; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3dcff;
    border-color: #b3dcff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #818181; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #EEF1F6; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.20125rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #e0e0e0;
    border: 1px solid #929AA2; }
  .custom-control-label::after {
    position: absolute;
    top: 0.20125rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #008BFF;
  background-color: #008BFF; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 139, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 139, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 139, 255, 0.5); }

.custom-switch {
  padding-left: 3.25rem; }
  .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.20125rem + 2px);
    left: calc(-3.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #929AA2;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #e0e0e0;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 139, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.2025rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.935rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292a2b;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #cdd8de;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #008BFF;
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(0, 139, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #292a2b;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #818181;
    background-color: #EEF1F6; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #292a2b; }

.custom-select-sm {
  height: calc(1.72719rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.5465rem + 2px);
  padding-top: 0.44rem;
  padding-bottom: 0.44rem;
  padding-left: 1rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.2025rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.2025rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #008BFF;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #EEF1F6; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.2025rem + 2px);
  padding: 0.4rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #292a2b;
  background-color: #fff;
  border: 1px solid #cdd8de;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.2025rem;
    padding: 0.4rem 1rem;
    line-height: 1.5;
    color: #292a2b;
    content: "Browse";
    background-color: #EEF1F6;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f3f5f7, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f3f5f7, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f3f5f7, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #008BFF;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3dcff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e0e0e0;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #008BFF;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3dcff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e0e0e0;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.05rem;
    margin-left: 0.05rem;
    background-color: #008BFF;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3dcff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #e0e0e0;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e0e0e0;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #929AA2; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #929AA2; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #929AA2; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #818181;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #e0e0e0; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #EEF1F6 #EEF1F6 #e0e0e0; }
    .nav-tabs .nav-link.disabled {
      color: #818181;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #008BFF;
    background-color: #f3f5f7;
    border-color: #e0e0e0 #e0e0e0 #f3f5f7; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #008BFF; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 0.935rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 0.935rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 7.5px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -7.5px;
    margin-left: -7.5px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 7.5px;
      margin-bottom: 0;
      margin-left: 7.5px; } }

.card-group > .card {
  margin-bottom: 7.5px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.9rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
  border-radius: inherit; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.7rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.7rem;
    color: #818181;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #172b4d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #008BFF;
  background-color: #fff;
  border: 1px solid #e0e0e0; }
  .page-link:hover {
    z-index: 2;
    color: #0061b3;
    text-decoration: none;
    background-color: #EEF1F6;
    border-color: #e0e0e0; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #008BFF;
  border-color: #008BFF; }

.page-item.disabled .page-link {
  color: #818181;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e0e0e0; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.935rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.81813rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.93em;
  font-size: 69%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #008BFF; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #006fcc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(0, 139, 255, 0.5); }

.badge-secondary {
  color: #292a2b;
  background-color: #929AA2; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #292a2b;
    background-color: #76818b; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(146, 154, 162, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #292a2b;
  background-color: #FFD436; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #292a2b;
    background-color: #ffc903; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(255, 212, 54, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #292a2b;
  background-color: #fafbfd; }
  a.badge-light:hover, a.badge-light:focus {
    color: #292a2b;
    background-color: #d6deee; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(250, 251, 253, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(52, 58, 64, 0.5); }

.badge-pink {
  color: #fff;
  background-color: #f91552; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #fff;
    background-color: #d6053d; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(249, 21, 82, 0.5); }

.badge-blue {
  color: #fff;
  background-color: #172b4d; }
  a.badge-blue:hover, a.badge-blue:focus {
    color: #fff;
    background-color: #0b1526; }
  a.badge-blue:focus, a.badge-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(23, 43, 77, 0.5); }

.badge-blue-600 {
  color: #fff;
  background-color: #008BFF; }
  a.badge-blue-600:hover, a.badge-blue-600:focus {
    color: #fff;
    background-color: #006fcc; }
  a.badge-blue-600:focus, a.badge-blue-600.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(0, 139, 255, 0.5); }

.badge-orange {
  color: #292a2b;
  background-color: #FFB15E; }
  a.badge-orange:hover, a.badge-orange:focus {
    color: #292a2b;
    background-color: #ff982b; }
  a.badge-orange:focus, a.badge-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(255, 177, 94, 0.5); }

.badge-gray-200 {
  color: #292a2b;
  background-color: #EEF1F6; }
  a.badge-gray-200:hover, a.badge-gray-200:focus {
    color: #292a2b;
    background-color: #cdd6e4; }
  a.badge-gray-200:focus, a.badge-gray-200.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(238, 241, 246, 0.5); }

.badge-gray-400 {
  color: #292a2b;
  background-color: #cdd8de; }
  a.badge-gray-400:hover, a.badge-gray-400:focus {
    color: #292a2b;
    background-color: #aec0ca; }
  a.badge-gray-400:focus, a.badge-gray-400.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(205, 216, 222, 0.5); }

.badge-gray-600 {
  color: #fff;
  background-color: #818181; }
  a.badge-gray-600:hover, a.badge-gray-600:focus {
    color: #fff;
    background-color: #686868; }
  a.badge-gray-600:focus, a.badge-gray-600.focus {
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(129, 129, 129, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #EEF1F6;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.8375rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004885;
  background-color: #cce8ff;
  border-color: #b8dfff; }
  .alert-primary hr {
    border-top-color: #9fd4ff; }
  .alert-primary .alert-link {
    color: #002c52; }

.alert-secondary {
  color: #4c5054;
  background-color: #e9ebec;
  border-color: #e0e3e5; }
  .alert-secondary hr {
    border-top-color: #d2d6d9; }
  .alert-secondary .alert-link {
    color: #343739; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856e1c;
  background-color: #fff6d7;
  border-color: #fff3c7; }
  .alert-warning hr {
    border-top-color: #ffeeae; }
  .alert-warning .alert-link {
    color: #5b4b13; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #828384;
  background-color: #fefeff;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: #696a6a; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-pink {
  color: #810b2b;
  background-color: #fed0dc;
  border-color: #fdbdcf; }
  .alert-pink hr {
    border-top-color: #fca4bd; }
  .alert-pink .alert-link {
    color: #52071b; }

.alert-blue {
  color: #0c1628;
  background-color: #d1d5db;
  border-color: #bec4cd; }
  .alert-blue hr {
    border-top-color: #b0b7c2; }
  .alert-blue .alert-link {
    color: #000001; }

.alert-blue-600 {
  color: #004885;
  background-color: #cce8ff;
  border-color: #b8dfff; }
  .alert-blue-600 hr {
    border-top-color: #9fd4ff; }
  .alert-blue-600 .alert-link {
    color: #002c52; }

.alert-orange {
  color: #855c31;
  background-color: #ffefdf;
  border-color: #ffe9d2; }
  .alert-orange hr {
    border-top-color: #ffddb9; }
  .alert-orange .alert-link {
    color: #604223; }

.alert-gray-200 {
  color: #7c7d80;
  background-color: #fcfcfd;
  border-color: #fafbfc; }
  .alert-gray-200 hr {
    border-top-color: #eaeef2; }
  .alert-gray-200 .alert-link {
    color: #636466; }

.alert-gray-400 {
  color: #6b7073;
  background-color: #f5f7f8;
  border-color: #f1f4f6; }
  .alert-gray-400 hr {
    border-top-color: #e1e8ec; }
  .alert-gray-400 .alert-link {
    color: #525659; }

.alert-gray-600 {
  color: #434343;
  background-color: #e6e6e6;
  border-color: gainsboro; }
  .alert-gray-600 hr {
    border-top-color: #cfcfcf; }
  .alert-gray-600 .alert-link {
    color: #2a2a2a; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.70125rem;
  background-color: #EEF1F6;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #008BFF;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #fafbfd; }
  .list-group-item-action:active {
    color: #292a2b;
    background-color: #EEF1F6; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #818181;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #008BFF;
    border-color: #008BFF; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004885;
  background-color: #b8dfff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004885;
    background-color: #9fd4ff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004885;
    border-color: #004885; }

.list-group-item-secondary {
  color: #4c5054;
  background-color: #e0e3e5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4c5054;
    background-color: #d2d6d9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #4c5054;
    border-color: #4c5054; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856e1c;
  background-color: #fff3c7; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856e1c;
    background-color: #ffeeae; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856e1c;
    border-color: #856e1c; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #828384;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828384;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828384;
    border-color: #828384; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-pink {
  color: #810b2b;
  background-color: #fdbdcf; }
  .list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #810b2b;
    background-color: #fca4bd; }
  .list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #810b2b;
    border-color: #810b2b; }

.list-group-item-blue {
  color: #0c1628;
  background-color: #bec4cd; }
  .list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
    color: #0c1628;
    background-color: #b0b7c2; }
  .list-group-item-blue.list-group-item-action.active {
    color: #fff;
    background-color: #0c1628;
    border-color: #0c1628; }

.list-group-item-blue-600 {
  color: #004885;
  background-color: #b8dfff; }
  .list-group-item-blue-600.list-group-item-action:hover, .list-group-item-blue-600.list-group-item-action:focus {
    color: #004885;
    background-color: #9fd4ff; }
  .list-group-item-blue-600.list-group-item-action.active {
    color: #fff;
    background-color: #004885;
    border-color: #004885; }

.list-group-item-orange {
  color: #855c31;
  background-color: #ffe9d2; }
  .list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #855c31;
    background-color: #ffddb9; }
  .list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #855c31;
    border-color: #855c31; }

.list-group-item-gray-200 {
  color: #7c7d80;
  background-color: #fafbfc; }
  .list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
    color: #7c7d80;
    background-color: #eaeef2; }
  .list-group-item-gray-200.list-group-item-action.active {
    color: #fff;
    background-color: #7c7d80;
    border-color: #7c7d80; }

.list-group-item-gray-400 {
  color: #6b7073;
  background-color: #f1f4f6; }
  .list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
    color: #6b7073;
    background-color: #e1e8ec; }
  .list-group-item-gray-400.list-group-item-action.active {
    color: #fff;
    background-color: #6b7073;
    border-color: #6b7073; }

.list-group-item-gray-600 {
  color: #434343;
  background-color: gainsboro; }
  .list-group-item-gray-600.list-group-item-action:hover, .list-group-item-gray-600.list-group-item-action:focus {
    color: #434343;
    background-color: #cfcfcf; }
  .list-group-item-gray-600.list-group-item-action.active {
    color: #fff;
    background-color: #434343;
    border-color: #434343; }

.close {
  float: right;
  font-size: 2.3375rem;
  font-weight: normal;
  line-height: 1;
  color: #979797;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #979797;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #818181;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #172b4d; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.55; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 0 solid #e0e0e0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.1333rem 0; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.1333rem 0-0.25rem;
  border-top: 0 solid #e0e0e0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 618px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.81813rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.81813rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.935rem;
  color: #172b4d;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #292a2b; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #818181;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #818181;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23818181' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23818181' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #292a2b;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #008BFF !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #006fcc !important; }

.bg-secondary {
  background-color: #929AA2 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #76818b !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #FFD436 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffc903 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #fafbfd !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d6deee !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-pink {
  background-color: #f91552 !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d6053d !important; }

.bg-blue {
  background-color: #172b4d !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #0b1526 !important; }

.bg-blue-600 {
  background-color: #008BFF !important; }

a.bg-blue-600:hover, a.bg-blue-600:focus,
button.bg-blue-600:hover,
button.bg-blue-600:focus {
  background-color: #006fcc !important; }

.bg-orange {
  background-color: #FFB15E !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #ff982b !important; }

.bg-gray-200 {
  background-color: #EEF1F6 !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cdd6e4 !important; }

.bg-gray-400 {
  background-color: #cdd8de !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #aec0ca !important; }

.bg-gray-600 {
  background-color: #818181 !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #686868 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #cdd8de !important; }

.border-top {
  border-top: 1px solid #cdd8de !important; }

.border-right {
  border-right: 1px solid #cdd8de !important; }

.border-bottom {
  border-bottom: 1px solid #cdd8de !important; }

.border-left {
  border-left: 1px solid #cdd8de !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #008BFF !important; }

.border-secondary {
  border-color: #929AA2 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #FFD436 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #fafbfd !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-pink {
  border-color: #f91552 !important; }

.border-blue {
  border-color: #172b4d !important; }

.border-blue-600 {
  border-color: #008BFF !important; }

.border-orange {
  border-color: #FFB15E !important; }

.border-gray-200 {
  border-color: #EEF1F6 !important; }

.border-gray-400 {
  border-color: #cdd8de !important; }

.border-gray-600 {
  border-color: #818181 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #008BFF !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0061b3 !important; }

.text-secondary {
  color: #929AA2 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #6b747d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #FFD436 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #e9b700 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #fafbfd !important; }

a.text-light:hover, a.text-light:focus {
  color: #c3cfe7 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-pink {
  color: #f91552 !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #bd0536 !important; }

.text-blue {
  color: #172b4d !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #050a12 !important; }

.text-blue-600 {
  color: #008BFF !important; }

a.text-blue-600:hover, a.text-blue-600:focus {
  color: #0061b3 !important; }

.text-orange {
  color: #FFB15E !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #ff8c12 !important; }

.text-gray-200 {
  color: #EEF1F6 !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bcc8dc !important; }

.text-gray-400 {
  color: #cdd8de !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #9fb4c0 !important; }

.text-gray-600 {
  color: #818181 !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #5b5b5b !important; }

.text-body {
  color: #292a2b !important; }

.text-muted {
  color: #818181 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #929AA2;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e0e0e0 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #e0e0e0; }
  .table .thead-dark th {
    color: inherit;
    border-color: #e0e0e0; } }

.description {
  width: 64.5% !important;
  margin: 0 auto; }

.dropdown-menu {
  top: 1.85em; }

.proposicao-container {
  padding: 1em;
  border: 1px solid #cdd8de;
  border-radius: 4px;
  height: 100%; }
  .proposicao-container button.btn.btn-primary, .proposicao-container button.btn.btn-default {
    width: 100px; }
  .proposicao-container i.icon-editorial-small {
    font-size: 2.5rem;
    display: flex;
    margin-right: 0.5em; }
  .proposicao-container .name {
    font-weight: bold; }
    .proposicao-container .name span {
      color: #979797;
      font-weight: normal; }
  .proposicao-container .ementa {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .proposicao-container .infos {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 1em;
    grid-column-gap: 2.5em; }
  .proposicao-container .info .label {
    font-weight: bold; }
    .proposicao-container .info .label .result {
      font-weight: normal;
      color: #008bff; }
    .proposicao-container .info .label .no-result {
      font-weight: normal;
      color: #979797; }

.classificar-container p {
  margin: 0 !important; }

.btn-default {
  border: 1px solid #cccccc;
  font-weight: normal; }
  .btn-default:hover {
    background: #8f8f8f;
    color: white; }

@media (min-width: 1000px) {
  .container.busca-proposicao {
    margin-left: 60px;
    margin-right: 60px;
    max-width: 90%; } }

.busca-proposicao form {
  display: flex;
  justify-content: space-between; }
  .busca-proposicao form .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%; }
    .busca-proposicao form .field label {
      width: 50%; }

.busca-proposicao .filter-menu {
  background: white;
  margin-right: 1em;
  padding: 0em 0.5em 1em 0.5em; }
  .busca-proposicao .filter-menu .filter-item {
    padding-left: 1.5em;
    padding-right: 0.25em;
    cursor: pointer; }
    .busca-proposicao .filter-menu .filter-item p {
      margin-bottom: 1em;
      border-bottom: 1px solid #fff; }
      .busca-proposicao .filter-menu .filter-item p:hover {
        border-bottom: 1px solid #cccccc; }

.icon-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .icon-input input {
    width: 100%;
    border: none;
    outline: none;
    background: transparent; }
  .icon-input .icon {
    cursor: pointer;
    margin-top: 0.3em; }

.pagination {
  align-items: center;
  justify-content: center;
  font-size: 0.8rem; }
  .pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
    border: none;
    background-color: none;
    background: none; }
  .pagination .page-item .page-link {
    border-radius: 4px; }
  .pagination .page-item,
  .pagination .next-page,
  .pagination .prev-page {
    cursor: pointer; }
  .pagination .prev-page,
  .pagination .next-page {
    padding: 0.5em;
    color: #575962;
    font-weight: bold;
    font-size: 30px;
    border-radius: 4px; }

.pagination > li > a {
  padding: 0.6em;
  font-size: 1.250em;
  color: #CBCACA;
  font-family: NewJuneBold;
  font-style: normal;
  font-weight: bold; }

.pagination > li > .undefined {
  color: #172B4D; }

.badge-danger {
  padding: 0.1em !important;
  text-align: center; }

.centralizer {
  margin: 0 auto; }

.accordion-body {
  padding: 10px; }

.modal-footer {
  padding: 0;
  justify-content: space-between; }
  .modal-footer .modal-footer-item {
    width: 45%;
    text-align: center;
    border-right: 1px solid #d8d8d8;
    height: 100%;
    padding: 1em; }
    .modal-footer .modal-footer-item:last-child {
      border-right: none; }
    .modal-footer .modal-footer-item button {
      background: none;
      border: none;
      text-transform: uppercase; }

.slick-next:before,
.slick-prev:before {
  content: "";
  border: solid #4b1196;
  border: solid var(--slick-next);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 0.2em;
  position: relative; }

.slick-next:before {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 0.5em; }

.slick-prev:before {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 0.5em; }

.menu-proposicao .nav-link {
  cursor: pointer;
  color: #d8d8d8 !important;
  border-color: #d8d8d8;
  font-size: 0.8rem;
  padding: 0 0 0.6em; }
  .menu-proposicao .nav-link.active {
    color: #3e4049 !important;
    border-color: #4b1196;
    border-color: var(--menuProposicaoActive); }

.timeline .comissao {
  padding: 0.5em;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center; }
  .timeline .comissao.ativo {
    border-bottom: 3px solid #dc2d41; }
  .timeline .comissao.inativo {
    border-bottom: 3px solid #d8d8d8; }
  .timeline .comissao .circulo {
    min-width: 25px;
    border-radius: 100px;
    width: 25px;
    height: 25px;
    margin-right: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center; }
    .timeline .comissao .circulo.concluido {
      background: #4cd964;
      color: white; }
    .timeline .comissao .circulo.atual {
      background: #3e4049;
      color: white; }
    .timeline .comissao .circulo.pendente {
      border: 2px solid #9b9b9b;
      color: #9b9b9b; }
  .timeline .comissao .sigla.ativo {
    color: #3e4049; }
  .timeline .comissao .sigla.inativo {
    color: #9b9b9b; }

.tramitacao .item .line {
  position: relative; }
  .tramitacao .item .line:last-child::before {
    display: none; }
  .tramitacao .item .line::before {
    content: "";
    width: 10px;
    height: 100%;
    top: 1.5em;
    position: absolute;
    left: 0.6em;
    border-left: 2px solid #d8d8d8; }
  .tramitacao .item .line .timeline-image {
    min-height: 5em; }
  .tramitacao .item .line .tramitacoes-tooltip {
    background: #ccc;
    padding: 1em;
    border-radius: 4px;
    position: relative;
    left: 2em;
    width: 75%;
    margin: 1em 0;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 500px;
    line-height: 1.8em; }

.modal-body {
  padding-top: 0px; }

.form-group {
  margin-bottom: 1em;
  margin-top: 2px; }

.w-30 {
  width: 30%; }

.acontecimento-user-photo {
  width: 35px;
  border-radius: 50%; }

.border-none {
  border: none; }

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #9b9b9b; }

.acontecimento-item .editing {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 9999; }

.acontecimento-item .despacho {
  word-break: break-word; }

.text-underline {
  text-decoration: underline; }

.send-icon {
  width: 18px; }

@media print {
  .no-print {
    display: none; } }

.outline {
  outline: none;
  box-shadow: none; }

.new-checkbox-filter {
  color: white;
  width: 20px; }

/* Customize the label (the container) */
.container-new-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.container-new-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark-new-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #3E4049;
  border: 2px solid white;
  border-radius: 3px; }

/* On mouse-over, add a grey background color */
/* When the checkbox is checked, add a blue background */
.container-new-checkbox input:checked ~ .checkmark-new-checkbox {
  background-color: white; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-new-checkbox:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.container-new-checkbox input:checked ~ .checkmark-new-checkbox:after {
  display: block; }

/* Style the checkmark/indicator */
.container-new-checkbox .checkmark-new-checkbox:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #3E4049;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

/*Lista de Proposições*/
/* MEDIA QUERY */
/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .botaoSeguir {
    width: "430px";
    position: "absolute";
    right: "0px"; }
  .background-confirm-modal {
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .background-confirm-modal .confirm-modal {
      background: white;
      padding: 1em;
      padding-bottom: 0; }
      .background-confirm-modal .confirm-modal .modal-footer {
        border-top: 1px solid #d8d8d8; } }

/*/ Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .botaoSeguir {
    width: "430px";
    position: "absolute";
    right: "0px";
    border: "1px solid red"; }
  .background-confirm-modal {
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .background-confirm-modal .confirm-modal {
      background: white;
      padding: 1em;
      padding-bottom: 0; }
      .background-confirm-modal .confirm-modal .modal-footer {
        border-top: 1px solid #d8d8d8; } }

/*/ Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .botaoSeguir {
    width: "430px";
    position: "absolute";
    right: "0px"; }
  .background-confirm-modal {
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .background-confirm-modal .confirm-modal {
      background: white;
      padding: 1em;
      padding-bottom: 0; }
      .background-confirm-modal .confirm-modal .modal-footer {
        border-top: 1px solid #d8d8d8; } }

/*/ Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .botaoSeguir {
    width: "430px";
    position: "absolute";
    right: "0px"; }
  .background-confirm-modal {
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .background-confirm-modal .confirm-modal {
      background: white;
      padding: 1em;
      padding-bottom: 0; }
      .background-confirm-modal .confirm-modal .modal-footer {
        border-top: 1px solid #d8d8d8; } }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .botaoSeguir {
    width: "300px";
    position: "absolute";
    right: "0px";
    border: "1px solid red"; }
  .background-confirm-modal {
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .background-confirm-modal .confirm-modal {
      background: white;
      padding: 1em;
      padding-bottom: 0; }
      .background-confirm-modal .confirm-modal .modal-footer {
        border-top: 1px solid #d8d8d8; } }

